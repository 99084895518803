import React, { useState, useEffect } from 'react'
import { Card, Space, Image, Carousel, Row, Col, Popover,Button } from 'antd'

import marketKingLogo from './marketKingLogo.png'
import Loading from '../Loading'
import {Link} from 'react-router-dom'

//static data:
import sectorPerformance from './industries'
import { CaretUpOutlined, CaretDownOutlined, ApiOutlined, ThunderboltOutlined, PaperClipOutlined, MedicineBoxOutlined, HomeOutlined, ToolOutlined, FormatPainterOutlined, LaptopOutlined, BankOutlined, ShopOutlined, PhoneOutlined } from '@ant-design/icons';

const { Meta } = Card;



let iconStyle = { fontSize: '2rem', color: 'white' }

let UtilitiesIcon = <ApiOutlined style={iconStyle} />
let EnergyIcon = <ThunderboltOutlined style={iconStyle} />
let ConsumerStaplesIcon = <PaperClipOutlined style={iconStyle} />
let HealthIcon = <MedicineBoxOutlined style={iconStyle} />
let RealEstateIcon = <HomeOutlined style={iconStyle} />
let IndustrialIcon = <ToolOutlined style={iconStyle} />
let MaterialsIcon = <FormatPainterOutlined style={iconStyle} />
let TechnologyIcon = <LaptopOutlined style={iconStyle} />
let FinancialsIcon = <BankOutlined style={iconStyle} />
let ConsumerDiscretionaryIcon = <ShopOutlined style={iconStyle} />
let TelecomunicationsIcon = <PhoneOutlined style={iconStyle} />

let icons = [TelecomunicationsIcon, ConsumerDiscretionaryIcon, ConsumerStaplesIcon, EnergyIcon, FinancialsIcon, HealthIcon, IndustrialIcon, MaterialsIcon, RealEstateIcon, TechnologyIcon, UtilitiesIcon]

let caretUp = <CaretUpOutlined style={{ color: 'green', fontSize: 12, marginRight: 0, alignItems: 'center' }} />
let caretDown = <CaretDownOutlined style={{ color: 'red', fontSize: 12, marginRight: 0, alignItems: 'center' }} />
const SectorPerformance = (props) => {

    //let sortedSectors = sectorPerformance.sort()
    const [loading, setLoading] = useState(true)
    const [sectorz, setSectorz] = useState([])
    const [visibleSector,setVisibleSector] = useState(11);
    const sectorsPerformance = sectorPerformance;


    useEffect(() => {
        let ColorCodeValues = () => {
            let performances = [];
            let sectors = sectorsPerformance.sort((a, b) => (a.name > b.name) ? 1 : -1);
            // console.log(sectors)
            for (let i = 0; i < sectorPerformance.length; i++) {
                if (sectors[i].performance >= 0) {
                    let green = 25500 * sectors[i].performance;
                    sectors[i].icon = icons[i];
                    sectors[i].color = "rgb(" + 0 + "," + green + "," + 0 + ")";
                    sectors[i].caret = caretUp;
                }
                else {
                    let red = 25500 * Math.abs(sectors[i].performance);
                    sectors[i].icon = icons[i];
                    sectors[i].color = "rgb(" + red + "," + 0 + "," + 0 + ")";
                    sectors[i].caret = caretDown;
                }
            }

            sectors.sort((a, b) => (a.performance > b.performance) ? -1 : 1)
            console.log(sectors);
            setSectorz(sectors)
            setLoading(false)

            // console.log(performances);
        }
        ColorCodeValues();
    }, [])

    let handleSectorClick = (sectorIndex) => {
        if(sectorIndex==visibleSector)
        {
            setVisibleSector(11)
        }
        else{
            setVisibleSector(sectorIndex)
        }
       // sectorIndex!==11?setVisibleSector(sectorIndex):setVisibleSector(11)


    }

    console.log('SectorPerformance: ', sectorsPerformance)
    if (loading == true) {
        return (

            <Loading message="Getting HeatMap" ></Loading>

        );
    }
    return (
        <div>
            <div style={{ marginTop: '1vh', display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '95vw', color: '#F68C1D', textAlign: 'left', fontSize: '2rem', borderBottom: '2px solid #F68C1D', }}>
                    Sector Performance
      </div>

            </div>
            <div style={{ display: 'flex', justifyContent: 'center', color: 'white' }}>


                <div style={{ width: '95vw', height: '30vh', marginTop: '1vh', display: 'flex', backgroundColor: 'blue', flexDirection: 'column' }}>

                    <Row style={{ height: '33.33%' }}>

                        <Popover content={<div><p >Percent Change:{sectorz[0].caret}{(sectorz[0].performance * 100).toFixed(2)}%</p>
                            <p>
                            <Row>
                                 <Link to={"/BrowseAssets/"+sectorz[0].name}  style={{marginTop:'1vh'}}>
                                <Button shape="round" style={{backgroundColor:'#F68C1D', color:'black',  borderColor:'#F68C1D', }}type="primary" block  >View List</Button> 
                                </Link>
                            </Row>
                            </p>
                            </div>    
                    }
                            title={sectorz[0].name}
                            triger="click"
                            visible={visibleSector==0?true:false}>
                                
                            <Col onClick={()=>handleSectorClick(0)} style={{ backgroundColor: sectorz[0].color, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '2px solid #F68C1D' }} flex={3}>
                           
                                <Row>{sectorz[0].icon}</Row>
                                {/* <Row>{sectorz[0].name}</Row> */}
                                <Row>{(sectorz[0].performance * 100).toFixed(2)} %</Row>
                               
                            </Col>
                           
                        </Popover>

                        <Popover content={<div><p >Percent Change:{sectorz[1].caret}{(sectorz[1].performance * 100).toFixed(2)}%</p>
                            <p>
                            <Row>
                                <Button shape="round" style={{backgroundColor:'#F68C1D', color:'black',  borderColor:'#F68C1D', }}type="primary" block  >View List</Button> 
                            </Row>
                            </p>
                            </div>      
                    }
                            title={sectorz[1].name}
                            trigger="click"
                            visible={visibleSector==1?true:false}>
                            <Col onClick={()=>handleSectorClick(1)} style={{ backgroundColor: sectorz[1].color, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '2px solid #F68C1D' }} flex={2}>
                                <Row>{sectorz[1].icon}</Row>
                                {/* <Row>{sectorz[1].name}</Row> */}
                                <Row>{(sectorz[1].performance * 100).toFixed(2)} %</Row>
                                
                            </Col>
                        </Popover>

                        <Popover content={<div><p >Percent Change:{sectorz[2].caret}{(sectorz[2].performance * 100).toFixed(2)}%</p>
                        <p>
                            <Row>
                                <Button shape="round" style={{backgroundColor:'#F68C1D', color:'black',  borderColor:'#F68C1D', }}type="primary" block  >View List</Button> 
                            </Row>
                            </p>
                            </div>   
                    }
                            title={sectorz[2].name}
                            trigger="click"
                            visible={visibleSector==2?true:false}>
                            <Col onClick={()=>handleSectorClick(2)} style={{ backgroundColor: sectorz[2].color, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '2px solid #F68C1D' }} flex={1}>
                                <Row>{sectorz[2].icon}</Row>
                                {/* <Row>{sectorz[2].name}</Row> */}
                                <Row>{(sectorz[2].performance * 100).toFixed(2)} %</Row>
                            </Col>
                        </Popover>

                    </Row>




                    <Row style={{ height: '33.33%' }}>


                        {/* <Row>{sectorz[3].name}</Row> */}
                        <Popover content={<div><p >Percent Change:{sectorz[3].caret}{(sectorz[3].performance * 100).toFixed(2)}%</p>
                        <p>
                            <Row>
                                <Button shape="round" style={{backgroundColor:'#F68C1D', color:'black',  borderColor:'#F68C1D', }}type="primary" block  >View List</Button> 
                            </Row>
                            </p>
                            </div>    
                    }
                            title={sectorz[3].name}
                            trigger="click"
                            visible={visibleSector==3?true:false}>
                            <Col onClick={()=>handleSectorClick(3)} style={{ backgroundColor: sectorz[3].color, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '2px solid #F68C1D' }} flex={1}>
                                <Row>{sectorz[3].icon}</Row>
                                <Row>{(sectorz[3].performance * 100).toFixed(2)} %</Row>
                            </Col>
                        </Popover>


                        <Popover content={<div><p >Percent Change:{sectorz[4].caret}{(sectorz[4].performance * 100).toFixed(2)}%</p>
                        <p>
                            <Row>
                                <Button shape="round" style={{backgroundColor:'#F68C1D', color:'black',  borderColor:'#F68C1D', }}type="primary" block  >View List</Button> 
                            </Row>
                            </p>
                            </div>    
                    }
                            title={sectorz[4].name}
                            trigger="click"
                            visible={visibleSector==4?true:false}>
                            <Col onClick={()=>handleSectorClick(4)} style={{ backgroundColor: sectorz[4].color, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '2px solid #F68C1D' }} flex={1}>
                                <Row>{sectorz[4].icon}</Row>
                                {/* <Row>{sectorz[4].name}</Row> */}
                                <Row>{(sectorz[4].performance * 100).toFixed(2)} %</Row>
                            </Col>
                        </Popover>


                        <Popover content={<div><p >Percent Change:{sectorz[5].caret}{(sectorz[5].performance * 100).toFixed(2)}%</p>
                        <p>
                            <Row>
                                <Button shape="round" style={{backgroundColor:'#F68C1D', color:'black',  borderColor:'#F68C1D', }}type="primary" block  >View List</Button> 
                            </Row>
                            </p>
                            </div>    
                    }
                            title={sectorz[5].name}
                            trigger="click"
                            visible={visibleSector==5?true:false}>
                            <Col onClick={()=>handleSectorClick(5)} style={{ backgroundColor: sectorz[5].color, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '2px solid #F68C1D' }} flex={1}>
                                <Row>{sectorz[5].icon}</Row>
                                {/* <Row>{sectorz[5].name}</Row> */}
                                <Row>{(sectorz[5].performance * 100).toFixed(2)} %</Row>
                            </Col>
                        </Popover>


                        <Popover content={<div><p >Percent Change:{sectorz[6].caret}{(sectorz[6].performance * 100).toFixed(2)}%</p>
                        <p>
                            <Row>
                                <Button shape="round" style={{backgroundColor:'#F68C1D', color:'black',  borderColor:'#F68C1D', }}type="primary" block  >View List</Button> 
                            </Row>
                            </p>
                            </div>   
                    }
                            title={sectorz[6].name}
                            trigger="click"
                            visible={visibleSector==6?true:false}>
                            <Col onClick={()=>handleSectorClick(6)} style={{ backgroundColor: sectorz[6].color, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '2px solid #F68C1D' }} flex={1}>
                                <Row>{sectorz[6].icon}</Row>
                                {/* <Row>{sectorz[6].name}</Row> */}
                                <Row>{(sectorz[6].performance * 100).toFixed(2)} %</Row>
                            </Col>
                        </Popover>

                        <Popover content={<div><p >Percent Change:{sectorz[7].caret}{(sectorz[7].performance * 100).toFixed(2)}%</p>
                        <p>
                            <Row>
                                <Button shape="round" style={{backgroundColor:'#F68C1D', color:'black',  borderColor:'#F68C1D', }}type="primary" block  >View List</Button> 
                            </Row>
                            </p>
                            </div>    
                    }
                            title={sectorz[7].name}
                            trigger="click"
                            visible={visibleSector==7?true:false}>
                            <Col onClick={()=>handleSectorClick(7)} style={{ backgroundColor: sectorz[7].color, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '2px solid #F68C1D' }} flex={1}>
                                <Row>{sectorz[7].icon}</Row>
                                {/* <Row>{sectorz[7].name}</Row> */}
                                <Row>{(sectorz[7].performance * 100).toFixed(2)} %</Row>
                            </Col>
                        </Popover>
                    </Row>

                    <Row style={{ height: '33.33%' }}>
                        <Popover content={<div><p >Percent Change:{sectorz[8].caret}{(sectorz[8].performance * 100).toFixed(2)}%</p>
                        <p>
                            <Row>
                                <Button shape="round" style={{backgroundColor:'#F68C1D', color:'black',  borderColor:'#F68C1D', }}type="primary" block  >View List</Button> 
                            </Row>
                            </p>
                            </div>    
                    }
                            title={sectorz[8].name}
                            trigger="click"
                            visible={visibleSector==8?true:false}>
                            <Col onClick={()=>handleSectorClick(8)} style={{ backgroundColor: sectorz[8].color, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '2px solid #F68C1D' }} flex={1}>
                                <Row>{sectorz[8].icon}</Row>
                                {/* <Row>{sectorz[7].name}</Row> */}
                                <Row>{(sectorz[8].performance * 100).toFixed(2)} %</Row>
                            </Col>
                        </Popover>
                        <Popover content={<div><p >Percent Change:{sectorz[9].caret}{(sectorz[9].performance * 100).toFixed(2)}%</p>
                        <p>
                            <Row>
                                <Button shape="round" style={{backgroundColor:'#F68C1D', color:'black',  borderColor:'#F68C1D', }}type="primary" block  >View List</Button> 
                            </Row>
                            </p>
                            </div>    
                    }
                            title={sectorz[9].name}
                            trigger="click"
                            visible={visibleSector==9?true:false}>
                            <Col onClick={()=>handleSectorClick(9)} style={{ backgroundColor: sectorz[9].color, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '2px solid #F68C1D' }} flex={2}>
                                <Row>{sectorz[9].icon}</Row>
                                {/* <Row>{sectorz[7].name}</Row> */}
                                <Row>{(sectorz[9].performance * 100).toFixed(2)} %</Row>
                            </Col>
                        </Popover>
                        <Popover content={<div><p>Percent Change:{sectorz[10].caret}{(sectorz[10].performance * 100).toFixed(2)}%</p>
                        <p>
                            <Row>
                                <Button shape="round" style={{backgroundColor:'#F68C1D', color:'black',  borderColor:'#F68C1D', }}type="primary" block  >View List</Button> 
                            </Row>
                            </p>
                            </div>    
                    }
                            title={sectorz[10].name}
                            trigger="click"
                            visible={visibleSector==10?true:false}>
                            <Col onClick={()=>handleSectorClick(10)} style={{ backgroundColor: sectorz[10].color, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '2px solid #F68C1D' }} flex={3}>
                                <Row>{sectorz[10].icon}</Row>
                                {/* <Row>{sectorz[7].name}</Row> */}
                                <Row>{(sectorz[10].performance * 100).toFixed(2)} %</Row>
                            </Col>
                        </Popover>
                    </Row>
                    {/* {UtilitiesIcon}
            {EnergyIcon}
            {ConsumerStaplesIcon}
            {HealthIcon}
            {RealEstateIcon}
            {IndustrialIcon}
            {MaterialsIcon}
            {TechnologyIcon}
            {FinancialsIcon}
            {ConsumerDiscretionaryIcon}
            {TelecomunicationsIcon} */}
                </div>
            </div>
        </div>
    )


}

export default SectorPerformance;

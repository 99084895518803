import {Auth, API} from 'aws-amplify';

const getCustomList = (list) => {
    let apiName = 'MarketKingsAPI'
    let path = '/standings/api/getCustomList/'
    let myInit = {body:{list:list}}
    console.log('in getCustomList')
    return API.post(apiName, path, myInit)
    .then(response =>{
      console.log('LIST:',response)
      return response
    })
    .catch(err=>console.log(err))
  
};

export default getCustomList;
import React, {useState, useEffect, } from 'react';

import {Layout, Row,Card, Col, Button, Alert,Statistic} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined, CloseCircleFilled,
    CheckCircleFilled} from '@ant-design/icons';

const {Countdown} = Statistic;
const {Content} = Layout;


let TimeLeftCard =(props) =>{
  
   let now = Date.now();
   let EndDate = new Date(props.EndDate)
   EndDate = EndDate.toISOString()
   EndDate = Date.parse(EndDate)
   
  // let EndDateEpoch = EndDate.parse()
   //console.log('EndDateEpoch: ',EndDateEpoch)
console.log(now)
console.log(EndDate)
console.log(EndDate-now)
   let countdown;
   if(EndDate-now>0)
   {
      
    countdown = <Countdown value={EndDate}></Countdown>;
      
   }
   else{
    countdown = 'Tournament Concluded!'
   }
    
    

    return(

        <Card style={{display:'flex',justifyContent:'center', width:'17vw', height:'8vh', alignItems:'center',backgroundColor:'black', borderRadius:'20px', color:'Black',borderColor:'transparent'}}>
       
            <span style={{fontSize:8}}>
                <span style={{color:'red'}}>{countdown}</span>
                
            </span>
            
           
         
         
        </Card>
      
    )
}

export default TimeLeftCard;


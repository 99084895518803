import React,{useState,useEffect} from 'react'
import {Card, Space, Image,Carousel} from 'antd'

import marketKingLogo from './marketKingLogo.png'

import  './newsItem.styles.css'

//static data:
import news from './news'

const {Meta} = Card;

const NewsCarousel = (props) => {
  
  const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };


  //console.log('TransactionList:',txs)
console.log('news:', news)
  //let news = props.news;

    return(
      <div >
      <div style={{marginTop:'10vh', display:'flex', justifyContent:'center' }}>
      <div style={{width:'95vw', color:'#F68C1D', textAlign:'left', fontSize:'2rem', borderBottom:'2px solid #F68C1D', }}>
         News
      </div>
        
    </div>
      <div style={{display:'flex', justifyContent:'center',}}>
        
      <Carousel autoplay effect={'scrollx'} style={{marginTop:'2vh', width:'95vw', height:'40vh'}}>
          { news.map((item,key)=>{
                        return(
                          <a href={item.url}>
                          <Card
                          key={key}
                          hoverable
                          style={{width:'100vw'}}
                          cover={<img  style={{width:'100vw', height:'20vh'}} alt="example" src={item.image}/>}>
                          
                  
                              <Meta title={item.headline} description={item.source}/>
                              {/* <Meta title='' description={item.summary}/> */}
                          </Card>
                          </a>
                        )
                        })

              }
       
    
    </Carousel>
    </div>
    </div>
    //  <div className='news-Container'> 
                 
        //     <Image   preview={false}  src={'https://cloud.iexapis.com/v1/news/image/a34b8e82-2337-4898-bcae-85807b4f5cb2'}></Image>
        // </div>
     
        )


}

export default NewsCarousel;

import React,{useState, useEffect} from 'react';
import '.././App.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import {Auth, API, ServiceWorker} from 'aws-amplify';
import {
    isPushNotificationSupported,
    askUserPermission,
    registerServiceWorker,
    createNotificationSubscription,
    getUserSubscription,
    sendNotification
  } from "../Utils/push-notifications";
  import getTournaments from '../Utils/getTournaments'
  import getPlayerTournaments from '../Utils/getPlayerTournaments'
  import Loading from '../Components/Loading'
  import ErrorLoading from '../Components/ErrorLoading';
  import MainFooter from '../Components/MainFooter'
import {Link} from 'react-router-dom'

import ATournamentCard from '../Components/ATournamentCard';

import { Layout, Menu, Breadcrumb, Col, Divider,Card,Button,Row,Alert,Collapse} from 'antd';
import {LeftOutlined, RightOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';

import {useLocation} from "react-router-dom";
import TopHomeContainer from '../Components/TopHomeContainer';
import TournamentDetailsModal from '../Components/TournamentDetailsModal';
import TournamentTabs from '../Components/TournamentTabs';
import RegistrationConfirmationModal from '../Components/RegistrationConfirmationModal';
import PlayerTournamentCard from '../Components/TournamentScreenComponents/PlayerTournamentCard';
import PlayerTournamentHeader from '../Components/TournamentScreenComponents/PlayerTournamentHeader';
import ATournamentHeader from '../Components/TournamentScreenComponents/PlayerTournamentHeader';
import TournamentTile from '../Components/TournamentScreenComponents/TournamentTile';

const {Panel} = Collapse
const { Header, Content, Footer, Sider} = Layout;
const { SubMenu } = Menu;
const rightIcon = <RightOutlined style={{fontSize:32, color:'#F68C1D',}}/>

//const pushNotificationSupported = isPushNotificationSupported();

//const serviceWorker = new ServiceWorker();

let Tournaments = () =>{
    // const [userConsent, setSuserConsent] = useState(Notification.permission);
    // const [error, setError] = useState(null);
     const [loading, setLoading] = useState(true);
     const [error, setError] = useState(false);
     const [tournaments, setTournaments] = useState([])
     const [userInfo, setUserInfo]= useState();
     const [detailsModal, setDetailsModal]= useState(false);
     const [detailsModalInfo, setDetailsModalInfo] = useState()
     const [registerModalVisible, setRegisterModalVisible] = useState();
     const [currentTab,setCurrentTab]=useState('2');
     const [playersTournaments, setPlayerTournaments]= useState([]);

     let toggleRegister = ()=>{
       setRegisterModalVisible(!registerModalVisible);
     }
     let register =()=>{
       setDetailsModal(!detailsModal)
       setRegisterModalVisible(true)
     }
     
     let toggleDetails = (details) =>{
       setDetailsModalInfo(details)
       setDetailsModal(!detailsModal)
     }

    
    


     useEffect(()=>{
       let getCurrentUser =()=>{
        Auth.currentAuthenticatedUser()
        .then(user =>{
            let userInfo = {UserID: user.attributes.sub, Username:user.username}
            console.log('userInfo',userInfo)
            setUserInfo(userInfo);
        })
        .catch(err=>{
          console.log(err);
          setError(true);
          
        })
     }; 
     
     getCurrentUser()

    },[])

    // useEffect(()=>{
    //   let fetchPlayerTournaments =()=>{
    //     getPlayerTournaments(userInfo)
    //       .then(pTournaments =>{
    //         console.log('pTournaments: ', pTournaments)
    //       })
    //       .catch(err=>{
    //         console.log(err);
    //         setError(true);
    //       })
    //   }
    //   fetchPlayerTournaments();

    // },[userInfo])



    useEffect(()=>{
      let fetchPlayerTournaments = async ()=>{
        if(userInfo.UserID){
          console.log(userInfo.UserID)
         let UserID ={UserID:userInfo.UserID}
        getPlayerTournaments(UserID)
          .then(pTournaments =>{

            if(pTournaments)
            {
              console.log('pTournaments: ', pTournaments)
              setPlayerTournaments(pTournaments.playerTournaments);
              setLoading(false);
            }
            else{
              setError(true);
              setLoading(false);
            }
            
          })
          .catch(err=>{
            console.log(err);
            setError(true);
            setLoading(false);
          })
      }
      
      else{
        console.log('Userinfo not ready yet')
      }
    }

      let fetchTournaments = async () =>{
        getTournaments().then(result =>{
          if(result)
          {
            setTournaments(result);
            setLoading(false);
          }
          else{
            setError(true);
            setLoading(false);
          }
          
        }).catch(err=>{
          console.log(err)
          setError(true)
          setLoading(false)
        })
      };
      if(userInfo)
      {
        console.log('IN HERE',userInfo.UserID)

        currentTab=='2'?fetchPlayerTournaments():fetchTournaments()
      }
      else{
        console.log('GOT HERE?')
      }
    },[userInfo,currentTab])


    //  useEffect(()=>{
    //   const fetchTournaments = async () => {
    //     Auth.currentAuthenticatedUser()
    //     .then(user =>{
    //         let userInfo = {UserID: user.attributes.sub, Username:user.username}
    //         console.log('userInfo',userInfo)
    //         setUserInfo(userInfo);
    //     })
    //     getTournaments().then(result =>{
    //       if(result)
    //       {
    //         setTournaments(result);
    //         setLoading(false);
    //       }
    //       else{
    //         setError(true);
    //         setLoading(false);
    //       }
          
    //     }).catch(err=>{
    //       console.log(err)
    //       setError(true)
    //       setLoading(false)
         
    //     })
        
       
    //   };
    //   fetchTournaments();
    // }, []);

   /* {playersTournaments.map((aTournament,index)=>{
      if(aTournament.Status !=3)
      {
        return(
        <Link to={"/Trade/"+aTournament.TournamentID} style={{marginTop:'1vh'}}>
          {<PlayerTournamentCard onClick={()=>console.log('Entering Tournament...')}tournament={aTournament}/>}
      {/* <Button type="primary" block>{aTournament.TournamentID}</Button> }
      </Link>)
      }
      else{
        return(
        // <Link to={"/Trade/"+aTournament.TournamentID} style={{marginTop:'1vh'}}>
       

       
          <PlayerTournamentCard onClick={()=>alert('Tournament is not ready to trade or view... Check back at 4 PM the day before the start time..')} tournament={aTournament}/>
         
     // {/* <Button type="primary" block>{aTournament.TournamentID}</Button> }
   //   {/* </Link> }
      )
      }
    })}
   
 */

  if(loading == true){
    return(
      <Content className="Content">
        
      <Loading message="Getting tournaments" ></Loading>
      <MainFooter defaultKey='2' ></MainFooter>
      </Content>
    );
  }
  else if(error == true){
      return(
          <ErrorLoading ></ErrorLoading>
      )
  }
else if(currentTab =='2' && playersTournaments.length>0){


    return (
        <Content className="Content" >
          <TournamentDetailsModal details={detailsModalInfo} visible={detailsModal} onCancel={toggleDetails} onRegister={register}></TournamentDetailsModal>
          <RegistrationConfirmationModal UserInfo={userInfo} registerModalVisible={registerModalVisible} onCancel={toggleRegister} register ={register} TournamentDetails={detailsModalInfo}></RegistrationConfirmationModal> 
        
          <TournamentTabs setTab={setCurrentTab} defaultKey={currentTab}></TournamentTabs>
          
         
            
         <Content style={{marginTop:'10vh'}}>

      

         {/*<Row style={{color:'#F68C1D', fontSize:24,display:'flex',}}>Tournaments {}</Row>*/}
        <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',marginTop:0}}></Divider>

        <Collapse  expandIcon={({ isActive }) => <RightOutlined style={{fontSize:14, color:'#F68C1D',}} rotate={isActive ? 90 : 0}/>}
                    style={{borderColor:'#F68C1D',}} defaultActiveKey={['1']} ghost accordion>

            <Panel  style={{color:'#F68C1D' }} header={<Content style={{color:'#F68C1D',marginLeft:0,width:'90vw'}}>Active Tournaments</Content>} key="1">
           
            {playersTournaments.map((aTournament,index)=>{
            if(aTournament.Status ==3 || aTournament.Status ==4 ||aTournament.Status==5)
             {
             return(
              <Link to={"/Explore/"+aTournament.TournamentID}  style={{marginTop:'1vh'}}>
                {<TournamentTile tournament={aTournament} onClick={()=>console.log('Entering Tournament...')}></TournamentTile>}
                {/* {<PlayerTournamentCard onClick={()=>console.log('Entering Tournament...')}tournament={aTournament}/>} */}
              {/* <Button type="primary" block>{aTournament.TournamentID}</Button>*/ }
            </Link>)
             }
            })}
      
            </Panel>
            <Panel  style={{color:'#F68C1D' }} header={<Content style={{color:'#F68C1D',marginLeft:0}}>Registered Tournaments</Content>} key="2">
            {playersTournaments.map((aTournament,index)=>{
            if(aTournament.Status ==3)
             {
             return(
              <Link to={"/Explore/"+aTournament.TournamentID}  style={{marginTop:'1vh'}}>
                {<PlayerTournamentCard onClick={()=>console.log('Entering Tournament...')}tournament={aTournament}/>}
              {/* <Button type="primary" block>{aTournament.TournamentID}</Button>*/ }
            </Link>)
             }
            })}
            </Panel>
            <Panel  style={{color:'#F68C1D' }} header={<Content style={{color:'#F68C1D',marginLeft:0}}>Concluded Tournaments</Content>} key="3">
            {playersTournaments.map((aTournament,index)=>{
            if(aTournament.Status ==6)
             {
             return(
              <Link to={"/Explore/"+aTournament.TournamentID} style={{marginTop:'1vh'}}>
                {<TournamentTile tournament={aTournament} onClick={()=>console.log('Entering Tournament...')}></TournamentTile>}

                {/*<PlayerTournamentCard onClick={()=>console.log('Entering Tournament...')}tournament={aTournament}/>*/}
              {/* <Button type="primary" block>{aTournament.TournamentID}</Button>*/ }
            </Link>)
             }
            })}
            </Panel>


            {/* <Panel  style={{color:'#F68C1D' }} header={<p style={{color:'#F68C1D',marginLeft:''}}>Further Information</p>} key="2">
                <Content style={{display:'flex', alignItems:'center',color:'#F68C1D'}}>
                
                </Content>
                
                <p style={{color:'#F68C1D',textAlign:'center'}}>
                
                </p>
            </Panel> */}



        </Collapse>

        </Content>

           {/* <Button  style={{borderWidth:0,borderColor:'#F68C1D',backgroundColor:'#141414', color:'#F68C1D'}} >Show More Articles</Button> */}
           <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',}}></Divider>
           
       
       
          
         

          
          <MainFooter defaultKey='2'></MainFooter>
      </Content>
    )
}
else if(currentTab =='2' && playersTournaments.length==0){


  return (
      <Content className="Content">
        <TournamentDetailsModal details={detailsModalInfo} visible={detailsModal} onCancel={toggleDetails} onRegister={register}></TournamentDetailsModal>
        <RegistrationConfirmationModal UserInfo={userInfo} registerModalVisible={registerModalVisible} onCancel={toggleRegister} register ={register} TournamentDetails={detailsModalInfo}></RegistrationConfirmationModal> 
        {/* <TopHomeContainer centeredMessage={'Tournaments'}></TopHomeContainer> */}
        <TournamentTabs setTab={setCurrentTab} defaultKey={currentTab}></TournamentTabs>
        
        <Content style={{paddingTop:'10vh', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
       
        <Row style={{marginTop:'5vh', }}>
          <Card style={{backgroundColor:'#141414', color:'#F68C1D', borderColor:'#F68C1D',width:'80vw'}}> You have not registered for any tournaments</Card>

        </Row>
        </Content>
       

        
        <MainFooter defaultKey='2'></MainFooter>
    </Content>
  )
}
else if(currentTab =='1' && tournaments){


  return (
      <Content className="Content">
        <TournamentDetailsModal details={detailsModalInfo} visible={detailsModal} onCancel={toggleDetails} onRegister={register}></TournamentDetailsModal>
        <RegistrationConfirmationModal UserInfo={userInfo} registerModalVisible={registerModalVisible} onCancel={toggleRegister} register ={register} TournamentDetails={detailsModalInfo}></RegistrationConfirmationModal> 
        {/* <TopHomeContainer centeredMessage={'Tournaments'}></TopHomeContainer> */}
        <TournamentTabs setTab={setCurrentTab} defaultKey={currentTab}></TournamentTabs>
        
        <Content style={{paddingTop:'10vh', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
        <ATournamentHeader></ATournamentHeader>
        {tournaments.map((aTournament,index)=>{
          return(<ATournamentCard onClick={toggleDetails} tournament={aTournament}/>)
        })}

        </Content>
        

        
        <MainFooter defaultKey='2'></MainFooter>
    </Content>
  )
}

}
export default Tournaments
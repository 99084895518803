import React, {useState, useEffect} from 'react';

import {Layout, Row,Card} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';

const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let TradeChangesContainer =(props) =>{

    let open = props.open
    let close= props.close
    let percentChange = Number((((close - open )/close)*100).toFixed(2))
    let percentChangeCarrot;
    if(percentChange>0)
    {
        percentChangeCarrot =<p1 style={{color:'green'}}><CaretUpOutlined/>{percentChange}%</p1>
    }
    else
    {
        percentChangeCarrot =<p1 style={{color:'red'}}><CaretDownOutlined/>{percentChange}%</p1>
    }
    
    return(
        <Content style={{width:'88vw', marginLeft:'5vw',marginRight:'2.5vw',marginTop:'10vh'}}>

            <Row style={{display:'flex', alignContent:'center', justifyContent:'space-evenly'}}>
                <Card style={{backgroundColor:'black', borderColor:'#F68C1D', color:'#F68C1D', height:'6vh', width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                     ${close} 
                </Card>
                <Card style={{backgroundColor:'black', borderColor:'#F68C1D', color:'#F68C1D', height:'6vh', width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    
                    
                        {percentChangeCarrot}
                    
                </Card>

            </Row>
           
            
        </Content>
    );



}

export default TradeChangesContainer;
import React,{useState, useEffect} from 'react';
import {Modal, Input, AutoComplete, Button} from 'antd'
import { SearchOutlined} from '@ant-design/icons';

const {Search} = Input
const searchOutlined = <SearchOutlined style={{marginTop:'.5vh', color:'#F68C1D',}} />
let SearchButton = <Button link style={{backgroundColor:'black',border:'transparent'}}><SearchOutlined style={{marginTop:'.5vh', color:'#F68C1D',}} /></Button>
const SearchModal = (props) =>{

    return(
    <Modal
    bodyStyle={{backgroundColor:'#141414',color:'#F68C1D'}}
    closable={false}
    visible={props.visible}
    onCancel={props.onCancel}
    footer={null}
  > 

{/* <div style={{display:'flex',justifyContent:'center'}}>
  <AutoComplete
    onSearch={props.onSearch}
    style={{width:'80%',color:'#F68C1D', }}
    >
        
    </AutoComplete>
</div> */}

    <div className="site-layout-background" style={{ padding: 10, Height: 10 }}>
         <Search  style={{backgroundColor:'#8c8c8c',}} bordered={false} placeholder="Please enter a stock symbol... ex. aapl" onSearch={props.onSearch}  enterButton={SearchButton}/>
     </div>   

  </Modal>
    )
}


export default SearchModal;
import {Auth, API} from 'aws-amplify';

const getList = (list) => {
 
    let apiName = 'StockDataAPI'
    let path = '/stockData/getList/'
    let p = path+list
    let myInit = {}
    console.log('in getList')
    return API.get(apiName, p, myInit)
    .then(response =>{
      console.log('LIST:',response)
      return response
    })
    .catch(err=>console.log(err))
  
};

export default getList;
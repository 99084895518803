import React,{useState, useEffect} from 'react';
import {Modal, Input, Button, Slider, InputNumber, Row, Col, Space, Alert, Spin, Layout, Divider, Result} from 'antd'
import {
    LoadingOutlined,
    CloseOutlined,
    CloseCircleFilled,
    CheckCircleFilled
  } from '@ant-design/icons';
import submitTransaction from '../../Utils/submitTransaction'
import closePosition from '../../Utils/closePosition'
import {Auth, API} from 'aws-amplify';

const anIcon = <LoadingOutlined style={{fontSize:72, color:'#F68C1D'}} spin>Loading</LoadingOutlined>
let close = <CloseOutlined style={{color:'#F68C1D'}}/>
let error = <CloseCircleFilled style={{color:'red'}}/>
let success = <CheckCircleFilled style={{color:'green'}}/>

const {Content} = Layout

const SubmitSellModal = (props) =>{
let TxData = props.TxData
let quote = props.quote;
let latestPrice;

if(quote )
{
    latestPrice=quote.latestPrice;
   // SelectedHolding=props.SelectedHolding
}
else{

}

//let [txResult, setTxResult] = useState()
let [loading,setLoading] = useState(false)
let [txAlert,setTxAlert]=useState()
//let [TxData,setTxData]= useState(props.TxData)

// useEffect(() => {
//   let adjustPrice =()=>{
//     if(latestPrice !=TxData.Price)
//     {
//       TxData.Price = latestPrice;
//       console.log('TXDATA UPDATED:',TxData)
//       setTxData
//     }
//   }
//   if(TxData)
//   {
//     adjustPrice();

//   }
// }, [latestPrice])


let handleOk = async ()=>{
    setLoading(true)
    console.log('############################handle ok ', TxData)
    
    let tempTxData = TxData;
    if(TxData.Price != latestPrice)
    {
      console.log('latest price is different!', TxData.Price,' ->', latestPrice)
      tempTxData.Price = latestPrice;
      tempTxData.TimeStamp = new Date();

      TxData=tempTxData
      
    }
    console.log('############################handle UPDATE ', TxData)

  closePosition(TxData).then(txResult =>{

  
  console.log(txResult);
  let m= txResult.TransactionResult + ': '+txResult.message;
  if (txResult.TransactionResult == 'Success')
  {
    setTxAlert(()=><Result icon={success} title={<h3 style={{color:'green'}}>Success</h3>} subTitle={<h3 style={{color:'green'}}>{m}</h3>}></Result>)
    setLoading(false)
    setTimeout(()=>{
        
        props.onConfirmOrderCancel()
        setTxAlert()},1500)
    
  }
  else{
    //<Alert style={{backgroundColor:'#141414',color:'#F68C1D',}} message={m} type="error"/>
    setTxAlert(()=><Result icon={error} title={<h3 style={{color:'red'}}>Error</h3>} subTitle={<h3 style={{color:'red'}}>{m}</h3>}></Result>)
    setLoading(false)
    setTimeout(()=>{
        
        props.onConfirmOrderCancel()
        setTxAlert()},2500)

  }

})
.catch(err=>{
  //let m= txResult.TransactionResult + ': '+txResult.message;
    //setTxAlert(()=><Alert message={err} type="error"/>)
    console.log(err)
    setLoading(false)
    setTimeout(()=>{
        
        props.onConfirmOrderCancel()
        setTxAlert()},1500)
})
}

//computePercentChange()
if(loading)
{
    return(
    <Modal
    
    visible={props.submitVisible}
    TxData ={props.TxData}
    bodyStyle={{backgroundColor:'#141414',color:'#F68C1D'}}
    closable={false}
    closeIcon={close}
    //onOk={props.onOk(TxData)}
    //onCancel={props.onCancel}
    //marketData={props.marketData}
    footer={null}
  > 
 <div style={{display:'flex', justifyContent:'center'}}>
 <Spin indicator={anIcon} ></Spin>

 </div>

    
  </Modal>
    )
}
else if(txAlert)
{
    return(
    <Modal
    bodyStyle={{backgroundColor:'#141414',color:'#F68C1D'}}
    closable={false}
    closeIcon={close}
    visible={props.submitVisible}
    onCancel={props.onCancel}
    footer={null}
  > 
 <div style={{display:'flex', justifyContent:'center'}}>
 {txAlert}

 </div>

    
  </Modal>
    )
}


else if(TxData)
{
return(
      
  //   <Modal
  //   title='Confirm Order'
  //   visible={props.submitVisible}
  //   TxData ={props.TxData}
  //   //onOk={props.onOk(TxData)}
  //   //onCancel={props.onCancel}
  //   //marketData={props.marketData}
  //   footer={[
          
  //           <Button key="back" onClick={props.onConfirmOrderCancel}>
  //             Cancel
  //           </Button>,
  //           <Button key="submit" type="primary" onClick={()=>{
  //            console.log('here') 
  //            handleOk()      

  //           }}>
  //             Submit Order
  //           </Button>,
  //         ]}
  // > 
 
  //   <h2>Symbol: {TxData.Symbol}</h2>
  //   <h2>TxType: {TxData.TxType}</h2>
  //   <h2>Price: {TxData.Price}</h2>
  //   <h2>Number of Shares: {TxData.Shares}</h2>
  //   <h2>Total Cost: ${(TxData.Price*TxData.Shares).toFixed(2)}</h2>

    
  // </Modal>
  <Modal
  bodyStyle={{backgroundColor:'#141414',color:'#F68C1D'}}
  closable={false}
  closeIcon={close}
  visible={props.submitVisible}
  onCancel={props.onCancel}
  footer={null}
> 
<Content style={{}}>
  <Row style={{ display:'flex', alignItems:'center',justifyContent:'space-evenly'}}>
   
    <Col>
      <Row style={{display:'flex', alignItems:'center'}}>
        <h1 style={{color:'#F68C1D'}}> Order Confirmation</h1>
       </Row>
    </Col>
  </Row>

<Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D', marginTop:'-2vh'  }}> </Divider>

<Row style={{marginTop:5, display:'flex',  justifyContent:'flex-start' }}>
 
  <Row style={{ display:'flex', alignItems:'center' }}>
    <Col>
      <h3 style={{color:'#F68C1D'}}>Symbol:</h3>
    </Col>
    <Col>
      <h3 style={{color:'#F68C1D', marginLeft:'2vw'}}> {TxData.Symbol.toUpperCase()}</h3>
    </Col>
  </Row>
</Row>

<Row style={{marginTop:5, display:'flex',  justifyContent:'flex-start' }}>
  <Row style={{ display:'flex', alignItems:'center' }}>
    <Col>
      <h3 style={{color:'#F68C1D'}}>Tx Type:</h3>
    </Col>
    <Col>
      <h3 style={{color:'#F68C1D', marginLeft:'2vw'}}> {TxData.TxType}</h3>
    </Col>
  </Row>
</Row>

<Row style={{marginTop:5, display:'flex',  justifyContent:'flex-start' }}>
  <Row style={{ display:'flex', alignItems:'center' }}>
    <Col>
      <h3 style={{color:'#F68C1D'}}>Asset Price:</h3>
    </Col>
    <Col>
      <h3 style={{color:'#F68C1D', marginLeft:'2vw'}}> ${latestPrice}</h3>
    </Col>
  </Row>
</Row>

<Row style={{marginTop:5, display:'flex',  justifyContent:'flex-start' }}>
  <Row style={{ display:'flex', alignItems:'center' }}>
    <Col>
      <h3 style={{color:'#F68C1D'}}>Shares:</h3>
    </Col>
    <Col>
      <h3 style={{color:'#F68C1D', marginLeft:'2vw'}}> {TxData.Shares}</h3>
    </Col>
  </Row>
</Row>

<Row style={{marginTop:5, display:'flex',  justifyContent:'flex-start' }}>
  <Row style={{ display:'flex', alignItems:'center' }}>
    <Col>
      <h3 style={{color:'#F68C1D'}}>Total:</h3>
    </Col>
    <Col>
     
      <h3 style={{color:'#F68C1D', marginLeft:'2vw'}}> ${(Number(TxData.Total)).toFixed(2)}</h3>
    </Col>
  </Row>
  
</Row>
{latestPrice!=TxData.Price&&loading==false?
<Row style={{marginTop:5, display:'flex',  justifyContent:'flex-start' }}>
  <Row style={{ display:'flex', alignItems:'center' }}>
    <Alert type="info" message={'Price changed:'} description={latestPrice>TxData.Price?'Price increased from $'+TxData.Price+' to $'+latestPrice:'Price decreased from $'+TxData.Price+' to $'+latestPrice}></Alert>
  </Row>
  
</Row>
:''}
    
 


<Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D',  }}> </Divider>



<Row style={{display:'flex', justifyContent:'space-evenly'}}>
    <Button type="round" style ={{backgroundColor:'Red', width:'40%',  borderColor:'transparent', color:'black'}} onClick={props.onConfirmOrderCancel}>Cancel</Button>
    <Button type="round" style ={{backgroundColor:'green', width:'40%', borderColor:'transparent', color:'black'}} onClick={()=>handleOk()}>Submit Order</Button>
</Row>


</Content>


</Modal>
);


}
else {
    return (
        <Modal
        visible={props.submitVisible}
        TxData ={props.TxData}
        //onOk={props.onOk(TxData)}
        //onCancel={props.onCancel}
        //marketData={props.marketData}
        footer={null}

      > 
       
        
      </Modal>
    )
}
}



export default SubmitSellModal;
import React,{useState,useEffect}  from 'react';
import { Layout, Menu, Breadcrumb, Image ,Button} from 'antd';
import getStandings from '../Utils/getStandings'
import Standing from '../Components/Standing'
import Loading from '../Components/Loading'
import ErrorLoading from '../Components/ErrorLoading'
import CustomFooter from '../Components/CustomFooter'
import TopTournamentContainer from '../Components/TopTournamentContainer'
import PlayerHoldingsModal from '../Components/PlayerHoldingsModal'
import Podium from '../Components/Podium'
import {RightCircleOutlined} from '@ant-design/icons';
import StandingsHeader from '../Components/StandingsHeader';
import {Auth} from 'aws-amplify'
import getPlayerHoldings from '../Utils/getPlayerHoldings'
const { Header, Content, Footer, Sider } = Layout;

const Standings =(props)=>
{
  let TournamentID = props.match.params.TournamentID;
  console.log("TOURNAMENT ID: ", TournamentID)
    const [loading, setLoading]= useState(true);
    const [standings,setStandings] = useState(true);
    const [error, setError] = useState(false);
    const [Modal,setModal] = useState(false);
    const [UserInfo, setUserInfo]= useState();
    const [userHoldings, setUserHoldings] = useState();

  //   useEffect(()=>{
  //     const fetchUserID = async () =>{
  //        Auth.currentAuthenticatedUser()
  //          .then(user =>{
  //              let userInfo = {UserID: user.attributes.sub, UserName:user.username}
  //              setUserInfo(userInfo);   
  //            }).catch(err=>console.log(err))
  //     };
  //     fetchUserID();
  // },[])

    useEffect(()=>{
      const fetchStandings = async () => {
        Auth.currentAuthenticatedUser()
        .then(user =>{
            let userInfo = {UserID: user.attributes.sub,TournamentID:TournamentID, UserName:user.username}
            console.log('userInfo',userInfo)
            setUserInfo(userInfo);
       
          getStandings(userInfo).then(result =>{
            if(result)
            {
              setStandings(result);
              setLoading(false);
            }
            else{
              setError(true);
              setLoading(false);
            }
            
          }).catch(err=>{
            console.log(err)
            setLoading(false)
            setError(true)
          })
          }).catch(err=>{
            console.log(err)
            setLoading(false)
            setError(true)
          })
          
       
      };
      fetchStandings();
    }, []);

    let checkPlayerHoldings = async (UserID) => {
      alert('There was an issue... had to remove this feature... fixing soon')
      // let UserTournObj ={UserID:UserID.UserID, TournamentID:TournamentID}
      // let holds = await getPlayerHoldings(UserTournObj)
      //           .then(holdings =>{
      //               if(holdings)
      //               {
      //                  //console.log('holdings:', holdings)
      //                  setUserHoldings(holdings)
      //                  ToggleModal()

      //               }
      //               else
      //               {
      //                 alert('There was a problem getting the requested holdings! Please try again...')
      //               }
      //           })
      //           .catch(err=>{
      //             console.log(err) 
      //           })
      //           return holds
    }

    


    let ToggleModal = () =>{
        setModal(!Modal)
    }

    if(loading == true){
        return(
          <Content className="Content">
          <Loading message="Getting the current standings..."></Loading>
          <CustomFooter TournamentID={TournamentID} defaultKey='3' ></CustomFooter>
      </Content>
      
        );
      }
      else if(error == true){
        return(
            <ErrorLoading ></ErrorLoading>
        )
    }
      else{
        return(
          <Content className="Content" style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <TopTournamentContainer centeredMessage={'Standings'} rightCornerMessage={''}/>
            <div style={{marginTop:'6vh'}}>
          <StandingsHeader></StandingsHeader>
            </div>
         
          {standings.map((aStanding,index)=>{
            if(aStanding.UserID == UserInfo.UserID)
            {
              return(
              <Standing CheckHoldings={checkPlayerHoldings} CurrentUser={true} UserID={aStanding.UserID} PortfolioValue={aStanding.PortfolioValue} Username={aStanding.Username} Position={index}></Standing>
              )
            }
            else{
              return(
              <Standing CheckHoldings={checkPlayerHoldings} CurrentUser={false} UserID={aStanding.UserID} PortfolioValue={aStanding.PortfolioValue} Username={aStanding.Username} Position={index}></Standing>
              )
            }

          }
            // <h3 style={{fontSize:18, color:'#F68C1D',}}>{standing.Username} : $ {standing.PortfolioValue}</h3>
          )}
          <PlayerHoldingsModal holdings={userHoldings} visible={Modal} ToggleModal={ToggleModal}></PlayerHoldingsModal>
           <CustomFooter TournamentID={TournamentID} defaultKey='3' ></CustomFooter>
        </Content>
        )
      }
}
export default Standings;

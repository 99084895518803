import React, {useState, useEffect} from 'react';
import {Layout, Row, Button} from 'antd'
import {SearchOutlined} from '@ant-design/icons';

const searchOutlined = <SearchOutlined style={{marginTop:'.5vh', color:'#F68C1D',}} />



const {Content} = Layout;


let ChartOptionsContainer =(props) =>{

    let ChartSymbol = props.ChartSymbol;
    let ChartTimeFrame = props.ChartTimeFrame;
    let ChartType = props.ChartType;
    let toggleSearch = props.toggleSearch;
    /*  <div style={{width:'25%', backgroundColor:'#141414', color:'#F68C1D', display:'flex',justifyContent:'space-between',alignItems:'center'}} >
    {ChartSymbol} {searchOutlined}
    </div> */

    return(
        <Content style={{width:'88vw', marginLeft:'5vw',marginRight:'2.5vw', backgroundColor:'',  }}>
            <Row style={{}}>
               <Button type='text' style={{backgroundColor:'black', color:'#F68C1D',borderColor:'#F68C1D', width:'25%', display:'flex',alignItems:'center', justifyContent:'space-around'}}
                        onClick={toggleSearch}>
                    {ChartSymbol} {searchOutlined}
               </Button>
           
                {ChartTimeFrame}
           
            
                {ChartType}
            
            </Row>
          
        </Content>
    );



}

export default ChartOptionsContainer;
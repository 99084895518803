import {Auth, API} from 'aws-amplify';

const autocomplete = (fragment) => {
 
    let apiName = 'StockDataAPI'
    let path = '/stockData/autocomplete/'
    let p = path+fragment
    let myInit = {}
    console.log('in autocomplete: ', fragment)
    return API.get(apiName, p, myInit)
    .then(response =>{
      console.log(response)
      return response.Data
    })
    .catch(err=>console.log(err))
  
};

export default autocomplete;
import React, {useEffect, useState} from 'react';
import { Layout, Menu, Breadcrumb, Space, Row,Col} from 'antd';
import {
  DesktopOutlined,
  HomeOutlined,
  SendOutlined,
  PieChartOutlined,
  SearchOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  SettingOutlined,
  TrophyOutlined,
  SlidersOutlined,
  CrownOutlined,
  UserOutliend
} from '@ant-design/icons';
import '.././App.css';
import { Link } from 'react-router-dom'

//#F68C1D GOLD COLOR


const { Header, Content, Sider, Footer  } = Layout;
const { SubMenu } = Menu;

const MainFooter = (props) =>{
  let defaultKeyz = props.defaultKey;
  console.log('tournament IDfooters',props.TournamentID.TournamentID)
  
  let [defaultKey,setDefaultKey] =useState(Number(defaultKeyz))
  let [tabOne,setTabOne] = useState(false)
  let [tabTwo,setTabTwo] = useState(false)
  let [tabThree,setTabThree] = useState(false)

  useEffect(()=>{
   
    menuSelect(defaultKey)
    
  },[])

  console.log('here Key', defaultKey)

  let menuSelect =(key)=>
  {
      if(key==1)
      {
          setTabOne(true);
          setTabTwo(false);
          setTabThree(false)
      }
      if(key==2)
      {
          setTabOne(false);
          setTabTwo(true);
          setTabThree(false)
      }
      if(key==3)
      {
          setTabOne(false);
          setTabTwo(false);
          setTabThree(true)
      }

  }


    return (
     
        <Footer style={{ padding: 0,position:"fixed", bottom:"0",width:'100vw'}}>
          
          <Menu  theme="dark" mode="horizontal" defaultSelectedKeys={[defaultKey]} style={{height:'10vh',backgroundColor:'black' }}  mode='horizontal' >
            
            <Menu.Item style={{width:"33.33%",height:"100%",   backgroundColor:tabOne? '#141414':'black'}} selectable={!tabOne} key="1" >
                    <Link to={"/Portfolio/"+props.TournamentID}>
                    <div style={{display:'flex',justifyContent:'center',alignContent:'center',marginTop:'1vh',width:'100%'}} >
                    
                      <Col >
                        <Row style={{alignItems:'center',justifyContent:'center',margin:0}}> <PieChartOutlined style={{margin:0,padding:0,color:tabOne?'#F68C1D':'#8c8c8c',fontSize:tabOne?32:18}}/></Row>
                        <Row style={{margin:"-1vh",display:'flex',alignItems:'center',justifyContent:'center',color:'#8c8c8c',color:tabOne?'#F68C1D':'#8c8c8c'}}>Portfolio </Row>
                      </Col>
                    </div>   
                    </Link>   
            </Menu.Item>

            <Menu.Item style={{width:"33.33%",height:"100%",  backgroundColor:tabTwo? '#141414':'black'}}selectable={!tabTwo} key="2">
                    <Link to={"/Explore/"+props.TournamentID}>
                    <div style={{display:'flex',justifyContent:'center',alignContent:'center',marginTop:'1vh'}}>
                      <Col >
                      {/* <Link to={"/Portfolio"}></Link> */}
                        <Row style={{alignItems:'center',justifyContent:'center',margin:0}}><SearchOutlined style={{margin:0,padding:0,color:tabTwo?'#F68C1D':'#8c8c8c',fontSize:tabTwo?32:18}}/></Row>
                        <Row style={{margin:"-1vh",display:'flex',alignItems:'center',justifyContent:'center',color:tabTwo?'#F68C1D':'#8c8c8c',color:tabTwo?'#F68C1D':'#8c8c8c'}}>Explore </Row>
                      </Col>
                    </div>  
                    </Link>    
            </Menu.Item>

            <Menu.Item style={{width:"33.33%",height:"100%",  backgroundColor:tabThree? '#141414':'black'}}selectable={!tabThree} key="3">
                    <Link to={"/Standings/"+props.TournamentID}>
                    <div style={{display:'flex',justifyContent:'center',alignContent:'center',marginTop:'1vh'}} >
                      <Col >
                      
                        <Row style={{alignItems:'center',justifyContent:'center',margin:0}}> <TrophyOutlined style={{margin:0,padding:0,color:tabThree?'#F68C1D':'#8c8c8c',fontSize:tabThree?32:18}}/></Row>
                        <Row style={{margin:"-1vh",display:'flex',alignItems:'center',justifyContent:'center',color:tabTwo?'#F68C1D':'#8c8c8c',color:tabThree?'#F68C1D':'#8c8c8c'}}>Standings </Row>
                      </Col>
                    </div>    
                    </Link>  
            </Menu.Item>
 
          </Menu>
         
        </Footer>

        

       
      
    );
  
}

export default MainFooter
import React, {useState, useEffect, } from 'react';

import {Layout, Row,Card, Col, Button, Alert} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined, CloseCircleFilled,
    CheckCircleFilled} from '@ant-design/icons';
import PlacementCard from './PlacementCard';
import TournamentTypeCard from './TournamentTypeCard';
import PortfolioValueCard from './PortfolioValueCard';
import TimeLeftCard from './TimeLeftCard';
let error = <CloseCircleFilled style={{color:'#F68C1D'}}/>
let success = <CheckCircleFilled style={{color:'#F68C1D'}}/>
//const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let PlayerTournamentCard =(props) =>{

    let aTourn = props.tournament
    
    let TournamentID = aTourn.TournamentID;
    let PremiumTournament = aTourn.PremiumTournament//==0?error:success
    console.log(PremiumTournament)
    let Placement = aTourn.Placement;
    let PortfolioValue = aTourn.PortfolioValue;
    let PercentChange= aTourn.PercentChange;
    let EndDate = Date(aTourn.EndDate);//aTourn.EndDate.split('T')[0];
    let status = aTourn.Status;
    console.log('STATUS:',status)
    console.log('placement:',Placement)
    let color ='#F68C1D'
    if(status ==4)
    {
     color = 'green'   
    }
    if(status ==5)
    {
     color = 'green'   
    }
    if(status ==6)
    {
     color = '#8c8c8c'   
    }


    console.log('A Tournament: ', aTourn)
    if(aTourn)
    {
    return(
        <Content style={{}}>
        <Card style={{marginTop:'2vh', borderRadius:'20px' , borderColor:'#F68C1D', borderWidth:'1vw',width:'95vw', height:'15vh',}}>
            
{/*          
         <div style={{display:'flex',justifyContent:'space-evenly'}}> 

        
        <div>
         <TournamentTypeCard TournamentType={PremiumTournament}></TournamentTypeCard>
         </div>
         <div>
         <PlacementCard Placement={Placement}></PlacementCard>
         </div>
         <div>
         <PortfolioValueCard Value={PortfolioValue} PercentChange={PercentChange}></PortfolioValueCard>

         </div>
         <div>
         <TimeLeftCard EndDate ={EndDate}></TimeLeftCard>

         </div>
        
          </div>  */}
          
         
          
       
           
        </Card>
        </Content>
    )
    }
    else{
        return(
            <span></span>
        )
    }
}

export default PlayerTournamentCard;

/*
        <Button onClick={props.onClick} style={{marginTop:'2vh',marginBottom:'1vh',width:'95vw', height:'10vh',color:'#F68C1D', backgroundColor:'black',  borderColor:color}}>

            
            <Row style={{justifyContent:'space-between', alignItems:'center'}}>
            <Col>
            {PremiumTournament}
            </Col>

            <Col>
                {Placement}
            </Col>

            <Col>
            ${PortfolioValue}
            </Col>

            <Col>
            {PercentChange}
            </Col>

            <Col>
            {EndDate}
            </Col>
            </Row>
            </Button>

            */
import {Auth, API} from 'aws-amplify';

const registerForTournament= (RegisterObj) => {

  console.log(RegisterObj)
        let apiName = 'MarketKingsAPI'
        let path = '/standings/api/Register'
        let myInit = {body:RegisterObj}
    
        return API.post(apiName, path, myInit)
        .then(response =>{
          console.log(response)
          return response
        })
        .catch(err =>{ return {Status:'Error', msg:'There was a problem... please try again!'}})
         
};

export default registerForTournament;
import React,{useState, useEffect} from 'react';
import {Modal, Input, Button, Slider, InputNumber, Row, Col, Space, Alert, Layout, Divider, Icon} from 'antd'
import {
    CaretUpOutlined,
    CaretDownOutlined,
    CloseOutlined,
    PlusOutlined,
    MinusOutlined
  } from '@ant-design/icons';
import submitTransaction from '../../Utils/submitTransaction'
import getLatestQuote from '../../Utils/getLatestQuote';
import {Auth, API, input} from 'aws-amplify';

let plus = <PlusOutlined style={{color:'#F68C1D', fontSize:32, }} />
let minus = <MinusOutlined style={{color:'#F68C1D', fontSize:32, }} />

let close = <CloseOutlined style={{color:'#F68C1D'}}/>
let {Content} = Layout

const SellModal = (props) =>{
    //const [percentChange, setPercentChange]= useState(0)
    const [inputValue, setInputValue] = useState(0);
    const [costTotal, setCostTotal]= useState(0);
    const [TxData, setTxData] = useState(0);
    //const [quote,setQuote] = useState(props.quote)

    let SellModalVisible = props.Visible
    let SelectedHolding = props.SelectedHolding
    let UserTournInfo = props.UserTournInfo
    console.log(SelectedHolding)
    let quote =props.quote
    let submitOrder = props.submitOrder
    //console.log('QUOTE MODAL: ',quote)

    let percentChange = quote?(quote.changePercent*100).toFixed(2):0;
    let percentChangeCarrot;
    if(quote)
    {
        if(percentChange>0)
         {
            percentChangeCarrot =<p1 style={{color:'green'}}><CaretUpOutlined/>${quote.change} ({percentChange}%)</p1>
            }
        else
        {
            percentChangeCarrot =<p1 style={{color:'red'}}><CaretDownOutlined/>${quote.change} ({percentChange}%)</p1>
        }   
        }
        else{
            console.log('qUoTe DoEsNt ExIst');
        }
   // console.log('SelectedHolding Modal:', SelectedHolding)
   // const [symbol,setSymbol] = useState('tsla')
   // let setSelected = props.setSelected
   // const [selectedHolding,s]
    
   // let  percentChangeCarrot =<p1 style={{color:'green'}}><CaretUpOutlined/>$0{/*quote.change*/} ({percentChange}%)</p1>

    

    

// const [costTotal, setCostTotal ] = useState(0)
// const [orderSubmit, setOrderSubmit] = useState(false)
// const [TxData, setTxData] = useState([])
// const [TxAlert,setTxAlert] = useState( 'Please select the amount of shares and your order type.' )

// //const [quote,setQuote] = useState(props.quote?props.quote:'')
// let TournamentID=props.TournamentID;
// let quote = props.quote;
// let marketData=props.marketData;
// let Balance = props.balance;
// let userInfo = props.userInfo;
// let submitOrder = props.submitOrder;
// let holdings = 0;

// let computeMaxShares = (price) =>{
//     let maxShares = ((Balance)-(holdings*price))/price
//     return maxShares;
// }

// // useEffect(() => {
// //   let updateTX = ()=>{
// //     console.log('TESTing quote change')
// //   }
// //   updateTX();
// // }, [quote])

useEffect(()=>{
  let computeCostTotal = () => {
      if(quote && SelectedHolding){

    if( SelectedHolding.TxType =="BUY")
        {
          let cost = quote.latestPrice * inputValue;
          setCostTotal(cost.toFixed(2));
          console.log(cost.toFixed(2))
        }
      else{
        let cost = SelectedHolding.Price * inputValue;
        
        let gain = ((Number(SelectedHolding.Price) - Number(quote.latestPrice))*Number(inputValue))
        let tv = cost+gain
      
        setCostTotal(tv.toFixed(2));
        console.log(cost)
      }
    }
    else{
        setCostTotal(0)
    }
  };

computeCostTotal();
}, [inputValue,quote])

let onChange = value =>{
  //value=Number(value)
  //console.log(value)

    setInputValue(value)
}

// // useEffect(()=>{
// //     let computePercentChange =()=>{
// //       setCostTotal(0)
// //       setInputValue(0)
// //       setTxData([])
// //         let percentChange = Number((((marketData[0].close - marketData[0].open )/marketData[0].close)*100).toFixed(2))
// //         console.log(percentChange)
// //         if(percentChange >0){
            
// //             setPercentChange(()=><p1 style={{color:'green'}}><CaretUpOutlined/>{percentChange}%</p1>)
            
// //         }
// //         else{
// //             setPercentChange(()=><p1 style={{color:'red'}}><CaretDownOutlined/>{percentChange}%</p1>)
                
// //         }
// //     };
// //     computePercentChange()
// // },[marketData])


// useEffect(()=>{
//   // const handleTransaction = async (TxData) =>{
//   //   const txResult = await submitTransaction(TxData);
//   //   console.log(txResult)
//   // };
//   // handleTransaction(TxData);
//  console.log('insideUSEEFFECT TXDATA:',TxData)
//   let alertTx = () => {
//     if(TxData.length==0)
//       {
//           let m ='Please select the amount of shares and your order type.' 
//           console.log(m)
//           setTxAlert(()=><Alert message={m} type="info" />)


//       }
//       else{
//         let m = TxData.TxType + " " + TxData.Shares + " shares of " + TxData.Symbol + " @ " + TxData.Price + " each for: $" + (TxData.Price*TxData.Shares).toFixed(2)
//         console.log(m)
//         setTxAlert(()=><Alert message={m} type="info" />)
//       }
//  };
//  alertTx();
// },[TxData])

let handleOrderType= async (orderType)=>{

//  console.log('UserInfo',userInfo)
  let TimeStamp = new Date();
  TimeStamp.toString();
 console.log('PlayerID:',9)
  console.log('Symbol:', SelectedHolding.Symbol)
  console.log('Shares:',inputValue)
  console.log('Price:', quote.latestPrice)
  console.log('Cost:', costTotal)
  console.log('ORDER TYPE:',orderType)
  console.log('TimeStamp: ', TimeStamp)
  console.log('SELECTED HOLDING', SelectedHolding)
  if(costTotal!=0)
  {
    
    let TxObj = {UserID:UserTournInfo.UserID,TournamentID:UserTournInfo.TournamentID, Symbol:SelectedHolding.Symbol,TransactionID:SelectedHolding.TransactionID, Shares:inputValue, Price: quote.latestPrice, TxType: orderType, TimeStamp:TimeStamp,Total:costTotal }
    setTxData(TxObj)
    setInputValue(0);
    submitOrder(TxObj)
    
  }
  else{
    alert("You can't "+orderType+" 0 shares...")
  }
}
  
         //   });
//}

if(quote && SelectedHolding)
{
return(
  //  <Modal visible={SellModalVisible} onCancel={()=>props.SetSelected(null)}></Modal>
  <Modal
  bodyStyle={{backgroundColor:'#141414',color:'#F68C1D',}}
  
  closeIcon={close}
  visible={SellModalVisible}
  onCancel={()=>{props.SetSelected(null)
                setCostTotal(0)
                setInputValue(0)}}
  footer={null}
> 
<Content style={{}}>
  <Row style={{marginTop:20, display:'flex', alignItems:'center',justifyContent:'space-evenly'}}>
    <Col>
    <h1 style={{color:'#F68C1D'}}>{(quote.symbol.toUpperCase())}</h1>
    </Col>

    <Col>
      <Row style={{display:'flex', alignItems:'center'}}>
        <h1 style={{color:'#F68C1D'}}> ${quote.latestPrice}</h1>

        <Col style={{display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center',marginLeft:5}}>
          <Row style={{}}>
            {percentChangeCarrot}
          {/* <h5 style={{color:'#F68C1D', }}> {percentChange} </h5> */}
          </Row>
        </Col>

       </Row>
    </Col>
  </Row>

<Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D',  }}> </Divider>

<Row style={{marginTop:20, display:'flex', justifyContent:'center' }}>
 
  <Row style={{marginBottom:20, display:'flex', justifyContent:'center', alignItems:'center' }}>
    <Col>
      <h4 style={{color:'#F68C1D'}}>Please select an amount of shares:</h4>
    </Col>
   </Row>

  
    <Row style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
      <Button onClick={()=>setInputValue(inputValue>0?inputValue-1:0)} ghost style={{backgroundColor:'black', color:'#F68C1D', borderColor:'#F68C1D', display:'flex', alignItems:'center', justifyContent:'center',marginRight:10 }} type="primary" shape="circle" icon={minus} size={'large'} />
      <Input disabled={true} onChange={onChange} style={{width:'30%', height:50, borderColor:'#F68C1D',textAlign:'center', backgroundColor:'#141414', fontSize:32, color:'#F68C1D'}} type='number' defaultValue={0} value={inputValue}/>
      <Button onClick={()=>{setInputValue(inputValue<SelectedHolding.NumberOfShares?inputValue+1:SelectedHolding.NumberOfShares)}} ghost style={{backgroundColor:'black', color:'#F68C1D', borderColor:'#F68C1D', display:'flex', alignItems:'center', justifyContent:'center',marginLeft:10  }} type="primary" shape="circle" icon={plus} size={'large'} />
    </Row>
    
    <Row style={{width:'100vw', marginTop:15}}>
   
     <Slider
      style={{width:'100%', color:'#F68C1D', }}
      
      min={0}
      max={SelectedHolding.NumberOfShares?SelectedHolding.NumberOfShares:0}
      onChange={onChange}  
      value={typeof inputValue === 'number' ? inputValue : 0} 
      >
     
     </Slider>

  </Row>
  <Row style={{width:'100vw', marginTop:15, display:'flex', justifyContent:'space-evenly'}}>
  {/* <h4 style={{color:'#F68C1D'}}>Current Balance: $ 0{Balance}</h4> */}
  <h4 style={{color:'#F68C1D'}}>Transaction Value: $ {costTotal}</h4>

  </Row>
</Row>

<Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D',  }}> </Divider>


<Row style={{marginTop:20, display:'flex', justifyContent:'center', }}>
 
  {/* <Row style={{marginBottom:20, display:'flex', justifyContent:'center', alignItems:'center' }}>
    <Col>
      <h4 style={{color:'#F68C1D'}}>Please select an order type:</h4>
    </Col>
  </Row> */}

</Row>

<Row style={{display:'flex', justifyContent:'space-evenly'}}>
    <Button type="round" style ={{backgroundColor:'#F68C1D', width:'80%', borderColor:'transparent', color:'black'}} onClick={()=>handleOrderType('Close')}>Close Position</Button>
    {/*<Button type="round" style ={{backgroundColor:'Red', width:'40%',  borderColor:'transparent', color:'black'}} onClick={()=>handleOrderType('SHORT')}>Short</Button>*/}
</Row>


</Content>


</Modal>
      

);
}
else{
    return (
        <div></div>
    )
}


}

export default SellModal;
import React,{useState, useEffect} from 'react';
import {Modal, Input, AutoComplete, Button, Row, Col, Divider, Layout} from 'antd'
import { SearchOutlined} from '@ant-design/icons';

const {Content}= Layout;

const TournamentDetailsModal = (props) =>{

    console.log('PROPS',props.details)
    if(props.details)
    {
        let aTourn=props.details;

        

        let TournamentLength = '1'+ aTourn.TournamentLengthType.toUpperCase();
        let StartDate = aTourn.StartDate.split('T')[0]
        let EndDate = aTourn.EndDate.split('T')[0];
        let StartTimeUn=((aTourn.StartDate.split('T')[1]).split('Z')[0])
        let StartTime = StartTimeUn.split('.')[0]
        let EndTimeUn=((aTourn.EndDate.split('T')[1]).split('Z')[0])
        let EndTime=EndTimeUn.split('.')[0]

        
        
        let MaxEntries = aTourn.MaxEntries;
        let EntryFee = aTourn.EntryAmount;
        let RewardStructure = '150 to Winner, 75 to Second, 50 to third, 25 to fourth, 15 to third, 5 to fifth';
        let RewardAmount = aTourn.RewardAmount;
        let RewardType = aTourn.PremiumTournament==0?'Coins':'Cash Prize';

        
        let CryptoTournament = aTourn.CryptoTournament==0?'Stocks':'Crypto Currencies';
        let TradingEnabled = aTourn.TradingEnabled==0?'No':'Yes'
        let PublicPortfolio = aTourn.PublicPortfolio==0?'Private (until end)':'Public'

        let StartingBalance = aTourn.StartingBalance;
        let MinimumAssetValue = aTourn.MinimumAssetValue;
        let MaximumAssetValue = aTourn.MaximumAssetValue;
        let MaxAllocation = aTourn.MaxPurchasePercentage;
        let Description = aTourn.Description;
       
        // console.log('starttime',StartTime)
    return(
    <Modal
    style={{position:'sticky', top:0}}
    bodyStyle={{backgroundColor:'#141414',color:'#F68C1D'}}
    closable={false}
    visible={props.visible}
    onCancel={()=>{props.onCancel(null)}}
    footer={null}
  > 
  <Content style={{}}>
       <Row style={{marginTop:20, display:'flex', alignItems:'center',justifyContent:'space-evenly'}}>
        <Col>
            <h2 style={{color:'#F68C1D'}}>Tournament Details</h2>  
        </Col>
      </Row>
      <Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D', marginTop:'-1vh' }}> </Divider>

    

      {/* <Row style={{ display:'flex', alignItems:'center'}}>
        <Col>
            <h5 style={{color:'#F68C1D'}}>Length: {TournamentLength}</h5>
        </Col>
      </Row> */}
      <Row style={{ display:'flex', alignItems:'center',}}>
        <Col style={{width:'25vw'}}>
            <h5 style={{color:'#F68C1D'}}>Start: </h5>
        </Col>
        <Col style={{marginLeft:'2vw'}}>
            <h5 style={{color:'#F68C1D'}}>{StartDate} @ {StartTime} E.S.T.</h5>
        </Col>
      </Row>
      <Row style={{ display:'flex', alignItems:'center', }}>
        <Col style={{width:'25vw'}}>
            <h5 style={{color:'#F68C1D'}}>End:</h5>
        </Col>
        <Col style={{marginLeft:'2vw'}}>
            <h5 style={{color:'#F68C1D'}}> {EndDate} @ {EndTime} E.S.T.</h5>
        </Col>
      </Row>

    <Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D'}}> </Divider>

    <Row style={{ display:'flex', alignItems:'center',}}>
        <Col style={{width:'25vw'}}>
            <h5 style={{color:'#F68C1D'}}>Asset Types:</h5>
        </Col>
        <Col style={{marginLeft:'2vw'}}>
            <h5 style={{color:'#F68C1D'}}>{CryptoTournament}</h5>
        </Col>
    </Row>

    <Row style={{ display:'flex', alignItems:'center', }}>
        <Col style={{width:'25vw'}}>
            <h5 style={{color:'#F68C1D'}}>Trading:</h5>
        </Col>
        <Col style={{marginLeft:'2vw'}}>
            <h5 style={{color:'#F68C1D'}}>{TradingEnabled}</h5>
        </Col>
    </Row>

    <Row style={{ display:'flex', alignItems:'center', }}>
        <Col style={{width:'25vw'}}>
            <h5 style={{color:'#F68C1D'}}>Portfolio:</h5>
        </Col>
        <Col style={{marginLeft:'2vw'}}>
            <h5 style={{color:'#F68C1D'}}>{PublicPortfolio}</h5>
        </Col>
    </Row>



    <Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D'}}> </Divider>

    <Row style={{ display:'flex', alignItems:'center',}}>
        <Col style={{width:'25vw'}}>
            <h5 style={{color:'#F68C1D'}}>Start Balance:</h5>
        </Col>
        <Col style={{marginLeft:'2vw'}}>
            <h5 style={{color:'#F68C1D'}}>${StartingBalance}</h5>
        </Col>
    </Row>

    <Row style={{ display:'flex', alignItems:'center', }}>
        <Col style={{width:'25vw'}}>
            <h5 style={{color:'#F68C1D'}}>Max Asset Price:</h5>
        </Col>
        <Col style={{marginLeft:'2vw'}}>
            <h5 style={{color:'#F68C1D'}}>${MaximumAssetValue}</h5>
        </Col>
    </Row>

    <Row style={{ display:'flex', alignItems:'center', }}>
        <Col style={{width:'25vw'}}>
            <h5 style={{color:'#F68C1D'}}>Min Asset Price:</h5>
        </Col>
        <Col style={{marginLeft:'2vw'}}>
            <h5 style={{color:'#F68C1D'}}>${MinimumAssetValue}</h5>
        </Col>
    </Row>

    <Row style={{ display:'flex', alignItems:'center', }}>
        <Col style={{width:'25vw'}}>
            <h5 style={{color:'#F68C1D'}}>Max Allocation:</h5>
        </Col>
        <Col style={{marginLeft:'2vw'}}>
            <h5 style={{color:'#F68C1D'}}>{MaxAllocation}%</h5>
        </Col>
    </Row>


    <Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D'}}> </Divider>

    <Row style={{ display:'flex', alignItems:'center', }}>
        <Col style={{width:'25vw'}}>
            <h5 style={{color:'#F68C1D'}}>Max Players:</h5>
        </Col>
        <Col style={{marginLeft:'2vw'}}>
            <h5 style={{color:'#F68C1D'}}>{MaxEntries}</h5>
        </Col>
    </Row>

    <Row style={{ display:'flex', alignItems:'center',}}>
        <Col style={{width:'25vw'}}>
            <h5 style={{color:'#F68C1D'}}>Entry Fee:</h5>
        </Col>
        <Col style={{marginLeft:'2vw'}}>
            <h5 style={{color:'#F68C1D'}}>{EntryFee} Coins</h5>
        </Col>
    </Row>
    

    <Row style={{ display:'flex', alignItems:'center', }}>
        <Col style={{width:'25vw'}}>
            <h5 style={{color:'#F68C1D'}}>Reward Pot:</h5>
        </Col>
        <Col style={{marginLeft:'2vw'}}>
            <h5 style={{color:'#F68C1D'}}>{RewardAmount}{aTourn.PremiumTournament==0?' Coins':' $'}</h5>
        </Col>
    </Row>

   

    <Row style={{ display:'flex', alignItems:'center', }}>
        <Col style={{width:'25vw'}}>
            <h5 style={{color:'#F68C1D'}}>Reward Structure:</h5>
        </Col>
        <Col style={{marginLeft:'2vw'}}>
            <h5 style={{color:'#F68C1D'}}>{RewardStructure}</h5>
        </Col>
    </Row>

    <Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D'}}> </Divider>

    <Row style={{display:'flex', justifyContent:'space-evenly'}}>
    <Button type="round" style ={{backgroundColor:'Red', width:'40%',  borderColor:'transparent', color:'black'}} onClick={()=>props.onCancel(null)}>Cancel</Button>
    <Button type="round" style ={{backgroundColor:'green', width:'40%', borderColor:'transparent', color:'black'}} onClick={()=>props.onRegister()}>Register</Button>

</Row>


  {/* <Row style={{marginTop:20, display:'flex', alignItems:'center',justifyContent:'space-evenly'}}>
    <Col>
    <h1 style={{color:'#F68C1D'}}>{TournamentLength}</h1>
    </Col>

    <Col>
      <Row style={{display:'flex', alignItems:'center'}}>
        <h1 style={{color:'#F68C1D'}}> $test</h1>

        <Col style={{display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center',marginLeft:5}}>
          <Row style={{}}><h5 style={{color:'#F68C1D', }}> test</h5></Row>
        </Col>

       </Row>
    </Col>
  </Row>

<Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D',  }}> </Divider>

<Row style={{marginTop:20, display:'flex', justifyContent:'center' }}>
 
  <Row style={{marginBottom:20, display:'flex', justifyContent:'center', alignItems:'center' }}>
    <Col>
      <h4 style={{color:'#F68C1D'}}>Message:</h4>
    </Col>
   </Row>

  
    
    
    <Row style={{width:'100vw', marginTop:15}}>
   

  </Row>
  <Row style={{width:'100vw', marginTop:15, display:'flex', justifyContent:'space-evenly'}}>
  <h4 style={{color:'#F68C1D'}}>TEST</h4>
  <h4 style={{color:'#F68C1D'}}>TEST</h4>
  



  </Row>
</Row>

<Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D',  }}> </Divider>


<Row style={{marginTop:20, display:'flex', justifyContent:'center', }}>
 
  <Row style={{marginBottom:20, display:'flex', justifyContent:'center', alignItems:'center' }}>
    <Col>
      <h4 style={{color:'#F68C1D'}}>Please select an order type:</h4>
    </Col>
  </Row>

</Row>

<Row style={{display:'flex', justifyContent:'space-evenly'}}>
    <Button type="round" style ={{backgroundColor:'green', width:'40%', borderColor:'transparent', color:'black'}} onClick={()=>console.log('test')}>test</Button>
    <Button type="round" style ={{backgroundColor:'Red', width:'40%',  borderColor:'transparent', color:'black'}} onClick={()=>console.log('test')}>test</Button>
</Row>
 */}

</Content>

  </Modal>
    )
    }
    else{
        return(
            <Modal
    
            bodyStyle={{backgroundColor:'#141414',color:'#F68C1D'}}
            closable={false}
            visible={props.visible}
            onCancel={props.onCancel}
            footer={null}
          ></Modal>
        )
    }
}


export default TournamentDetailsModal;
import {Auth, API} from 'aws-amplify';

const getStandings= async (UserTournObj) => {
    
        let apiName = 'MarketKingsAPI'
        let path = '/standings/api/getStandings'
        let myInit = {body:UserTournObj}
    
        return API.post(apiName, path, myInit)
        .then(response =>{
         console.log(response)
          return response
        })
        .catch(err=>console.log(err))
      
};

export default getStandings;
import {Auth, API} from 'aws-amplify';

const getTournaments= async () => {
    
        let apiName = 'MarketKingsAPI'
        let path = '/standings/api/getTournaments'
        // let myInit = {tournamentID:tournamentID}
        let myInit ={}
        return API.post(apiName, path, myInit)
        .then(response =>{
         console.log(response)
          return response
        })
        .catch(err=>console.log(err))
      
};

export default getTournaments;
import {Auth, API} from 'aws-amplify';

const submitTransaction= (TxObj) => {

  console.log(TxObj)
        let apiName = 'MarketKingsAPI'
        let path = '/standings/api/aTransaction'
        let myInit = {body:TxObj}
    
        return API.post(apiName, path, myInit)
        .then(response =>{
          //console.log(response)
          return response
        })
        .catch(err=>console.log(err))
         
};

export default submitTransaction;
import React, {useState, useEffect} from 'react';

import {Layout, Row,Image} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';

const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let SymbolContainer =(props) =>{
    // logo={tradeScreenData.logo.url}
    // symbol={Symbol}
    // companyName={tradeScreenData.companyInfo.companyName}
    // latestPrice={latestPrice}
    // open={tradeScreenData.quote.iexOpen}
    // close={tradeScreenData.quote.iexClose}
    // quote={tradeScreenData.quote}

    // console.log('props.logo', props.logo)
    let ImageURL = props.data.logo?props.data.logo.url:''
    let symbol = props.data.companyInfo.symbol?props.data.companyInfo.symbol:''
    let latestPrice = props.quote.latestPrice
   // let companyName='testing testing testing compan'
    let companyName;
    companyName=props.data.companyInfo.companyName
        if(companyName.length>26)
        {
             companyName=companyName.slice(0,23)+'...'

        }
    
    let priceChange = props.quote.change?props.quote.change:0;
    let percentChange = props.quote.changePercent?(props.quote.changePercent*100).toFixed(2):0;

    //let percentChange = Number((((close - open )/close)*100).toFixed(2))
    //let priceChange = Number(((close - open ).toFixed(2)))

    //console.log('percentChange:',percentChange)
   // console.log('price Change:', priceChange)

    let percentChangeCarrot;
    if(percentChange>0)
    {
        percentChangeCarrot =<p1 style={{color:'green'}}><CaretUpOutlined/>${priceChange} ({percentChange}%)</p1>
    }
    else
    {
        percentChangeCarrot =<p1 style={{color:'red'}}><CaretDownOutlined/>${priceChange} ({percentChange}%)</p1>
    }   
    return(
        <Content style={{width:'88vw', marginLeft:'5vw',marginRight:'2.5vw',marginTop:'6vh'}}>
            <Row>
            <Image  preview={false} width={50} height={50} src={ImageURL}></Image>

            </Row>

        <Row style={{color:'#F68C1D',fontSize:24}}>
            {symbol}
        </Row>
        <Row style={{color:'#F68C1D',fontSize:24}}>
            {companyName}
        </Row>
        <Row style={{color:'#F68C1D',fontSize:24}}>
            ${latestPrice} 
        </Row>
        <Row style={{color:'#F68C1D',fontSize:24}}>
            {percentChangeCarrot}
        </Row>
       
            {/* <Row style={{display:'flex', alignContent:'center', justifyContent:'space-evenly'}}>
                <Card style={{backgroundColor:'black', borderColor:'#F68C1D', color:'#F68C1D', height:'6vh', width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                     ${close} 
                </Card>
                <Card style={{backgroundColor:'black', borderColor:'#F68C1D', color:'#F68C1D', height:'6vh', width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    
                    
                        {percentChangeCarrot}
                    
                </Card>

            </Row>
            */}
            
        </Content>
    );



}

export default SymbolContainer;
import React, {useState, useEffect, } from 'react';

import {Layout, Row,Card, Col, Button, Alert,Divider,  Input} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined, CloseCircleFilled,
    CheckCircleFilled,SearchOutlined} from '@ant-design/icons';

import StockCard from '../ExploreScreenComponents/StockCard'
import ATournamentCard from '../ATournamentCard';
import {Link} from 'react-router-dom'

const {Search} = Input

let error = <CloseCircleFilled style={{color:'#F68C1D'}}/>
let success = <CheckCircleFilled style={{color:'#F68C1D'}}/>
//const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const searchOutlined = <SearchOutlined style={{ color:'#F68C1D',}} />
let SearchButton = <Button link style={{backgroundColor:'black',border:'transparent'}}><SearchOutlined style={{color:'#F68C1D',}} /></Button>
const {Content} = Layout;


let StockSearch =(props) =>{


     let TournamentID = props.TournamentID
    // console.log('StockCardSection TOurnament ID: ', TournamentID)
    //let TournamentID = aTourn.TournamentID;
let stocks;

    if(props.stocks){
         stocks = props.stocks
    }

    else{
         stocks = []
    }
    return(
        <Content >


         <Search  style={{backgroundColor:'white',color:'red', marginBottom:'3vh', width:'90vw'}} bordered={false} placeholder="Please enter a stock symbol or company." onChange={props.onChange} onSearch={props.onSearch}  enterButton={SearchButton}/>
  

     <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',marginTop:0}}/>


             {stocks.length>0?stocks.map((aStock, key)=>{
                console.log('aStock',aStock)

                return(
                    
                    <Link to={"/Trade/"+TournamentID+"/"+aStock.symbol}>
                        <StockCard key={key} stock={aStock}></StockCard>

                    </Link>
                )
            }):<h1 style={{color:'#F68C1D'}}>Nothing found..</h1>
            } 
           
        </Content>
        
          
    );
      
    
}

export default StockSearch;
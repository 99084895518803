import React,{useState,useEffect} from 'react';
import '.././App.css';
import styles from '../Components/styles.less'
import { Layout, Menu, Breadcrumb, Image, Col, Divider, Card, Alert } from 'antd';

import getPlayerInfo from '../Utils/getPlayerInfo';
import getPlayerTransactions from '../Utils/getPlayerTransactions'
import TransactionList from '../Components/TransactionList'
import PortfolioBalance from '../Components/PortfolioBalance'
import CustomFooter from '../Components/CustomFooter'
import {Auth, API} from 'aws-amplify';
import getPlayerHoldings from '../Utils/getPlayerHoldings';
import Loading from '../Components/Loading'
import ErrorLoading from '../Components/ErrorLoading'
import TopTournamentContainer from '../Components/TopTournamentContainer';
const { Header, Content, Footer, Sider} = Layout;
const { SubMenu } = Menu;




let Portfolio = (props)=> {
  let TournamentID = props.match.params.TournamentID;
  console.log("TOURNAMENT ID portfolio: ", TournamentID)

     const [UserID, setUserID]=useState();


    const [loading, setLoading]= useState(true);
    const [aloading, setaLoading]= useState(true);

    const [UserData, setUserData]=useState([]);
    const [Transactions, setTransactions] = useState([]);
    const [PortfolioTabContents, setPortfolioTabContents] = useState(()=><Alert message='Portfolio' type="warning"></Alert>)
    const [TransactionTabContents, setTransactionTabConents] = useState(()=><Alert message='No Transactions' type="warning"></Alert>)
    const [Tab, setTab] = useState('Portfolio')
    const tabList = [
        {
            key: 'Portfolio',
            tab: 'Portfolio',
          },
          {
            key: 'Transactions',
            tab: 'Transactions',
          },
    ]
    const [contentList,setContentList] =useState({
        Portfolio: PortfolioTabContents,
        Transactions: TransactionTabContents
    })
    const [error,setError] =useState(false)
    const [zerror,setzError] =useState(true)

    const [Holdings, setHoldings] = useState([])


    useEffect(()=>{
        let setTabs =()=>{
        setContentList({
            Portfolio:PortfolioTabContents,
            Transactions:TransactionTabContents
        })
    }
    setTabs();
    },[TransactionTabContents, PortfolioTabContents]);

useEffect(()=>{
    const fetchUserPortfolio = async () => {
      
        Auth.currentAuthenticatedUser()
             .then(user =>{
                 console.log('user: ',user)
                 //setUserID(user.attributes.sub)
                 
                // let UserInfo = {UserID:user.attributes.sub}
                 let UserTournObj ={UserID:user.attributes.sub, TournamentID:TournamentID}
                 getPlayerTransactions(UserTournObj)
                    .then(txs =>{
                        console.log('txs: ',txs)
                        //setTransactions(txs)
                        if(txs.length>0)
                        {
                            setTransactionTabConents(()=><TransactionList txs={txs}></TransactionList>) 
                            setLoading(false)
                        }
                        
                    })
                    .catch(err=>{
                      console.log(err)
                      setError(true)
                      setLoading(false)
                      
                    })

                
                getPlayerHoldings(UserTournObj)
                .then(holdings =>{
                    if(holdings)
                    {
                        //data={result}
                        console.log('holdings:', holdings)
                        setPortfolioTabContents(()=><PortfolioBalance data={holdings.data} holdings={holdings.currentholdings}></PortfolioBalance>)
                        setLoading(false)

                    }
                    else
                    {
                      setError(true)
                    setLoading(false)
                    }
                })
                .catch(err=>{
                  console.log(err)
                  setError(true)
                  setLoading(false)
                  
                })
             })
             .catch(err=>{
              console.log(err)
              setError(true)
              setLoading(false)
              
            })

            
        //const txs= await ;
        //setTransactions(txs);
        
        //const holdings = await getPlayerHoldings(9);
        //console.log('holdings:' , holdings.currentholdings)
       // const result = await getPlayerInfo('Mike');
        //setUserData(result);
       // setHoldings(holdings.currentholdings);
       // console.log('length',holdings.currentholdings.length)
        
       /* if(holdings.data.currentholdings)
        {
          let h =holdings.data.currentholdings
          //let s=''
          let s = []
          for(const key in h)
          {
            // console.log('Data:', h[key].Symbol)
            // if(!s.includes(h[key].Symbol.toString()))
            // {
            //  // s=s+h[key].Symbol.toString() + ','
            //  //s.push(h[key].Symbol.toString())
            //  const symbolsData= await getQuoteForSymbol(s[0])
            // }
              let symbolsData= await getQuoteForSymbol(h[key].Symbol.toString())
              s.push(symbolsData)
          }
          //s=s.substring(0,s.length-1)
         // const symbolsData= await getQuoteForSymbol(s[0])
          //console.log('SYMBOLS DATA',symbolsData)
          console.log('finalS:',s)

        }
        
        if(txs.length> 0){
           // setTransactionTabConents(()=><TransactionList txs={txs}></TransactionList>)
        }
        
        if(result && holdings)
        {
         //  setPortfolioTabContents(()=><PortfolioBalance txs={txs} data={result} holdings={holdings.currentholdings}></PortfolioBalance>)
        }
        else{

        }*/
        
        
     
    };
    fetchUserPortfolio();
  }, []);
  let tabBarStyle={color:'#F68C1D', borderColor:'red',}
  

  if(loading == true){
    return(
      <Content className="Content">
        
        <Loading message="Loading your portfolio..."></Loading>
        <CustomFooter TournamentID={TournamentID} defaultKey='1' ></CustomFooter>
    </Content>
    
    );
  }
  else if(error == true){
    return(
        <ErrorLoading ></ErrorLoading>
    )
}
  else{
    return(
        <Content className="Content" >
          <TopTournamentContainer rightCornerMessage ={' '} centeredMessage={'Portfolio'}></TopTournamentContainer>
          <Card 
          className="portfolio"
            bordered={false}
            headStyle={{color:'#F68C1D' , borderColor:'#141414',}}
            //style={{width: '100%', color:'#F68C1D' , borderColor:'#141414'}}
          style={{ width: '100%',marginTop:'6vh', backgroundColor:'#141414', color:'#F68C1D'}}
          tabProps={{tabBarStyle:tabBarStyle, }}
          //tabProps={{className={styles.customTabs}}}
          tabList={tabList}
          activeTabKey={Tab}
          onTabChange={key => {
          setTab(key)
          }}
          >
          {contentList[Tab]}
          
          </Card>
          <CustomFooter TournamentID={TournamentID} defaultKey='1' ></CustomFooter>
        </Content>
    )
  }
}
export default Portfolio
import React,{useState,useEffect} from 'react'
import {Card, Space, Row, Divider, Statistic,Col,Layout,Alert} from 'antd'
import {
  CaretUpOutlined,
  CaretDownOutlined
} from '@ant-design/icons';
const { Header, Content, Footer, Sider} = Layout;

const PortfolioBalance = (props) => {
   
  //let txs = props.txs
  console.log(props.data)
  let data = props.data
  let holdings = props.holdings
  let hKeys = Object.keys(holdings)
  console.log('keys',hKeys)
  console.log('holdings',holdings)
 
  if(hKeys.length>0)
  {
    return(
       
    <Content>        
        <Card
      style={{backgroundColor:'#141414', borderColor:'#F68C1D'}}
        >
          
        <Row gutter={16} style={{backgroundColor:'#141414'}}>
       
        <Col span={12}>
          <Statistic valueStyle={{color:'#F68C1D'}} style={{color:'#F68C1D'}} title={<h5 style={{color:'#F68C1D'}}>Account Balance (VUSD) </h5>} value={"$"+data[0].Balance} precision={2} />
        </Col>
        <Col span={12}>
            <Statistic valueStyle={{color:'#F68C1D'}} title={<h5 style={{color:'#F68C1D'}}>Portfolio Value (VUSD) </h5>}  value={"$"+data[0].PortfolioValue} />
        </Col>
        

        </Row>
      
        </Card>
        <Divider style={{color:'#F68C1D'}}>  Portfolio</Divider>
        <Row >
        
          <Col span={8}>
            <h5 style={{color:'#F68C1D'}} >Symbol</h5>
            <h5 style={{color:'#F68C1D'}} >Shares/Type</h5>
          </Col>

          <Col span={8}>
            <h5 style={{color:'#F68C1D'}} >Price</h5>
            <h5 style={{color:'#F68C1D'}}>Change/change %</h5>
          </Col>
      
          <Col span={8}>
            <h5 style={{color:'#F68C1D'}}>Value</h5>
            <h5 style={{color:'#F68C1D'}}>Gain/Loss</h5>
          </Col>
       
        </Row>    
        
        
        {hKeys.map((keyz,index)=>{
        return(
        <div key={index}>
        <Card
        style={{ marginTop: 16 , width:'100%',height:'10vh',borderColor:holdings[keyz].Color ,backgroundColor:'black'}}
        type="inner"
        >
      <Row style={{display:'flex', justifyContent:'space-between'}}>
      <Col span={8}>
      <h6 style={{color:'#F68C1D'}}>{holdings[keyz].Symbol}</h6>
      <h6 style={{color:'#F68C1D'}}>{holdings[keyz].NumberOfShares}/{holdings[keyz].TxType}</h6>
      </Col>
      <Col span={8}>
      <h6 style={{color:'#F68C1D'}}>{holdings[keyz].CurrentPrice}</h6>
      <h6 style={{color:'#F68C1D'}}>{holdings[keyz].Change}/{holdings[keyz].PercentChange}%</h6>
      </Col>
      <Col span={8}>
      <h6 style={{color:'#F68C1D'}}>{holdings[keyz].Value}</h6>
      <h6 style={{color:'#F68C1D'}}>{(holdings[keyz].Change * holdings[keyz].NumberOfShares)}</h6>
      </Col>
        

      </Row>
        
      </Card>
      </div>
        )
        })}
        
        </Content>
   
)


}
else{
  return(       
      <Content>        
        <Card
      style={{backgroundColor:'#141414', borderColor:'#F68C1D'}}
        >
          
        <Row gutter={16} style={{backgroundColor:'#141414'}}>
       
        <Col span={12}>
          <Statistic valueStyle={{color:'#F68C1D'}} style={{color:'#F68C1D'}} title={<h5 style={{color:'#F68C1D'}}>Account Balance (VUSD) </h5>} value={"$"+data[0].Balance} precision={2} />
        </Col>
        <Col span={12}>
            <Statistic valueStyle={{color:'#F68C1D'}} title={<h5 style={{color:'#F68C1D'}}>Portfolio Value (VUSD) </h5>}  value={"$"+data[0].PortfolioValue} />
        </Col>
        

        </Row>
      
        </Card>
        <Divider style={{color:'#F68C1D'}}>  Portfolio</Divider>
        <Row >
        
          <Col span={8}>
            <h5 style={{color:'#F68C1D'}} >Symbol</h5>
            <h5 style={{color:'#F68C1D'}} >Shares/Type</h5>
          </Col>

          <Col span={8}>
            <h5 style={{color:'#F68C1D'}} >Price</h5>
            <h5 style={{color:'#F68C1D'}}>Change/change %</h5>
          </Col>
      
          <Col span={8}>
            <h5 style={{color:'#F68C1D'}}>Value</h5>
            <h5 style={{color:'#F68C1D'}}>Gain/Loss</h5>
          </Col>
       
        </Row>      
        
        <Card style={{backgroundColor:'black', borderColor:'#F68C1D', color:'#F68C1D'}}>
        {/* <Alert style={{backgroundColor:'black',borderColor:'transparent', color:'#F68C1D'}} message='No Holdings' type="warning"></Alert> */}
            No holdings
        </Card>
        
        </Content>
  )
}
}


export default PortfolioBalance;
import react,{useEffect, useState} from 'react';

import {Layout, Card, Col, Row, Space, Button} from 'antd';

//import {ArrowRightOutlined} from '@ant-design/icons';
import Standings from '../Routes/Standings';
import {ArrowRightOutlined} from '@ant-design/icons';


const rightArrow = <ArrowRightOutlined style={{fontSize:18, color:'#F68C1D',}}/>
const {Content}= Layout;

const StandingsHeader = () =>{
   
        return(
            
            <div style={{display:'flex',width:'90vw',height:'5vh',margin:5, backgroundColor:'black',justifyContent:'space-evenly',alignItems:'center' , border:'1px solid #8c8c8c', color:'#F68C1D'}}>

              <Col style={{color:'#8c8c8c', width:'30%'}}>
                Position
              </Col>

              <Col style={{color:'#8c8c8c',width:'40%',display:'flex', justifyContent:'flex-start', alignItems:'flex-start'}}>
              Username

              </Col>
              
               <Col style={{color:'#8c8c8c',width:'40%',display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
               Portfolio Value
             

              </Col>
              

            
            </div>
            )

    
}

export default StandingsHeader;
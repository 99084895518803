import React, {useEffect, useState} from 'react';
import './tournamentTile.styles.css';
import 'antd/dist/antd.css';

//const StandardImg = require('./assets/')
import StandardImg from './assets/Standard.png';
import PremiumImg from './assets/Premium.png';
import { Statistic, Spin } from 'antd';
import {ClockCircleOutlined } from '@ant-design/icons';

const { Countdown } = Statistic;


function TournamentTile(props) {
    let Standard,Weekly,Startdate,Enddate,Counttime,Value,Place =1
   
    const [standard,setstandard]=useState(true);
    const [weekly,setweekly]=useState(true);
    const [startdate,setstartdate]=useState('');
    const [enddate,setenddate]=useState('');
    const [countdown,setcountdown]=useState();
    const [value,setvalue]=useState('');
    const [place,setplace]=useState('');
    const [percent,setpercent]=useState('');
    const [timeleft,setTimeLeft]= useState();


    useEffect(()=>{
        let tournament = props.tournament;
        console.log('Tournament: ', tournament)
        setstartdate(tournament.StartDate);
        setenddate(tournament.EndDate);
        tournament.PremiumTournament===0?setstandard(true):setstandard(false);
        tournament.TournamentLengthType==="W"?setweekly(true):setweekly(false);
        setvalue(tournament.PortfolioValue);
        setplace(tournament.Placement +1);

        tournament.TimeLeft>0?setcountdown(Date.now()+tournament.TimeLeft):setcountdown(0)
       // let timeLeft = (new Date(enddate).getTime())-Date.now();
      //  setTimeLeft(timeLeft)
        //console.log('here9')
        

      
    },[]
    );

    // useEffect(() => {
    //     console.log('here time left use effect')
    //     let tl = Date.now() + timeleft;
    //     setcountdown(tl)
    // }, [timeleft])


   
  return (
    <div className='tournament-tile-container'>

        {standard === true?
        <img className='tournament-img tournament-block--1' src={StandardImg} alt='standard tournament'/>:
        <img className='tournament-img tournament-block--1' src={PremiumImg} alt='premium tournament'/>   
        }

        <div className='tournament-block--2'>
            <div className='tournament-info'>
                {
                weekly === true?
                (<h3>Weekly Tournament</h3>):
                (<h3>Daily Tournament</h3>)
                }
                
                 {/*Delete <p>03/01/2021 - 03/05/2021</p>  */ }
                {/*<p>03/01/2021 - 03/05/2021</p>   */}
                 <p>{new Date(startdate).toLocaleDateString()} - {new Date(enddate).toLocaleDateString()}</p> 
            </div>    

            <div>
                <li class="playButton" href="#"></li>
            </div>
        </div>
        <div className='tournament-block--3'>
                <div className='tournament-text'style={{borderRight:'3px solid #F68C1D'}}>
                        {/*Delete $20*/ }
                        {/*Value: $20 */}
                        Value:${value} 
                        &nbsp; 
                    <span style={percent>0?{color:'green'}:{color:'red'}}>
                        {/*Delete 10% */ }
                         {/* 10%*/ }
                        {percent}
                    </span></div>
                <div className='tournament-text' style={{borderRight:'3px solid #F68C1D'}}>
                        {/*Delete 1st*/ }
                        Place: 
                        {place}
                </div>
                <div className='tournament-countdown'>
                    <div style={{marginTop:'7px'}}><ClockCircleOutlined /></div> 
                    <div>{countdown?
                    <Countdown value={countdown} format="HH:mm:ss" />:<p>Concluded</p>
                    }
                    </div>
                </div>
        </div>
    </div>
  );
}

export default TournamentTile;



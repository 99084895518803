import React,{useState, useEffect} from 'react';
import {Modal, Input, Button, Slider, InputNumber, Row, Col, Space, Alert, Spin, Layout, Divider, Checkbox} from 'antd'
import {
    LoadingOutlined
  } from '@ant-design/icons';
import registerForTournament from '../Utils/registerForTournament'
import {Auth, API} from 'aws-amplify';
import { AxeResults } from 'axe-core';

const {Content} = Layout
const anIcon = <LoadingOutlined style={{fontSize:72, color:'#F68C1D'}} spin>Loading</LoadingOutlined>

const RegistrationConfirmationModal = (props) =>{

let [loading,setLoading] = useState(false)
let [registrationAlert,setRegistrationAlert]=useState()
let [checkbox, setCheckBox]= useState(false)

let TD = props.TournamentDetails;
let UserID = props.UserInfo.UserID;
let Username = props.UserInfo.Username;
let handleOk = async ()=>{
    
    let RegisterObj ={UserID:UserID, TournamentID:TD.TournamentID, Username:Username}
    console.log(RegisterObj)
    setLoading(true)
    registerForTournament(RegisterObj).then(response =>{
        console.log('response', response)
        let m= response.Status + ': '+response.msg;
        setLoading(false)
        if(response.Status=='Success')
          {
             setRegistrationAlert(()=><Alert message={m} type="success"/>)
             setLoading(false)
             setTimeout(()=>{
        
            props.onCancel()
            setCheckBox(false)
            setRegistrationAlert()
        },1000)
    
          }
        else{

        setRegistrationAlert(()=><Alert message={m} type="error"/>)
        setLoading(false)
        setTimeout(()=>{
        
        props.onCancel()
        setRegistrationAlert()
        setCheckBox(false)
        },2000)

        }
    })
    .catch(err=>{
        console.log(err)
    })
//   const txResult = await submitTransaction(TxData);
//   console.log(txResult);
//   let m= txResult.TransactionResult + ': '+txResult.message;
//   if (txResult.TransactionResult == 'Success')
//   {
//     setTxAlert(()=><Alert message={m} type="success"/>)
//     setLoading(false)
//     setTimeout(()=>{
        
//         props.onConfirmOrderCancel()
//         setTxAlert()},1000)
    
//   }
//   else{
//     setTxAlert(()=><Alert message={m} type="error"/>)
//     setLoading(false)
//     setTimeout(()=>{
        
//         props.onConfirmOrderCancel()
//         setTxAlert()},1500)

//   }
// console.log('onOK')
}

//computePercentChange()
if(loading)
{
    return(
    <Modal
    style={{}}
    bodyStyle={{backgroundColor:'#141414',color:'#F68C1D'}}
    closable={false}
    visible={props.registerModalVisible}
    footer={null}
    //onOk={props.onOk(TxData)}
    onCancel={props.onCancel}
    //marketData={props.marketData}
  > 
 <div style={{display:'flex', justifyContent:'center'}}>
 <Spin indicator={anIcon} ></Spin>

 </div>

    
  </Modal>
    )
}
else if(registrationAlert)
{
    return(
    <Modal
    style={{}}
    bodyStyle={{backgroundColor:'#141414',color:'#F68C1D'}}

    closable={false}
    visible={props.registerModalVisible}
    footer={null}

    //TxData ={props.TxData}
    //onOk={props.onOk(TxData)}
    onCancel={props.onCancel}
    //marketData={props.marketData}
   
  > 
 <div style={{display:'flex', justifyContent:'center'}}>
 {registrationAlert}

 </div>

    
  </Modal>
    )
}


else if(TD)
{
return(
      
    <Modal
    style={{}}
    closable={false}
    visible={props.registerModalVisible}
    bodyStyle={{backgroundColor:'#141414',color:'#F68C1D'}}
    footer={null}
    onCancel={props.onCancel}
  > 
 <Content style={{}}>
       <Row style={{marginTop:20, display:'flex', alignItems:'center',justifyContent:'space-evenly'}}>
        <Col>
            <h2 style={{color:'#F68C1D'}}>Registration Confirmation</h2>  
        </Col>
      </Row>
      <Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D', marginTop:'-1vh' }}> </Divider>

      <Row style={{ display:'flex', alignItems:'center',}}>
        <Col style={{width:'10%'}}>
            <Checkbox checked={checkbox} onChange={()=>setCheckBox(!checkbox)}></Checkbox>
        </Col>
        <Col style={{marginLeft:'2vw',width:'85%'}}>
            <h5 style={{color:'#F68C1D'}}>By confirming your registration, you are agreeing that you have throughly reviewed the tournament details.</h5>
        </Col>
    </Row>

    <Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D'}}> </Divider>

    <Row style={{display:'flex', justifyContent:'space-evenly'}}>
    <Button type="round" style ={{backgroundColor:'Red', width:'40%',  borderColor:'transparent', color:'black'}} onClick={()=>props.onCancel(null)}>Cancel</Button>
    <Button type="round" style ={{backgroundColor:'green', width:'40%', borderColor:'transparent', color:'black'}} disabled={!checkbox} onClick={()=>handleOk()}>Confirm</Button>

</Row>
</Content>
  </Modal>
);


}
else {
    return (
        <Modal
        closable={false}
        visible={props.registerModalVisible}
        footer={null}

        //TxData ={props.TxData}
        //onOk={props.onOk(TxData)}
        onCancel={props.onCancel}
        //marketData={props.marketData}
     
      > 
       
        
      </Modal>
    )
}
}



export default RegistrationConfirmationModal;
import React, {useState, useEffect} from 'react';

import {Layout, Row, Button, Col} from 'antd'
import {LeftOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';

const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let TopTournamentContainer =(props) =>{
    let back;
    if(props.backButton)
    {
         back = props.backButton;
    }
    else{
         back = "/Tournaments"
    }
    let rightCornerMessage =props.rightCornerMessage;
    let centeredMessage = props.centeredMessage

    return(
        <Content style={{width:'100vw',height:'6vh', backgroundColor:'#141414',position:'fixed', zIndex:1,borderBottomWidth:'5vh',borderBottomColor:'#F68C1D' }}>
           <Row style={{display:'flex', alignContent:'center', justifyContent:'space-between'}}>

           
            <Col>
            <Link to={back} style={{position:'absolute'}}>
                    <Button type="primary" ghost style ={{ borderColor:'transparent'}} icon={leftIcon} />
            </Link>
            </Col>
            <Col>
            <div style={{display:'flex', marginTop:5,}}>
            <h1 style={{ color:'#F68C1D',}}>{centeredMessage}</h1>
            </div>
            </Col>
                <Col>
               <div style={{ marginTop:5, }}>
                    <h3 style={{ color:'#F68C1D',marginRight:2}}>{rightCornerMessage}</h3>
               </div>
               </Col>
            </Row>
            
        </Content>
    );



}

export default TopTournamentContainer;
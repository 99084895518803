import React,{useState, useEffect} from 'react';
import {Modal,  Button, Card, Row, Col} from 'antd'
import { SearchOutlined,CloseOutlined} from '@ant-design/icons';
let close = <CloseOutlined style={{color:'#F68C1D'}}/>

const PlayerHoldingsModal = (props) =>{

    
    if(props.holdings)
    {
    let holdings = props.holdings.currentholdings
    let hKeys = Object.keys(holdings)

   
    let userData = props.holdings.data[0]
    console.log(holdings)
    return(
    <Modal
    bodyStyle={{backgroundColor:'#141414',color:'#F68C1D'}}
    closable={true}
    closeIcon={close}
    visible={props.visible}
    onCancel={props.ToggleModal}
    footer={null}
  > 
  <Card style={{ marginTop: 20, width:'83vw',height:'10vh',borderColor: '#F68C1D',backgroundColor:'black', display:'flex',alignItems:'center', }} type="inner">

      <Row style={{width:'100%',display:'flex',alignItems:'center', justifyContent:'space-evenly'}}>
      <Col><h5 style={{color:'#F68C1D'}}>Player: {userData.Username}</h5></Col>

      <Col style={{marginLeft:20}}>
      <h5 style={{color:'#F68C1D'}}>Portfolio Value: ${userData.PortfolioValue}</h5>
      <h5 style={{color:'#F68C1D'}}>Balance: ${userData.Balance}</h5>
      </Col>
      </Row>


  </Card>
 {hKeys.map((keyz,index)=>{
        return(
        <div key={index}>
        <Card
        style={{ marginTop: 16 , width:'83vw',height:'10vh',borderColor:holdings[keyz].Color ,backgroundColor:'black'}}
        type="inner"
        >
      <Row>
      <Col span={8}>
      <h6 style={{color:'#F68C1D'}}>{holdings[keyz].Symbol}</h6>
      <h6 style={{color:'#F68C1D'}}>{holdings[keyz].NumberOfShares}/{holdings[keyz].TxType}</h6>
      </Col>
      <Col span={8}>
      <h6 style={{color:'#F68C1D'}}>{holdings[keyz].CurrentPrice}</h6>
      <h6 style={{color:holdings[keyz].Color}}>{holdings[keyz].Change}/{holdings[keyz].PercentChange}%</h6>
      </Col>
      <Col span={8}>
      <h6 style={{color:'#F68C1D'}}>{holdings[keyz].Value}</h6>
      <h6 style={{color:holdings[keyz].Color}}>{(holdings[keyz].Change * holdings[keyz].NumberOfShares).toFixed(2)}</h6>
      </Col>
        

      </Row>
        
      </Card>
      </div>
        )
        })}

  </Modal>
    )
}
else 
{
    return(
        <Modal
        bodyStyle={{backgroundColor:'#141414',color:'#F68C1D'}}
        closable={true}
        closeIcon={close}
        visible={props.visible}
        onCancel={props.ToggleModal}
        footer={null}
      > 
     
    
      </Modal>
        )
}
}


export default PlayerHoldingsModal;
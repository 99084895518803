import React,{useState,useEffect} from 'react'
import {Card, Space, Row, Divider, Statistic,Col, Layout, Spin} from 'antd'
import {CaretUpOutlined, CaretDownOutlined, LoadingOutlined
} from '@ant-design/icons';
const { Header, Content, Footer, Sider} = Layout;
const anIcon = <LoadingOutlined style={{fontSize:72,  color:'#F68C1D'}} spin>Loading</LoadingOutlined>

const Loading = (props) => {

    let LoadingMessage = props.message;
    let customStyle = props.customStyle;
    if(props.customStyle)
    {
        return(
            <Content  style={customStyle}>
                <Spin indicator={anIcon} ></Spin>
                <div style={{marginTop:20}}>
                    <h1 style={{fontSize:18, color:'#F68C1D'}}>{LoadingMessage}</h1>
                </div>
            </Content>
        )
    }
    else{
   
    return(
        <Content  style={{marginTop:'30vh'}}>
            <Spin indicator={anIcon} ></Spin>
            <div style={{marginTop:20}}>
                <h1 style={{fontSize:18, color:'#F68C1D'}}>{LoadingMessage}</h1>
            </div>
        </Content>
    )
    }

}

export default Loading;
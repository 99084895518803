import react,{useEffect, useState} from 'react';

import {Layout, Card, Col, Row, Space, Button} from 'antd';

//import {ArrowRightOutlined} from '@ant-design/icons';
import Standings from '../Routes/Standings';
import {ArrowRightOutlined, LoadingOutlined} from '@ant-design/icons';



const {Content}= Layout;

const Standing = (props) =>{

    let [loading,setLoading]=useState(false)

    let Username = props.Username;
    let UserID = {UserID: props.UserID}
    let PortfolioValue = props.PortfolioValue;
    let Position = props.Position;
    let CurrentUser = props.CurrentUser

    const rightArrow = <ArrowRightOutlined style={{fontSize:18, color:CurrentUser?'#F68C1D':'#8c8c8c',}}/>
    const aloading = <LoadingOutlined style={{fontSize:18, color:CurrentUser?'#F68C1D':'#8c8c8c',}}/>


    let Place = Position+1
    if(Place<4)
    {
        if(Place == 1 ){Place = Place+'st'}
        else if(Place == 2){Place = Place+'nd'}
        else{Place = Place+'rd'}
    }
    else{
        Place=Place+'th'
    }

    let loadingData = (UserID) =>{
        setLoading(true)
        console.log('UserID:', UserID)
        props.CheckHoldings(UserID).then(()=>{
            setLoading(false)
        })

    }

   
        return(
            
            <div style={{display:'flex',width:'90vw',height:'5vh',margin:5, backgroundColor:'black',justifyContent:'space-evenly',alignItems:'center' , border:CurrentUser?'1px solid #F68C1D': '1px solid #8c8c8c', color:'#F68C1D'}}>

              <Col style={{color:CurrentUser?'#F68C1D':'#8c8c8c', width:'30%'}}>
                {Place}
              </Col>

              <Col style={{color:CurrentUser?'#F68C1D':'#8c8c8c',width:'40%',display:'flex', justifyContent:'flex-start', alignItems:'flex-start'}}>
              {Username}

              </Col>
              
               <Col style={{color:CurrentUser?'#F68C1D':'#8c8c8c',width:'30%',display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
               ${PortfolioValue}
             

              </Col>
              <Col style={{color:'#F68C1D',width:'10%',display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
                  <Button onClick={()=>loadingData(UserID)}  type="primary" ghost style ={{ border: '0px hidden #F68C1D'}} icon={loading?aloading:rightArrow} >
                        
                  </Button>
             

              </Col>

            
            </div>
            )

    
}

export default Standing;
import React,{useState, useEffect} from 'react';
import '.././App.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import {Auth, API, ServiceWorker} from 'aws-amplify';
import {
    isPushNotificationSupported,
    askUserPermission,
    registerServiceWorker,
    createNotificationSubscription,
    getUserSubscription,
    sendNotification,
     
  } from "../Utils/push-notifications";
  import Loading from '../Components/Loading'
  import ErrorLoading from '../Components/ErrorLoading';
  import MainFooter from '../Components/MainFooter'
import { Layout, Menu, Breadcrumb, Col, Divider,Card,Cascader, Dropdown,Row ,Select } from 'antd';
import {DownOutlined} from '@ant-design/icons'
import TopHomeContainer from '../Components/TopHomeContainer'
import TopTournamentContainer from '../Components/TopTournamentContainer'
import {useLocation} from "react-router-dom";
import StockCardSectionContainer from '../Components/ExploreScreenComponents/StockCardSectionContainer';
import CustomFooter from '../Components/CustomFooter'
import {Link} from 'react-router-dom'
import StockSearch from '../Components/ExploreScreenComponents/StockSearch'
import getAutocomplete from '../Utils/getAutocomplete'
import getList from '../Utils/getList';

const { Header, Content, Footer, Sider} = Layout;
const { SubMenu } = Menu;
//const pushNotificationSupported = isPushNotificationSupported();

//const serviceWorker = new ServiceWorker();

let DownIcon = <DownOutlined style={{color:'#F68C1D'}} />

let Explore = (props) =>{
    // const [userConsent, setSuserConsent] = useState(Notification.permission);
    // const [error, setError] = useState(null);
     const [loading, setLoading] = useState(false);
     const [error, setError] = useState(false);
     const [list,setList] = useState('mostactive')
     const [stockList,setStockList]=useState([])
     const [searchText, setSearchText] =useState('')
     const [searchStocks,setSearchResults] = useState([])
     const [searchLength, setSearchLength]=useState([0,0])//Current Length ,prev Length

     useEffect(() => {
       let Search =()=>{
          console.log('Search Text UE:',searchText)
          getAutocomplete(searchText).then(response=>{
            console.log('response use effect: ',response)
            // let tSearchStocks = searchStocks;
            // tSearchStocks.push(...searchStocks,response)
            // console.log('tSearchStocks:', tSearchStocks)
              setSearchResults(response)
           
          })
       }
       Search();
      
     }, [searchText.length])

     useEffect(() => {
       let fetchList=(list)=>{
          setStockList()
         console.log('IN HERE')
          getList(list).then(response=>{
            console.log('response use effect fetchList...',response);
            if(response.Data)
            {
              setStockList(response.Data)
            }
            else{
              setError(true)
            }
            
            //let storageTop50={timeRetrieved:Date.now(), data:response}
            //console.log('StorageTop50', storageTop50.toString())

            //localStorage.setItem('MostPopular',response)
          })
       
      }
       //let a= Date.now()
      // console.log('Current Epoc',a)
     // let checkMostPopular= localStorage.getItem('MostPopular');
      //console.log('check most exists?',checkMostPopular)
       list!="Search"?fetchList(list):console.log('no list necessary for search');
      
     }, [list])

     let TournamentID = props.match.params.TournamentID;
     console.log("TOURNAMENT ID: ", TournamentID)

    let options = [
      {
        value:'Search',
        label:'🔎 Search'
        },
      {
      value:'mostactive',
      label:'💯 Most Popular'
      },
      {
        value:'gainers',
        label:'🚀 Biggest Gainers'
      },
      {
        value:'losers',
        label:'💣 Biggest Losers'
      },
        

    ]
    let handleChange =(value) =>{
      console.log('value:', value)
      
      setList(value)
    }
    let onChangeSearch =(value) =>{
      
      if(value.nativeEvent.inputType==="insertText")
      {
      let tSearch = searchText;
      tSearch = tSearch.concat(value.nativeEvent.data)
      setSearchText(tSearch)
      }
      else if(value.nativeEvent.inputType ==="deleteContentBackward")
      {
        let tSearch = searchText;
        tSearch =tSearch.substring(0,tSearch.length-1);
        setSearchText(tSearch);
      }

    }
   
    let ddRender = (menus) =>{
      return(
        <div style={{backgroundColor:'#141414', color:'#F68C1D'}}>
          {menus}
          <Divider style={{margin:0}}/>

        </div>
      )
    }



  if(loading == true){
    return(
      <Content className="Content">
        
              <Loading message="Getting ready to Explore the Markets!" ></Loading>
              <CustomFooter TournamentID={TournamentID} defaultKey='2' ></CustomFooter>
              </Content>
   
    );
  }
  else if(error == true){
      return(
          <ErrorLoading ></ErrorLoading>
      )
  }
else{


    return (
        <Content className="Content">
            <TopTournamentContainer centeredMessage={'Explore'} />
              <Content style={{marginTop:'6vh'}}>
                <Row style={{display:'flex',justifyContent:'center', marginTop:'1vh',marginBottom:'1vh',borderColor:'gold', alignItems:'center'}}>

                <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',marginTop:0}}>

                <Select defaultValue={list} style={{width:'50vw', color:'#F68C1D',  }} bordered={false} suffixIcon={DownIcon} options={options} onChange={handleChange}/> 


                </Divider>

                {/* <Cascader dropdownRender={ddRender} suffixIcon={DownIcon} allowClear={false} bordered={true} style={{color:'#F68C1D',backgroundColor:'transparent'}} options={options} onChange={onChange} /> */}

                </Row>
                {list==="Search"?<StockSearch TournamentID={TournamentID} stocks={searchStocks} onChange={onChangeSearch}></StockSearch>:<StockCardSectionContainer SectionName={list} stocks={stockList} TournamentID={TournamentID}/>

                  
                }

                    
              </Content>

              <CustomFooter TournamentID={TournamentID} defaultKey='2' ></CustomFooter>

      </Content>
    )
}
}
export default Explore
import React, {useState, useEffect} from 'react';

import {Layout, Select} from 'antd'
import {DownOutlined} from '@ant-design/icons';
const dropDownIcon = <DownOutlined style={{ color:'#F68C1D',}}/>
const {Option} = Select;
const {Content} = Layout;


let TimeFrameDropDown =(props) =>{

    let setChartTimeFrame = props.setChartTimeFrame;

    return(
        // <div style={{backgroundColor:'#F68C1D', alignSelf:'center',}}>
        <Select defaultValue="1D" bordered={false} style={{width:'37.5%',backgroundColor:'#141414' ,borderColor:'#F68C1D', color:'#F68C1D'}}suffixIcon={dropDownIcon} dropdownStyle= {{backgroundColor:'#141414'}}onSelect={setChartTimeFrame}>
                 <Option value="1D" style={{backgroundColor:'#141414' , color:'#F68C1D'}}>1 Day</Option>
                 <Option value="6h" style={{backgroundColor:'#141414',color:'#F68C1D'}}>6 Hour</Option>
                 <Option value="1H" style={{backgroundColor:'#141414' , color:'#F68C1D'}}>1 Hour</Option>
                 <Option value="15m" style={{backgroundColor:'#141414',color:'#F68C1D'}}>15 Min</Option>
                 <Option value="5m" style={{backgroundColor:'#141414' , color:'#F68C1D'}}>5 Min</Option>
                 <Option value="1m" style={{backgroundColor:'#141414',color:'#F68C1D'}}>1 Min</Option>
        </Select>
       // </div>
    );



}

export default TimeFrameDropDown;
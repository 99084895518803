import React, {useState, useEffect} from 'react';

import {Layout, Row, Button} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';

const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let StickyTradeButton =(props) =>{

    const [selected, setSelection] = useState(1);
    

   
    return(
        <Content style={{width:'100vw',height:'7vh',backgroundColor:'#F68C1D',position:'fixed', marginBottom:'10vh',bottom:0, display:'flex', justifyContent:'center', alignItems:"center"}}>
                <Button shape="round" style={{backgroundColor:'Black', color:'#F68C1D', borderColor:'#F68C1D', width:'90vw'}}type="primary" block  onClick={props.toggleModal}>Trade</Button> 
        </Content>
    );



}

export default StickyTradeButton;
import React,{useState, useEffect} from 'react';
import '.././App.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import {Auth, API, ServiceWorker} from 'aws-amplify';
import {
    isPushNotificationSupported,
    askUserPermission,
    registerServiceWorker,
    createNotificationSubscription,
    getUserSubscription,
    sendNotification
  } from "../Utils/push-notifications";
  import CustomFooter from '../Components/CustomFooter'
  import Loading from '../Components/Loading'
  import ErrorLoading from '../Components/ErrorLoading';
import { Layout, Menu, Breadcrumb, Col, Divider,Card  } from 'antd';
import {useLocation} from "react-router-dom";

const { Header, Content, Footer, Sider} = Layout;
const { SubMenu } = Menu;
//const pushNotificationSupported = isPushNotificationSupported();

//const serviceWorker = new ServiceWorker();

let Home = () =>{
    // const [userConsent, setSuserConsent] = useState(Notification.permission);
    // const [error, setError] = useState(null);
     const [loading, setLoading] = useState(false);
     const [error, setError] = useState(false);

     
    
    // useEffect(() => {
    //     if (pushNotificationSupported) {
    //       setLoading(true);
    //       setError(false);
    //       registerServiceWorker().then(() => {
    //         setLoading(false);
    //       });
    //     }
    //   }, []);

    //   const onClickAskUserPermission = () => {
    //     setLoading(true);
    //     setError(false);
    //     askUserPermission().then(consent => {
    //       setSuserConsent(consent);
    //       if (consent !== "granted") {
    //         setError({
    //           name: "Consent denied",
    //           message: "You denied the consent to receive notifications",
    //           code: 0
    //         });
    //       }
    //       setLoading(false);
    //     });
    //   };

    //   const onClickSendNotification = async () => {
    //       sendNotification()
    //     //setLoading(true);
    //     //setError(false);
    //    // await http.get(`/subscription/${pushServerSubscriptionId}`).catch(err => {
    //      // setLoading(false);
    //       //setError(err);
    //    // });
    //     //setLoading(false);
    //   };
    // const [notificationSupport, setNotificationSupport]=useState(false);
    // const [notificationPermission, setNotificationPermission] = useState();
    // useEffect(()=>{
    //     let isPushNotificationSupported= async ()=>{
    //         let support = "serviceWorker" in navigator && "PushManager" in window;
    //         setNotificationSupport(support);
    //     }
    //     isPushNotificationSupported()
    // },)


    // useEffect(()=>{
        
    //     let askPermission= async ()=>{
    //         console.log('Notification Support: ',notificationSupport)
    //          Notification.requestPermission().then(permission =>{
    //             setNotificationPermission(permission)
    //          })
    //     }
    //     askPermission();
    // },notificationSupport)


    // useEffect(()=>{
    //     console.log("Notification Permission:",notificationPermission)
    //     let registerServiceWorker = ()=> {
    //         return navigator.serviceWorker.register("../../public/sw.js");
    //       }
    //      registerServiceWorker();
    // },notificationPermission)



 //const [UserInfo, setUserInfo]=useState();
 // const [Loading, setLoading]=useState(true);

  //  useEffect(()=>{
  //      const fetchUserID = async () =>{
  //         Auth.currentAuthenticatedUser()
  //           .then(user =>{
  //               let userInfo = {UserID: user.attributes.sub, UserName:user.username}
  //               setUserInfo(userInfo);

  //               let apiName = 'MarketKingsAPI'
  //               let path = '/standings/api/CheckUser'
  //               let myInit = {body:userInfo}
            
  //               API.post(apiName, path, myInit)
  //                 .then(response =>{
  //                 console.log('A Response:',response)
  //                 setLoading(false)
  //                 }).catch(err=>console.log(err))
  //             }).catch(err=>console.log(err))
  //      };
  //      fetchUserID();
  //  },[])


  if(loading == true){
    return(
    
      <Loading message={'Bringing you home.. :)'}></Loading>
   
    );
  }
  else if(error == true){
      return(
          <ErrorLoading ></ErrorLoading>
      )
  }
else{


    return (
        <Content style={{marginBottom:'10vh', marginTop:'10vh'}}>
            <Card style={{minHeight:'100vh'}}>
        <Col >       
        <h2 className="title">  
            Welcome to the Market Kings Beta!
            </h2>
        </Col>
        <Col >
        <h1>
            The rules are simple...
        </h1>
        </Col>
        <Divider></Divider>

        <Col>
            Each player starts with $10,000 of Virtual USD that holds no real world value.
        </Col>
        <Divider></Divider>

            Before the start of the competition, each player will allocate their $10,000 virtual dollars into a collection of stocks.
            <Divider></Divider>
            You must allocate your virtual dollars by 9:30 AM Monday Morning
            
            <Divider></Divider>
            The portfolio with the highest balance at 4PM on Friday wins!
            <Divider></Divider>
            
            This week there will be three winners:
            1st: $15
            2nd: $10
            3rd: $5
        <Divider></Divider>
            I have made a few bug fixes on the trading screen which you all have brought to my attention. 
        
        <Col>
        
            
        </Col>
        <Divider></Divider>

        <Col>
        Thank you for participating in the closed beta! ( :
        </Col>
        
        <AmplifySignOut></AmplifySignOut>
        </Card>
        <CustomFooter defaultKey='1' ></CustomFooter>
      </Content>
    )
}
}
export default Home
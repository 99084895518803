import React,{useState,useEffect} from 'react';
import '.././App.css';
import styles from '../Components/styles.less'
import { Layout, Menu, Breadcrumb, Image, Col, Divider, Card, Alert } from 'antd';

import getPlayerInfo from '../Utils/getPlayerInfo';
import getPlayerTransactions from '../Utils/getPlayerTransactions'
import TransactionList from '../Components/TransactionList'
import PortfolioBalance from '../Components/PortfolioBalance'
import CustomFooter from '../Components/CustomFooter'
import {Auth, API} from 'aws-amplify';
import getPlayerHoldings from '../Utils/getPlayerHoldings';
import Loading from '../Components/Loading'
import ErrorLoading from '../Components/ErrorLoading'
import TopTournamentContainer from '../Components/TopTournamentContainer';
import PortfolioValue from '../Components/PortfolioScreenComponents/PortfolioValue';
import PortfolioHoldings from '../Components/PortfolioScreenComponents/PortfolioHoldings';
import SellModal from '../Components/PortfolioScreenComponents/SellModal';
import getLatestQuote from '../Utils/getLatestQuote';
import SubmitSellModal from '../Components/PortfolioScreenComponents/SubmitSellModal';
import getPlayerBalances from '../Utils/getPlayerBalances'
const { Header, Content, Footer, Sider} = Layout;
const { SubMenu } = Menu;




let NewPortfolio = (props)=> {
  let TournamentID = props.match.params.TournamentID;
  console.log("TOURNAMENT ID portfolio: ", TournamentID)

     const [UserID, setUserID]=useState();
     const [error,setError] = useState(false);
     const [loading, setLoading] = useState(true);
     const [transactions,setTransactions]= useState();
     const [holdings,setHoldings]= useState();
     const [Balances,setBalances]= useState({Balance:0,PortfolioValue:0})


     const [selected, setSelected] = useState();
     const [selectedSymbol, setSelectedSymbol] = useState()
     const [quote,setQuote]=useState()
     const [SellModalVisible,setSellModalVisible]= useState(false);
     const [TxData, setTxData] = useState();
     const [confirmOrderModal,setConfirmOrderModal] = useState();
     const [UserTournInfo, setUserTournInfo] = useState();

     const [reload,setReload] =useState(true)


     useEffect(() => {
        let fetchTransactions =()=>{
            Auth.currentAuthenticatedUser()
            .then(user =>{
                console.log('user: ',user)
              
                let UserTournObj ={UserID:user.attributes.sub, TournamentID:TournamentID}
                setUserTournInfo(UserTournObj)
                getPlayerBalances(UserTournObj).then(balances=>{
                  console.log("BALANCES:", balances)
                  setBalances(balances);
                })
                .catch(err=>{
                  setError(true);
                  setLoading(false);
                  console.log('err getting balances...', err)
                })
                getPlayerHoldings(UserTournObj)
                   .then(txs =>{
                       console.log('txs: ',txs)
                       //setTransactions(txs)
                       if(txs.length>0)
                       {
                           setHoldings(txs) 
                           setLoading(false)
                       }
                       else{
                           setHoldings([])
                           setLoading(false)
                       }
                       
                   })
                   .catch(err=>{
                     console.log(err)
                     setError(true)
                     setLoading(false)
                   })
                })
         }
         fetchTransactions();
        
     }, [reload])

     let selectedHolding = (selected)=>{
       console.log('Selected Holding: ',selected)
       setSelected(selected);
     }

     useEffect(() => {
      let selectedAPosition =()=>{
        if(selected)
        {
          console.log('sected.symbol', selected.Symbol)
          getLatestQuote(selected.Symbol).then(result=>{
            console.log('LatestQuote: ', result)
            setQuote(result)
            console.log('USEEFFECT SELECTEDAPOSITION: ',selected)
            setSellModalVisible(true);
          })
          
        }
        else{
          setQuote(null)
          setSellModalVisible(false)
          //clearInterval(intervalPrice)
          console.log('selected after false:', selected)
        }
      } 
      selectedAPosition();

     }, [selected])


     useEffect(() => {
      const fetchLatestQuote =()=>{
        
        getLatestQuote(selected.Symbol).then(result=>{
          console.log('LatestQuote: ', result)
          setQuote(result)
        })
      }
      let intervalPrice;
      if(quote)
      {
        if(quote.isUSMarketOpen)
        {
         console.log('setting INterval')
          intervalPrice = setInterval(fetchLatestQuote,10000)
        }
        else{
          console.log('Market is closed not updating!')
          clearInterval(intervalPrice)
        }
      }
      else{
      console.log('Havent gotten a quote yet');
      clearInterval(intervalPrice)
      }
      return () =>{
        clearInterval(intervalPrice)
      }
    }, [quote])
    
    let toggleConfirmOrderModal =() =>{
      if(confirmOrderModal)
      {
        setSelected(null);
        setConfirmOrderModal(false)
        setReload(!reload)
      //  setCheckBalance(!checkBalance)
      }
      else{
        setConfirmOrderModal(true)

      }
    }
    let submitOrder = (TxData)=>{
      //console.log('TEST')
      console.log('TxData:', TxData)
      setTxData(TxData);
      setSellModalVisible(false);
      setConfirmOrderModal(true);

}
  

  if(loading == true){
    return(
      <Content className="Content">
        
        <Loading message="Loading your portfolio..."></Loading>
        <CustomFooter TournamentID={TournamentID} defaultKey='1' ></CustomFooter>
    </Content>
    
    );
  }
  else if(error == true){
    return(
        <ErrorLoading ></ErrorLoading>
    )
}
  else{
    return(
        <Content className="Content" >
          <TopTournamentContainer rightCornerMessage ={' '} centeredMessage={'Portfolio'}></TopTournamentContainer>
           
                <PortfolioValue Balances={Balances}></PortfolioValue>
                <Alert type={'warning'} message={'* Portfolio Value only updates during market hours *'}/>
                <Content style={{marginTop:'5vh'}}>
                <PortfolioHoldings holdings={holdings} selectedHolding={selectedHolding}></PortfolioHoldings>
                </Content>
                <SellModal UserTournInfo={UserTournInfo} submitOrder={submitOrder} quote={quote} Visible={SellModalVisible} SetSelected={setSelected} SelectedHolding={selected}></SellModal>
                <SubmitSellModal SelectedHolding={selected} TxData={TxData} submitVisible={confirmOrderModal} onConfirmOrderCancel = {toggleConfirmOrderModal} quote={quote}></SubmitSellModal>
          <CustomFooter TournamentID={TournamentID} defaultKey='1' ></CustomFooter>
        </Content>
    )
  }
}
export default NewPortfolio;

/*const [loading, setLoading]= useState(true);
    const [aloading, setaLoading]= useState(true);

    const [UserData, setUserData]=useState([]);
    const [Transactions, setTransactions] = useState([]);
    const [PortfolioTabContents, setPortfolioTabContents] = useState(()=><Alert message='Portfolio' type="warning"></Alert>)
    const [TransactionTabContents, setTransactionTabConents] = useState(()=><Alert message='No Transactions' type="warning"></Alert>)
    const [Tab, setTab] = useState('Portfolio')
    const tabList = [
        {
            key: 'Portfolio',
            tab: 'Portfolio',
          },
          {
            key: 'Transactions',
            tab: 'Transactions',
          },
    ]
    const [contentList,setContentList] =useState({
        Portfolio: PortfolioTabContents,
        Transactions: TransactionTabContents
    })
    const [error,setError] =useState(false)
    const [zerror,setzError] =useState(true)

    const [Holdings, setHoldings] = useState([])


    useEffect(()=>{
        let setTabs =()=>{
        setContentList({
            Portfolio:PortfolioTabContents,
            Transactions:TransactionTabContents
        })
    }
    setTabs();
    },[TransactionTabContents, PortfolioTabContents]);

useEffect(()=>{
    const fetchUserPortfolio = async () => {
      
        Auth.currentAuthenticatedUser()
             .then(user =>{
                 console.log('user: ',user)
                 //setUserID(user.attributes.sub)
                 
                // let UserInfo = {UserID:user.attributes.sub}
                 let UserTournObj ={UserID:user.attributes.sub, TournamentID:TournamentID}
                 getPlayerTransactions(UserTournObj)
                    .then(txs =>{
                        console.log('txs: ',txs)
                        //setTransactions(txs)
                        if(txs.length>0)
                        {
                            setTransactionTabConents(()=><TransactionList txs={txs}></TransactionList>) 
                            setLoading(false)
                        }
                        
                    })
                    .catch(err=>{
                      console.log(err)
                      setError(true)
                      setLoading(false)
                      
                    })

                
                getPlayerHoldings(UserTournObj)
                .then(holdings =>{
                    if(holdings)
                    {
                        //data={result}
                        console.log('holdings:', holdings)
                        setPortfolioTabContents(()=><PortfolioBalance data={holdings.data} holdings={holdings.currentholdings}></PortfolioBalance>)
                        setLoading(false)

                    }
                    else
                    {
                      setError(true)
                    setLoading(false)
                    }
                })
                .catch(err=>{
                  console.log(err)
                  setError(true)
                  setLoading(false)
                  
                })
             })
             .catch(err=>{
              console.log(err)
              setError(true)
              setLoading(false)
              
            })

            
      
        
        
     
    };
    fetchUserPortfolio();
  }, []);
  let tabBarStyle={color:'#F68C1D', borderColor:'red',}
  */
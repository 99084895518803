import React,{useState,useEffect} from 'react';
import '.././App.css';

import getDailyChartForSymbol from '../Utils/getDailyChartForSymbol.js';
import getTournamentDetails from '../Utils/getTournamentDetails.js';
import { Layout, Menu, Breadcrumb, Input, Button, Modal, Row, Card, Select, Space} from 'antd';
import Loading from '../Components/Loading';
import Chart from '../Components/Chart'
import OrderModal from '../Components/OrderModal';
import {Auth,API} from 'aws-amplify'
import ErrorLoading from '../Components/ErrorLoading'
import CustomFooter from '../Components/CustomFooter'
import SubmitOrderModal from '../Components/SubmitOrderModal';
import SearchModal from '../Components/SearchModal'

import {Link} from 'react-router-dom'
import { LoadingOutlined, LeftOutlined, DownOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import ChartTypeDropDown from '../Components/ChartTypeDropDown';
import TimeFrameDropDown from '../Components/TimeFrameDropDown'
import ChartOptionsContainer from '../Components/ChartOptionsContainer';
import TopTournamentContainer from '../Components/TopTournamentContainer';
import TradeChangesContainer from '../Components/TradeChangesContainer';
import {useLocation, useHistory} from "react-router-dom";
import SymbolContainer from '../Components/TradeScreenComponents/SymbolContainer';
import NewChart from '../Components/TradeScreenComponents/NewChart';
import ChartOptions from '../Components/TradeScreenComponents/ChartOptions'
import KeyStats from '../Components/TradeScreenComponents/KeyStats'
import StickyTradeButton from '../Components/TradeScreenComponents/StickyTradeButton';
import News from '../Components/TradeScreenComponents/News'
import About from '../Components/TradeScreenComponents/About'

import tImage from '../marketKingLogo.png'

import getStockData from '../Utils/getStockData.js'
import getTradeScreenData from '../Utils/getTradeScreenData';
import getLatestPrice from '../Utils/getLatestPrice';
import getLatestQuote from '../Utils/getLatestQuote';
import getIntradayChart from '../Utils/getIntradayChart';
import getPreviousClose from '../Utils/getPreviousDayClose';
import getChartSegment from '../Utils/getChart';

const {Option} = Select;
const { Header, Content, Footer, Sider, Col } = Layout;
const {Search} = Input;
const { SubMenu } = Menu;



const NewTrade = (props)=> {
    let TournamentID = props.match.params.TournamentID;
    let Symbol = props.match.params.Symbol;
    console.log('Symbol:', Symbol)
    console.log("TOURNAMENT ID: ", TournamentID)
    let history = useHistory()

    const [symbol, setSymbol] = useState(Symbol);
    const [tradeScreenData, setTradeScreenData] = useState()
    const [latestPrice,setLatestPrice] = useState();
    const [logo,setLogo] = useState('../marketKingLogo.png')
    const [quote,setQuote]=useState()
    const [previousClose,setPreviousClose]= useState();
    const [chartTimeSelection,setChartTimeSelection]=useState(1);
    const [chartTimeOptions,setChartTimeOptions]=useState(['','intra','5dm','1mm','3m','1y'])
    const [chartLoading,setChartLoading]=useState(true);
    const [weekChart,setWeekChart] =useState();




    const [loading, setLoading]= useState(true);
    const [stockData, setStockData] = useState([]);
    const [showModal, setModal] = useState(false);
    const [userInfo, setUserInfo] = useState();
    const [balance, setBalance] = useState(0);
    const [chartTitle,setChartTitle] = useState('twtr')
    const [checkBalance, setCheckBalance] = useState(false)
    const [error, setError] = useState(false)

    const [tournamentDetails, setTournamentDetails] =useState()

    const [confirmOrderModal, setConfirmOrderModal] = useState(false)
    const [TxData,setTxData] = useState()

    const [chartType,setChartType]=useState('Line')
    const [percentChange,setPercentChange]=useState()
    const [chartTimeFrame, setChartTimeFrame]=useState('Day')
    const [searchModal,setSearchModal]= useState(false);
    const [checkedPrice,setCheckedPrice]=useState()

    const [NewsArticles,setNews]=useState([{datetime:'2hr', headline:'Twitter makes leaps against all odds in the current market conditions', source:'barrons', url:'marketkings.app', summary:'lorem ipsum ',image:tImage},{datetime:'2hr', headline:'Jack Dorsey endorses joe bidens steal for the country', source:'barrons', url:'marketkings.app', summary:'lorem ipsum ',image:tImage},{datetime:'2hr', headline:'Twitter makes leaps', source:'barrons', url:'marketkings.app', summary:'lorem ipsum ',image:tImage}])
    const [AboutInfo,setAboutInfo]= useState({ "symbol": "AAPL",
    "companyName": "Apple Inc.",
    "exchange": "NASDAQ",
    "industry": "Telecommunications Equipment",
    "website": "http://www.apple.com",
    "description": "Apple, Inc. engages in the design, manufacture, and marketing of mobile communication, media devices, personal computers, and portable digital music players. It operates through the following geographical segments: Americas, Europe, Greater China, Japan, and Rest of Asia Pacific. The Americas segment includes North and South America. The Europe segment consists of European countries, as well as India, the Middle East, and Africa. The Greater China segment comprises of China, Hong Kong, and Taiwan. The Rest of Asia Pacific segment includes Australia and Asian countries. The company was founded by Steven Paul Jobs, Ronald Gerald Wayne, and Stephen G. Wozniak on April 1, 1976 and is headquartered in Cupertino, CA.",
    "CEO": "Timothy Donald Cook",
    "securityName": "Apple Inc.",
    "issueType": "cs",
    "sector": "Electronic Technology",
    "primarySicCode": 3663,
    "employees": 132000,
    "tags": [
      "Electronic Technology",
      "Telecommunications Equipment"
    ],
    "address": "One Apple Park Way",
    "address2": null,
    "state": "CA",
    "city": "Cupertino",
    "zip": "95014-2083",
    "country": "US",
    "phone": "1.408.974.3123"
  })
    // const [chartOptions, setChartOptions]=useState(<Option value="Candlestick"></Option>)

    const onSearch = value =>{
      setSymbol(value.toString());
      if(searchModal)
      {
        setSearchModal(false)
      }
      console.log(value)
    } 

    // useEffect(()=>{
    //   let changeOptions = ()=>{
    //       if(chartType== "Line")
    //       {
    //           setChartOptions(<Option value="Candlestick"></Option>)
    //       }
    //       else{
    //         setChartOptions(<Option value="Line"></Option>)

    //       }
    //   }
    //   changeOptions()
    // },chartType)
  
    const toggleModal = () =>{
     // console.log()
    //  let dt = new Date();
    //  console.log('Tournament DEtails: ',tournamentDetails)
    //  console.log('dt',dt.toISOString())
    //  console.log('START TIME:')
    //  console.log('Hours:',dt.getHours())
    //  console.log("Day: ",dt.getDay())
    //  if(dt.getDay()>0 && dt.getDay()<5) 
    //  {
    //    if(dt.getHours()==9)
    //    {
    //     console.log('here')
    //     alert("Trading is now closed!")
    //    }
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
    // if(tournamentDetails.Status != 4 || (tournamentDetails.Status ==5 && tournamentDetails.TradingEnabled==1))
    // {
    //   alert("Trading is now closed")
    // }
        //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

      //  else{
      //   setModal(prevModal=>{return !prevModal});
      //   console.log(stockData[0].close)
      //   console.log(showModal)
      //   setCheckBalance(!checkBalance);
      // }
      
     //}
     if(false)
     {

     }
     else{
      setModal(prevModal=>{return !prevModal});
      console.log(stockData[0].close)
      console.log(showModal)
      setCheckBalance(!checkBalance);
    }
    } 

    useEffect(()=>
    {
      const fetchTournamentDetails = async () => {
        Auth.currentAuthenticatedUser()
        .then(user =>{
            let userInfo = {UserID: user.attributes.sub, TournamentID:TournamentID}
            console.log('userInfo',userInfo)
            setUserInfo(userInfo);

        getTournamentDetails(userInfo).then(result=>{
          console.log(result)
          setTournamentDetails(result.TournamentDetails)
          console.log('TOURNY DETAILS:',tournamentDetails)
        }).catch(err=>{
          console.log(err)
          setError(true)
          setLoading(false)

    })
    }).catch(err=>{
      console.log(err)
      setError(true)
      setLoading(false)
        
      })
    }

      fetchTournamentDetails();
    },[])    
    
   
   
    useEffect(()=>{
      const getUserBalance = async()=>{
        Auth.currentAuthenticatedUser()
        .then(user =>{
            let userInfo = {UserID: user.attributes.sub, TournamentID:TournamentID}
            let apiName = 'MarketKingsAPI'
            let path = '/standings/api/getUserBalance'
            let myInit = {body:userInfo}
        
            API.post(apiName, path, myInit)
              .then(response =>{
              console.log('A Response:',response[0].Balance)
              console.log("User info",userInfo)
              setBalance(response[0].Balance)
             // setLoading(false)
              }).catch(err=>{
                console.log(err)
                setError(true)
                setLoading(false)
              })

            });
          
   };
      getUserBalance();
    },[checkBalance])
   
  
useEffect(()=>{
  const fetchTradeScreenData = async ()=>{
    getTradeScreenData(symbol).then(results =>{
      console.log('results:',results);
      setTradeScreenData(results);
      setQuote(results.quote)
      setLatestPrice(results.quote.latestPrice);
      setLogo(results.logo.url)
      setNews(results.news)
      setAboutInfo(results.companyInfo)
      //setStockData(results.intraday)
      setPreviousClose(results.previous)
      //let sd=results.intraday;
      //let stockD=formatStockD(sd);
      let pd = results.previous
      let sd = results.intraday;
      let stockDataFormatted = [];
      if(sd[0].average == null)
      {
         let tStockDataEntry = sd[0];
         console.log('tstockData:',tStockDataEntry)
         tStockDataEntry.close = pd.close;
         tStockDataEntry.open = pd.close;
         tStockDataEntry.high = pd.close;
         tStockDataEntry.low = pd.close;
         tStockDataEntry.average=pd.close;
         
         //stockDataFormatted.push(tStockDataEntry)
         sd[0]=tStockDataEntry
      }
      for(let i=0;i<sd.length;i++)
      {
        
        if(sd[i].average ==null)
        {
          let tStockDataEntry = sd[i];
          tStockDataEntry.average=stockDataFormatted[i-1].average;
          tStockDataEntry.close=stockDataFormatted[i-1].average;
          tStockDataEntry.high=stockDataFormatted[i-1].average;
          tStockDataEntry.low=stockDataFormatted[i-1].average;
          stockDataFormatted.push(tStockDataEntry);
        }
        else
        {
          stockDataFormatted.push(sd[i])
        }
       
      }
      // if(stockDataFormatted<390 && chartTimeSelection==1)
      // {
      //   for(let i=stockDataFormatted.length;i <389; i++)
      //   {
      //     stockDataFormatted.push({})
      //   }
      // }
      
      setStockData(stockDataFormatted);
      console.log('StockData:',stockDataFormatted)
      setChartLoading(false)
      setLoading(false)
      

    }).catch(err=>{
      console.log('err getting trade screen data: ', err)
      setError(true);
      setLoading(false);
    })
  }
  fetchTradeScreenData();
},symbol)

// let formatStockD =(stockd)=>{
//   console.log(stockd)
//       let pd = previousClose
//       let sd = stockd
//       let stockDataFormatted = [];
//       if(sd[0].average == null)
//       {
//          let tStockDataEntry = sd[0];
//          console.log('tstockData:',tStockDataEntry)
//          tStockDataEntry.close = pd.close;
//          tStockDataEntry.open = pd.close;
//          tStockDataEntry.high = pd.close;
//          tStockDataEntry.low = pd.close;
//          tStockDataEntry.average=pd.close;
         
//          //stockDataFormatted.push(tStockDataEntry)
//          sd[0]=tStockDataEntry
//       }
//       for(let i=0;i<sd.length;i++)
//       {
        
//         if(sd[i].average ==null)
//         {
//           let tStockDataEntry = sd[i];
//           tStockDataEntry.average=stockDataFormatted[i-1].average;
//           tStockDataEntry.close=stockDataFormatted[i-1].average;
//           tStockDataEntry.high=stockDataFormatted[i-1].average;
//           tStockDataEntry.low=stockDataFormatted[i-1].average;
//           stockDataFormatted.push(tStockDataEntry);
//         }
//         else
//         {
//           stockDataFormatted.push(sd[i])
//         }
       
//       }
//       return stockDataFormatted;
// }
      // useEffect(()=>{
      //     const fetchStockData = async () => {
      //      // const result = await getDailyChartForSymbol(symbol);
      //      // setStockData(formatStockData(result.data['Time Series (Daily)']));
      //      // setLoading(false)
      //      getDailyChartForSymbol(symbol).then(result=>{
      //        if(result.data.note){
      //          console.log('Note',result.data.note)
               
      //          setError(true)
      //          //setLoading(false)
      //        }
           
      //        if(result.data['Error Message'])
      //        {console.log(result)
      //          alert("There was an error with your entry.. Please try again!")
      //          //setSymbol('twtr')
      //        }
      //        else{
               
      //          if(result.data['Time Series (Daily)']){
      //           setChartTitle(symbol)
      //           console.log("result.data['Time Series (Daily)']",result.data['Time Series (Daily)']);
      //           let data =formatStockData(result.data['Time Series (Daily)'])
      //           setStockData(data);
                
                
      //           //setLoading(false)
      //          }
      //         }
               
      //      })
      //      .catch(err =>{
      //        console.log("Error:",err)
      //        setError(true);
      //        setLoading(false);
      //      })
           
      //     };
      //     fetchStockData();
      //   }, [symbol]);

        // useEffect(()=>{
        //   const fetchStockChart = async () => {
        //     console.log('HERRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRE')
        //  getIntradayChart(symbol).then(result=>{

        //   console.log('ChartData:',result)

        //  }).catch(err=>{
        //    console.log('err:',err)
        //  })
           
        //   };
        //   fetchStockChart();
        // }, []);


        // useEffect(()=>{
        //   const setStockPrice = ()=>{
        //     console.log('###############IN HERE', stockData)
        //     if(stockData.length>0){
        //       setCheckedPrice(stockData[0].close)
        //     }
        //   };
        //   setStockPrice();
        // },[stockData])

        useEffect(()=>{
         let getChartForTimeSelection =()=>{
            console.log("HEEEEEEEEEEEEEEEEEEEEEEREEEEEEEEEEEEEEE TIME SELECTION:",chartTimeSelection)
            setChartLoading(true)
            if(chartTimeSelection==1)
            {
              getIntradayChart(symbol).then(response=>{
                console.log('GET INTRADAY:::::',response)

            
                setStockData(response);
                //let stockD=formatStockD(response);
               // setStockData(stockD)
                setChartLoading(false);
              })
            }
            else{
              getChartSegment(symbol,chartTimeOptions[chartTimeSelection]).then(response=>{
                console.log('response ChartSegment: ', response)
               setStockData(response);
               setChartLoading(false); 
              })
            
            }
          }
          getChartForTimeSelection();

        },[chartTimeSelection])



        useEffect(() => {
          const fetchLatestQuote =()=>{
            
            getLatestQuote(symbol).then(result=>{
              console.log('LatestQuote: ', result)
              setQuote(result)
            })
          }
          let intervalPrice;
       if(quote)
       {
          if(quote.isUSMarketOpen)
          {
            console.log('setting INterval')
            intervalPrice = setInterval(fetchLatestQuote,10000)
          }
          else{
            console.log('Market is closed not updating!')
            clearInterval(intervalPrice)
          }
       }
       else{
         console.log('Havent gotten a quote yet');
       }

       
         
        
          return () =>{
            clearInterval(intervalPrice)
          }
        }, [quote])

       

        // useEffect(()=>{
        //   let stockDataResult =()=>{
        //   if(stockData)
        //   {
        //     setLoading(false);
        //   }
        //   else
        //   {
        //     setError(true);
        //     setLoading(false);
        //   }
        // }
        // stockDataResult();

        // },[stockData])

        // useEffect(()=>{
        //   const load = async ()=>{
        //   //  setLoading(false)
        //   };
        //   load()
        // },[userInfo,chartTitle])

        let submitOrder = (TxData)=>{
          console.log('TEST')
          console.log('TxData:', TxData)
          setTxData(TxData);
          setModal(false);
          setConfirmOrderModal(true);

    }
    let toggleConfirmOrderModal =() =>{
      if(confirmOrderModal)
      {
        setConfirmOrderModal(false)
        setCheckBalance(!checkBalance)
      }
      else{
        setConfirmOrderModal(true)

      }
    }
    const toggleSearch = () => {
      console.log('test')
      setSearchModal(!searchModal)
    }

   


        if(loading == true){
            return(
              <Content className="Content">
        
              <Loading message="Getting ready to trade!" ></Loading>
              <CustomFooter TournamentID={TournamentID} defaultKey='2' ></CustomFooter>
              </Content>
            );
          }
          else if(error == true){
            return(
                <ErrorLoading ></ErrorLoading>
            )
        }
          else if(stockData[0].close && tradeScreenData){
           return (
               <Content className="Content">
                 
                 <SearchModal visible={searchModal} onCancel={toggleSearch} onSearch={onSearch}></SearchModal>
                <TopTournamentContainer backButton={"/Explore/"+TournamentID} rightCornerMessage={'Balance: $'+balance}/>
                <SymbolContainer data={tradeScreenData} quote={quote} ></SymbolContainer>

               <NewChart chartType={chartType} symbol={chartTitle} stockData={stockData} setPrice={setCheckedPrice} chartTimeSelection={chartTimeSelection} chartLoading={chartLoading}></NewChart>

                <ChartOptions setChartTimeSelection={setChartTimeSelection} setChartType={setChartType}></ChartOptions>
        
                <KeyStats quote={tradeScreenData.quote}></KeyStats>

                <News news={NewsArticles}></News>
                <About about={AboutInfo}> </About>

               
                   
               

                  {/* <div className="site-layout-background" style={{ padding: 10, Height: 10 }}>
                     <Search style={{backgroundColor:'#8c8c8c', borderRadius:10, }} bordered={false} placeholder="Please enter a stock symbol... ex. aapl" onSearch={onSearch} enterButton />
                 </div> */}
              {/* 
                 <div style={{color:'#F68C1D',display:"flex",justifyContent:'center',fontSize:32}}>
                    {chartTitle}
                 </div> */}
                 {/* <TradeChangesContainer open={stockData[0].open} close={stockData[0].close} ></TradeChangesContainer>

                  <Space> </Space>
               
                 <ChartOptionsContainer 
                      ChartSymbol={chartTitle} 
                      SymbolPrice={stockData[0].close}
                      ChartTimeFrame={<TimeFrameDropDown setChartTimeFrame={setChartTimeFrame} />} 
                      ChartType={<ChartTypeDropDown setChartType={setChartType} />} 
                      toggleSearch={toggleSearch}
                  />

                 

                 
                 <div className="site-layout-background" style={{marginLeft:'5vw',marginRight:'2.5vw' }}>
                   
                   <Chart chartType={chartType} symbol={chartTitle} stockData={stockData}></Chart>
                 </div>
                 <div className="site-layout-background" style={{ padding: 24}}> */}

                {/* <Button style={{backgroundColor:'Black', color:'#F68C1D', borderColor:'#F68C1D'}}type="primary" block  onClick={toggleModal}>Trade</Button> 
                </div> */}
                
                 
          <OrderModal 
                     TournamentID={TournamentID}
                     visible={showModal}
                     submitOrder={submitOrder}
                     userInfo={userInfo}
                     balance={balance}
                     onCancel={toggleModal}
                     marketData={stockData}
                     quote={quote}
                     symbol={symbol}
          >
          </OrderModal>
          
          <SubmitOrderModal
            submitVisible = {confirmOrderModal}
            TxData={TxData}
            onConfirmOrderCancel = {toggleConfirmOrderModal}
            latestPrice={quote.latestPrice}
          />

                <Content style={{height:'20vh'}}></Content>
                <StickyTradeButton toggleModal={toggleModal} ></StickyTradeButton>
               <CustomFooter TournamentID={TournamentID} defaultKey='2' ></CustomFooter>
             </Content>
           )
       }
       else{
         <ErrorLoading></ErrorLoading>
       }
}
function formatStockData(stockData)
{
    return Object.entries(stockData).map(entries =>{
        const [date, priceData] = entries;
        return{
            date,
            open: Number(priceData['1. open']),
            high: Number(priceData['2. high']),
            low: Number(priceData['3. low']),
            close: Number(priceData['4. close']),
            volume: Number(priceData['5. volume']),
        }
    });
}
export default NewTrade
let stocks = [
    {
        "symbol": "HOFV",
        "companyName": "Hall of Fame Resort & Entertainment Company",
        "quote": {
            "symbol": "HOFV",
            "companyName": "Hall of Fame Resort & Entertainment Company",
            "primaryExchange": "NASDAQ CAPITAL MARKET",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 6.125,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:32 PM",
            "latestUpdate": 1616602772929,
            "latestVolume": 167779603,
            "iexRealtimePrice": 6.125,
            "iexRealtimeSize": 200,
            "iexLastUpdated": 1616602772929,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 4.03,
            "previousVolume": 107271681,
            "change": 2.095,
            "changePercent": 0.51985,
            "volume": 167779603,
            "iexMarketPercent": 0.008030749721108829,
            "iexVolume": 1347396,
            "avgTotalVolume": 31074952,
            "iexBidPrice": 6.12,
            "iexBidSize": 927,
            "iexAskPrice": 6.13,
            "iexAskSize": 100,
            "iexOpen": 6.125,
            "iexOpenTime": 1616602772929,
            "iexClose": 6.125,
            "iexCloseTime": 1616602772929,
            "marketCap": 510118157,
            "peRatio": null,
            "week52High": 12.31,
            "week52Low": 1.09,
            "ytdChange": 2.7962727642276426,
            "lastTradeTime": 1616602772929,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/HOFV.png"
        }
    },
    {
        "symbol": "APTO",
        "companyName": "Aptose Biosciences Inc",
        "quote": {
            "symbol": "APTO",
            "companyName": "Aptose Biosciences Inc",
            "primaryExchange": "NASDAQ CAPITAL MARKET",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 6.6,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:33 PM",
            "latestUpdate": 1616602773896,
            "latestVolume": 138878354,
            "iexRealtimePrice": 6.6,
            "iexRealtimeSize": 190,
            "iexLastUpdated": 1616602773896,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 3.67,
            "previousVolume": 6067900,
            "change": 2.93,
            "changePercent": 0.79837,
            "volume": 138878354,
            "iexMarketPercent": 0.013216084055834935,
            "iexVolume": 1835428,
            "avgTotalVolume": 1157164,
            "iexBidPrice": 6.59,
            "iexBidSize": 500,
            "iexAskPrice": 6.99,
            "iexAskSize": 100,
            "iexOpen": 6.6,
            "iexOpenTime": 1616602773896,
            "iexClose": 6.6,
            "iexCloseTime": 1616602773896,
            "marketCap": 586619464,
            "peRatio": null,
            "week52High": 9,
            "week52Low": 3.39,
            "ytdChange": 0.6362695433789954,
            "lastTradeTime": 1616602773896,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/APTO.png"
        }
    },
    {
        "symbol": "CIDM",
        "companyName": "Cinedigm Corp - Class A",
        "quote": {
            "symbol": "CIDM",
            "companyName": "Cinedigm Corp - Class A",
            "primaryExchange": "NASDAQ/NMS (GLOBAL MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 1.89,
            "latestSource": "IEX real time price",
            "latestTime": "12:18:59 PM",
            "latestUpdate": 1616602739409,
            "latestVolume": 107905308,
            "iexRealtimePrice": 1.89,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602739409,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 1.51,
            "previousVolume": 62494889,
            "change": 0.38,
            "changePercent": 0.25166,
            "volume": 107905308,
            "iexMarketPercent": 0.0033761082448325896,
            "iexVolume": 364300,
            "avgTotalVolume": 15033281,
            "iexBidPrice": 1.88,
            "iexBidSize": 200,
            "iexAskPrice": 1.9,
            "iexAskSize": 1045,
            "iexOpen": 1.89,
            "iexOpenTime": 1616602739409,
            "iexClose": 1.89,
            "iexCloseTime": 1616602739409,
            "marketCap": 313652610,
            "peRatio": null,
            "week52High": 6,
            "week52Low": 0.28,
            "ytdChange": 1.5934714143920596,
            "lastTradeTime": 1616602739409,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/CIDM.png"
        }
    },
    {
        "symbol": "SNDL",
        "companyName": "Sundial Growers Inc",
        "quote": {
            "symbol": "SNDL",
            "companyName": "Sundial Growers Inc",
            "primaryExchange": "NASDAQ CAPITAL MARKET",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 1.19,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:28 PM",
            "latestUpdate": 1616602768852,
            "latestVolume": 81321957,
            "iexRealtimePrice": 1.19,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602768852,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 1.21,
            "previousVolume": 265495802,
            "change": -0.02,
            "changePercent": -0.01653,
            "volume": 81321957,
            "iexMarketPercent": 0.010541347400186151,
            "iexVolume": 857243,
            "avgTotalVolume": 314404519,
            "iexBidPrice": 1.19,
            "iexBidSize": 30188,
            "iexAskPrice": 1.2,
            "iexAskSize": 30400,
            "iexOpen": 1.19,
            "iexOpenTime": 1616602768845,
            "iexClose": 1.19,
            "iexCloseTime": 1616602768852,
            "marketCap": 1975669002,
            "peRatio": null,
            "week52High": 3.96,
            "week52Low": 0.1381,
            "ytdChange": 1.538908225976769,
            "lastTradeTime": 1616602768852,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/SNDL.png"
        }
    },
    {
        "symbol": "GNUS",
        "companyName": "Genius Brands International Inc",
        "quote": {
            "symbol": "GNUS",
            "companyName": "Genius Brands International Inc",
            "primaryExchange": "NASDAQ CAPITAL MARKET",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 2.9,
            "latestSource": "IEX real time price",
            "latestTime": "12:18:52 PM",
            "latestUpdate": 1616602732210,
            "latestVolume": 79198784,
            "iexRealtimePrice": 2.9,
            "iexRealtimeSize": 800,
            "iexLastUpdated": 1616602732210,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 2.63,
            "previousVolume": 134174636,
            "change": 0.27,
            "changePercent": 0.10266,
            "volume": 79198784,
            "iexMarketPercent": 0.004960947380202201,
            "iexVolume": 392901,
            "avgTotalVolume": 36331918,
            "iexBidPrice": 2.87,
            "iexBidSize": 300,
            "iexAskPrice": 2.88,
            "iexAskSize": 100,
            "iexOpen": 2.9,
            "iexOpenTime": 1616602732210,
            "iexClose": 2.9,
            "iexCloseTime": 1616602732210,
            "marketCap": 864791641,
            "peRatio": null,
            "week52High": 11.73,
            "week52Low": 0.2,
            "ytdChange": 1.0084571014492754,
            "lastTradeTime": 1616602732210,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/GNUS.png"
        }
    },
    {
        "symbol": "VIAC",
        "companyName": "ViacomCBS Inc - Class B",
        "quote": {
            "symbol": "VIAC",
            "companyName": "ViacomCBS Inc - Class B",
            "primaryExchange": "NASDAQ/NGS (GLOBAL SELECT MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 75.68,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:34 PM",
            "latestUpdate": 1616602774805,
            "latestVolume": 55918597,
            "iexRealtimePrice": 75.68,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602774805,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 91.25,
            "previousVolume": 37782349,
            "change": -15.57,
            "changePercent": -0.17063,
            "volume": 55918597,
            "iexMarketPercent": 0.03850826228705273,
            "iexVolume": 2153328,
            "avgTotalVolume": 24063400,
            "iexBidPrice": 75.61,
            "iexBidSize": 100,
            "iexAskPrice": 75.78,
            "iexAskSize": 100,
            "iexOpen": 75.68,
            "iexOpenTime": 1616602774805,
            "iexClose": 75.68,
            "iexCloseTime": 1616602774805,
            "marketCap": 46695011410,
            "peRatio": 19.31,
            "week52High": 101.97,
            "week52Low": 11.59,
            "ytdChange": 1.2852053338859526,
            "lastTradeTime": 1616602774805,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/VIAC.png"
        }
    },
    {
        "symbol": "INTC",
        "companyName": "Intel Corp.",
        "quote": {
            "symbol": "INTC",
            "companyName": "Intel Corp.",
            "primaryExchange": "NASDAQ/NGS (GLOBAL SELECT MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 63.4,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:31 PM",
            "latestUpdate": 1616602771614,
            "latestVolume": 46597139,
            "iexRealtimePrice": 63.4,
            "iexRealtimeSize": 48,
            "iexLastUpdated": 1616602771614,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 63.48,
            "previousVolume": 35159952,
            "change": -0.08,
            "changePercent": -0.00126,
            "volume": 46597139,
            "iexMarketPercent": 0.02858499531484111,
            "iexVolume": 1331979,
            "avgTotalVolume": 32043256,
            "iexBidPrice": 63.35,
            "iexBidSize": 100,
            "iexAskPrice": 63.39,
            "iexAskSize": 600,
            "iexOpen": 63.4,
            "iexOpenTime": 1616602771614,
            "iexClose": 63.4,
            "iexCloseTime": 1616602771614,
            "marketCap": 257594200000,
            "peRatio": 12.83,
            "week52High": 66.38,
            "week52Low": 43.03,
            "ytdChange": 0.28064888915365344,
            "lastTradeTime": 1616602771614,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/INTC.png"
        }
    },
    {
        "symbol": "AMC",
        "companyName": "AMC Entertainment Holdings Inc - Class A",
        "quote": {
            "symbol": "AMC",
            "companyName": "AMC Entertainment Holdings Inc - Class A",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 10.83,
            "openTime": 1616592601281,
            "openSource": "official",
            "close": 10.66,
            "closeTime": 1616529622330,
            "closeSource": "official",
            "high": 11.21,
            "highTime": 1616601874898,
            "highSource": "15 minute delayed price",
            "low": 9.93,
            "lowTime": 1616595533894,
            "lowSource": "15 minute delayed price",
            "latestPrice": 10.175,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:20 PM",
            "latestUpdate": 1616602760283,
            "latestVolume": 40998746,
            "iexRealtimePrice": 10.175,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602760283,
            "delayedPrice": 10.15,
            "delayedPriceTime": 1616601874889,
            "oddLotDelayedPrice": 10.15,
            "oddLotDelayedPriceTime": 1616601874628,
            "extendedPrice": 10.82,
            "extendedChange": 0.645,
            "extendedChangePercent": 0.06339,
            "extendedPriceTime": 1616592599800,
            "previousClose": 10.66,
            "previousVolume": 87923236,
            "change": -0.485,
            "changePercent": -0.0455,
            "volume": 40998746,
            "iexMarketPercent": 0.0075852563880856256,
            "iexVolume": 310986,
            "avgTotalVolume": 156965932,
            "iexBidPrice": 10.17,
            "iexBidSize": 100,
            "iexAskPrice": 10.2,
            "iexAskSize": 100,
            "iexOpen": 10.175,
            "iexOpenTime": 1616602760283,
            "iexClose": 10.175,
            "iexCloseTime": 1616602760283,
            "marketCap": 4580339193,
            "peRatio": -0.26,
            "week52High": 20.36,
            "week52Low": 1.91,
            "ytdChange": 3.9828018867924526,
            "lastTradeTime": 1616602760283,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/AMC.png"
        }
    },
    {
        "symbol": "AAPL",
        "companyName": "Apple Inc",
        "quote": {
            "symbol": "AAPL",
            "companyName": "Apple Inc",
            "primaryExchange": "NASDAQ/NGS (GLOBAL SELECT MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 121.555,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:31 PM",
            "latestUpdate": 1616602771227,
            "latestVolume": 36768341,
            "iexRealtimePrice": 121.555,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602771227,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 122.54,
            "previousVolume": 95467142,
            "change": -0.985,
            "changePercent": -0.00804,
            "volume": 36768341,
            "iexMarketPercent": 0.024679302229056243,
            "iexVolume": 907417,
            "avgTotalVolume": 126983653,
            "iexBidPrice": 121.55,
            "iexBidSize": 100,
            "iexAskPrice": 121.56,
            "iexAskSize": 400,
            "iexOpen": 121.555,
            "iexOpenTime": 1616602771227,
            "iexClose": 121.555,
            "iexCloseTime": 1616602771227,
            "marketCap": 2040677009280,
            "peRatio": 33.03,
            "week52High": 144.87,
            "week52Low": 58.77,
            "ytdChange": -0.08315396914530677,
            "lastTradeTime": 1616602771227,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/AAPL.png"
        }
    },
    {
        "symbol": "DLPN",
        "companyName": "Dolphin Entertainment Inc.",
        "quote": {
            "symbol": "DLPN",
            "companyName": "Dolphin Entertainment Inc.",
            "primaryExchange": "NASDAQ CAPITAL MARKET",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 25.47,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:32 PM",
            "latestUpdate": 1616602772237,
            "latestVolume": 35230467,
            "iexRealtimePrice": 25.47,
            "iexRealtimeSize": 5,
            "iexLastUpdated": 1616602772237,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 18.33,
            "previousVolume": 174577956,
            "change": 7.14,
            "changePercent": 0.38953,
            "volume": 35230467,
            "iexMarketPercent": 0.014986801054893766,
            "iexVolume": 527992,
            "avgTotalVolume": 8415619,
            "iexBidPrice": 5.1,
            "iexBidSize": 100,
            "iexAskPrice": 33,
            "iexAskSize": 164,
            "iexOpen": 25.47,
            "iexOpenTime": 1616602772237,
            "iexClose": 25.47,
            "iexCloseTime": 1616602772237,
            "marketCap": 835459554,
            "peRatio": null,
            "week52High": 18.33,
            "week52Low": 1.96,
            "ytdChange": 4.7807064705882345,
            "lastTradeTime": 1616602772237,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/DLPN.png"
        }
    },
    {
        "symbol": "PLTR",
        "companyName": "Palantir Technologies Inc - Class A",
        "quote": {
            "symbol": "PLTR",
            "companyName": "Palantir Technologies Inc - Class A",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 23.3,
            "openTime": 1616592601431,
            "openSource": "official",
            "close": 23.26,
            "closeTime": 1616529602974,
            "closeSource": "official",
            "high": 23.485,
            "highTime": 1616601874407,
            "highSource": "15 minute delayed price",
            "low": 22.655,
            "lowTime": 1616595601699,
            "lowSource": "IEX real time price",
            "latestPrice": 23.05,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:31 PM",
            "latestUpdate": 1616602771528,
            "latestVolume": 28257464,
            "iexRealtimePrice": 23.05,
            "iexRealtimeSize": 50,
            "iexLastUpdated": 1616602771528,
            "delayedPrice": 22.98,
            "delayedPriceTime": 1616601874407,
            "oddLotDelayedPrice": 22.979,
            "oddLotDelayedPriceTime": 1616601875015,
            "extendedPrice": 23.32,
            "extendedChange": 0.27,
            "extendedChangePercent": 0.01171,
            "extendedPriceTime": 1616592599310,
            "previousClose": 23.26,
            "previousVolume": 50169702,
            "change": -0.21,
            "changePercent": -0.00903,
            "volume": 28257464,
            "iexMarketPercent": 0.012162414857893829,
            "iexVolume": 343679,
            "avgTotalVolume": 92844687,
            "iexBidPrice": 23.04,
            "iexBidSize": 300,
            "iexAskPrice": 23.05,
            "iexAskSize": 100,
            "iexOpen": 23.05,
            "iexOpenTime": 1616602771528,
            "iexClose": 23.05,
            "iexCloseTime": 1616602771528,
            "marketCap": 40383754619,
            "peRatio": -19.37,
            "week52High": 45,
            "week52Low": 8.9,
            "ytdChange": -0.021344225053078553,
            "lastTradeTime": 1616602771528,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/PLTR.png"
        }
    },
    {
        "symbol": "F",
        "companyName": "Ford Motor Co.",
        "quote": {
            "symbol": "F",
            "companyName": "Ford Motor Co.",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 12.38,
            "openTime": 1616592627185,
            "openSource": "official",
            "close": 12.21,
            "closeTime": 1616529735656,
            "closeSource": "official",
            "high": 12.53,
            "highTime": 1616601870367,
            "highSource": "15 minute delayed price",
            "low": 12.255,
            "lowTime": 1616594153089,
            "lowSource": "15 minute delayed price",
            "latestPrice": 12.39,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:26 PM",
            "latestUpdate": 1616602766015,
            "latestVolume": 27355156,
            "iexRealtimePrice": 12.39,
            "iexRealtimeSize": 9,
            "iexLastUpdated": 1616602766015,
            "delayedPrice": 12.37,
            "delayedPriceTime": 1616601870367,
            "oddLotDelayedPrice": 12.367,
            "oddLotDelayedPriceTime": 1616601873982,
            "extendedPrice": 12.36,
            "extendedChange": -0.03,
            "extendedChangePercent": -0.00242,
            "extendedPriceTime": 1616592597152,
            "previousClose": 12.21,
            "previousVolume": 73020161,
            "change": 0.18,
            "changePercent": 0.01474,
            "volume": 27355156,
            "iexMarketPercent": 0.014344498711687113,
            "iexVolume": 392396,
            "avgTotalVolume": 80201121,
            "iexBidPrice": 12.39,
            "iexBidSize": 1900,
            "iexAskPrice": 12.4,
            "iexAskSize": 2400,
            "iexOpen": 12.39,
            "iexOpenTime": 1616602766015,
            "iexClose": 12.39,
            "iexCloseTime": 1616602766015,
            "marketCap": 48418174039,
            "peRatio": -38.49,
            "week52High": 13.62,
            "week52Low": 4.17,
            "ytdChange": 0.40381849829351557,
            "lastTradeTime": 1616602766015,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/F.png"
        }
    },
    {
        "symbol": "CCL",
        "companyName": "Carnival Corp. (Paired Stock)",
        "quote": {
            "symbol": "CCL",
            "companyName": "Carnival Corp. (Paired Stock)",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 26.25,
            "openTime": 1616592601358,
            "openSource": "official",
            "close": 25.33,
            "closeTime": 1616529602350,
            "closeSource": "official",
            "high": 27.37,
            "highTime": 1616601875032,
            "highSource": "15 minute delayed price",
            "low": 26.17,
            "lowTime": 1616592602861,
            "lowSource": "15 minute delayed price",
            "latestPrice": 26.74,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:09 PM",
            "latestUpdate": 1616602749736,
            "latestVolume": 26561998,
            "iexRealtimePrice": 26.74,
            "iexRealtimeSize": 200,
            "iexLastUpdated": 1616602749736,
            "delayedPrice": 26.49,
            "delayedPriceTime": 1616601875032,
            "oddLotDelayedPrice": 26.49,
            "oddLotDelayedPriceTime": 1616601874585,
            "extendedPrice": 26.23,
            "extendedChange": -0.51,
            "extendedChangePercent": -0.01907,
            "extendedPriceTime": 1616592599907,
            "previousClose": 25.33,
            "previousVolume": 51242192,
            "change": 1.41,
            "changePercent": 0.05567,
            "volume": 26561998,
            "iexMarketPercent": 0.014349297067185985,
            "iexVolume": 381146,
            "avgTotalVolume": 56347414,
            "iexBidPrice": 26.73,
            "iexBidSize": 600,
            "iexAskPrice": 26.75,
            "iexAskSize": 100,
            "iexOpen": 26.74,
            "iexOpenTime": 1616602749736,
            "iexClose": 26.74,
            "iexCloseTime": 1616602749736,
            "marketCap": 29520693028,
            "peRatio": -2.02,
            "week52High": 30.12,
            "week52Low": 7.8,
            "ytdChange": 0.22510674976915956,
            "lastTradeTime": 1616602749736,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/CCL.png"
        }
    },
    {
        "symbol": "GE",
        "companyName": "General Electric Co.",
        "quote": {
            "symbol": "GE",
            "companyName": "General Electric Co.",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 12.76,
            "openTime": 1616592601187,
            "openSource": "official",
            "close": 12.66,
            "closeTime": 1616529602381,
            "closeSource": "official",
            "high": 13.02,
            "highTime": 1616601874452,
            "highSource": "15 minute delayed price",
            "low": 12.75,
            "lowTime": 1616592602764,
            "lowSource": "15 minute delayed price",
            "latestPrice": 12.855,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:24 PM",
            "latestUpdate": 1616602764900,
            "latestVolume": 26043713,
            "iexRealtimePrice": 12.855,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602764900,
            "delayedPrice": 12.825,
            "delayedPriceTime": 1616601874038,
            "oddLotDelayedPrice": 12.825,
            "oddLotDelayedPriceTime": 1616601874452,
            "extendedPrice": 12.72,
            "extendedChange": -0.135,
            "extendedChangePercent": -0.0105,
            "extendedPriceTime": 1616592597554,
            "previousClose": 12.66,
            "previousVolume": 65655147,
            "change": 0.195,
            "changePercent": 0.0154,
            "volume": 26043713,
            "iexMarketPercent": 0.015867591537351067,
            "iexVolume": 413251,
            "avgTotalVolume": 111382001,
            "iexBidPrice": 12.85,
            "iexBidSize": 3797,
            "iexAskPrice": 12.86,
            "iexAskSize": 2300,
            "iexOpen": 12.855,
            "iexOpenTime": 1616602764900,
            "iexClose": 12.855,
            "iexCloseTime": 1616602764900,
            "marketCap": 112926692372,
            "peRatio": 21.54,
            "week52High": 14.41,
            "week52Low": 5.45,
            "ytdChange": 0.18849117865085244,
            "lastTradeTime": 1616602764900,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/GE.png"
        }
    },
    {
        "symbol": "BAC",
        "companyName": "Bank Of America Corp.",
        "quote": {
            "symbol": "BAC",
            "companyName": "Bank Of America Corp.",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 37.23,
            "openTime": 1616592601006,
            "openSource": "official",
            "close": 36.9,
            "closeTime": 1616529602033,
            "closeSource": "official",
            "high": 37.79,
            "highTime": 1616601872022,
            "highSource": "15 minute delayed price",
            "low": 37.18,
            "lowTime": 1616592601312,
            "lowSource": "15 minute delayed price",
            "latestPrice": 37.465,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:32 PM",
            "latestUpdate": 1616602772859,
            "latestVolume": 22428596,
            "iexRealtimePrice": 37.465,
            "iexRealtimeSize": 200,
            "iexLastUpdated": 1616602772859,
            "delayedPrice": 37.385,
            "delayedPriceTime": 1616601872022,
            "oddLotDelayedPrice": 37.38,
            "oddLotDelayedPriceTime": 1616601874348,
            "extendedPrice": 37.22,
            "extendedChange": -0.245,
            "extendedChangePercent": -0.00654,
            "extendedPriceTime": 1616592594797,
            "previousClose": 36.9,
            "previousVolume": 49838043,
            "change": 0.565,
            "changePercent": 0.01531,
            "volume": 22428596,
            "iexMarketPercent": 0.02051064631954671,
            "iexVolume": 460025,
            "avgTotalVolume": 63822395,
            "iexBidPrice": 37.45,
            "iexBidSize": 1200,
            "iexAskPrice": 37.47,
            "iexAskSize": 100,
            "iexOpen": 37.465,
            "iexOpenTime": 1616602772859,
            "iexClose": 37.465,
            "iexCloseTime": 1616602772859,
            "marketCap": 323220016861,
            "peRatio": 20.03,
            "week52High": 39.97,
            "week52Low": 19.03,
            "ytdChange": 0.2387766878866851,
            "lastTradeTime": 1616602772859,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/BAC.png"
        }
    },
    {
        "symbol": "AAL",
        "companyName": "American Airlines Group Inc",
        "quote": {
            "symbol": "AAL",
            "companyName": "American Airlines Group Inc",
            "primaryExchange": "NASDAQ/NGS (GLOBAL SELECT MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 22.885,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:34 PM",
            "latestUpdate": 1616602774687,
            "latestVolume": 21861058,
            "iexRealtimePrice": 22.885,
            "iexRealtimeSize": 10,
            "iexLastUpdated": 1616602774687,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 22.27,
            "previousVolume": 55290735,
            "change": 0.615,
            "changePercent": 0.02762,
            "volume": 21861058,
            "iexMarketPercent": 0.01853698023215528,
            "iexVolume": 405238,
            "avgTotalVolume": 52735655,
            "iexBidPrice": 22.88,
            "iexBidSize": 917,
            "iexAskPrice": 22.89,
            "iexAskSize": 132,
            "iexOpen": 22.885,
            "iexOpenTime": 1616602774687,
            "iexClose": 22.885,
            "iexCloseTime": 1616602774687,
            "marketCap": 14638980683,
            "peRatio": -1.25,
            "week52High": 26.09,
            "week52Low": 8.25,
            "ytdChange": 0.43979501585288516,
            "lastTradeTime": 1616602771941,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/AAL.png"
        }
    },
    {
        "symbol": "AMD",
        "companyName": "Advanced Micro Devices Inc.",
        "quote": {
            "symbol": "AMD",
            "companyName": "Advanced Micro Devices Inc.",
            "primaryExchange": "NASDAQ/NGS (GLOBAL SELECT MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 78.22,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:32 PM",
            "latestUpdate": 1616602772659,
            "latestVolume": 21575956,
            "iexRealtimePrice": 78.22,
            "iexRealtimeSize": 3,
            "iexLastUpdated": 1616602772659,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 78.38,
            "previousVolume": 40466635,
            "change": -0.16,
            "changePercent": -0.00204,
            "volume": 21575956,
            "iexMarketPercent": 0.024911202080686482,
            "iexVolume": 537483,
            "avgTotalVolume": 45001284,
            "iexBidPrice": 78,
            "iexBidSize": 150,
            "iexAskPrice": 78.22,
            "iexAskSize": 100,
            "iexOpen": 78.22,
            "iexOpenTime": 1616602772659,
            "iexClose": 78.22,
            "iexCloseTime": 1616602772659,
            "marketCap": 94787263591,
            "peRatio": 37.97,
            "week52High": 99.23,
            "week52Low": 41.7,
            "ytdChange": -0.14738947115908846,
            "lastTradeTime": 1616602772659,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/AMD.png"
        }
    },
    {
        "symbol": "ESGC",
        "companyName": "Eros STX Global Corporation - Class A",
        "quote": {
            "symbol": "ESGC",
            "companyName": "Eros STX Global Corporation - Class A",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 2.46,
            "openTime": 1616592667591,
            "openSource": "official",
            "close": 2.24,
            "closeTime": 1616529602359,
            "closeSource": "official",
            "high": 2.63,
            "highTime": 1616601862947,
            "highSource": "15 minute delayed price",
            "low": 2.27,
            "lowTime": 1616600821105,
            "lowSource": "15 minute delayed price",
            "latestPrice": 2.32,
            "latestSource": "IEX real time price",
            "latestTime": "12:16:11 PM",
            "latestUpdate": 1616602571009,
            "latestVolume": 21552332,
            "iexRealtimePrice": 2.32,
            "iexRealtimeSize": 500,
            "iexLastUpdated": 1616602571009,
            "delayedPrice": 2.308,
            "delayedPriceTime": 1616601862947,
            "oddLotDelayedPrice": 2.308,
            "oddLotDelayedPriceTime": 1616601871872,
            "extendedPrice": 2.45,
            "extendedChange": 0.13,
            "extendedChangePercent": 0.05603,
            "extendedPriceTime": 1616592598994,
            "previousClose": 2.24,
            "previousVolume": 15563403,
            "change": 0.08,
            "changePercent": 0.03571,
            "volume": 21552332,
            "iexMarketPercent": 0.010017199066903758,
            "iexVolume": 215894,
            "avgTotalVolume": 10838329,
            "iexBidPrice": 2.31,
            "iexBidSize": 900,
            "iexAskPrice": 2.33,
            "iexAskSize": 2200,
            "iexOpen": 2.32,
            "iexOpenTime": 1616602571009,
            "iexClose": 2.32,
            "iexCloseTime": 1616602571009,
            "marketCap": 429937396,
            "peRatio": null,
            "week52High": 4.36,
            "week52Low": 1.38,
            "ytdChange": 0.26647923076923086,
            "lastTradeTime": 1616602571009,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/ESGC.png"
        }
    },
    {
        "symbol": "FNKO",
        "companyName": "Funko Inc - Class A",
        "quote": {
            "symbol": "FNKO",
            "companyName": "Funko Inc - Class A",
            "primaryExchange": "NASDAQ/NGS (GLOBAL SELECT MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": 24.68,
            "highTime": 1616602771331,
            "highSource": "IEX real time price",
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 24.66,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:33 PM",
            "latestUpdate": 1616602773856,
            "latestVolume": 19764489,
            "iexRealtimePrice": 24.66,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602773856,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 18.12,
            "previousVolume": 31310614,
            "change": 6.54,
            "changePercent": 0.36093,
            "volume": 19764489,
            "iexMarketPercent": 0.015221845604002209,
            "iexVolume": 300852,
            "avgTotalVolume": 2552921,
            "iexBidPrice": 21.55,
            "iexBidSize": 100,
            "iexAskPrice": 0,
            "iexAskSize": 0,
            "iexOpen": 24.66,
            "iexOpenTime": 1616602773856,
            "iexClose": 24.66,
            "iexCloseTime": 1616602773856,
            "marketCap": 893856766,
            "peRatio": 222.76,
            "week52High": 24.68,
            "week52Low": 3.12,
            "ytdChange": 1.106594739884393,
            "lastTradeTime": 1616602773856,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/FNKO.png"
        }
    },
    {
        "symbol": "ZKIN",
        "companyName": "ZK International Group Co Ltd.",
        "quote": {
            "symbol": "ZKIN",
            "companyName": "ZK International Group Co Ltd.",
            "primaryExchange": "NASDAQ CAPITAL MARKET",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 11.59,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:28 PM",
            "latestUpdate": 1616602768315,
            "latestVolume": 17731827,
            "iexRealtimePrice": 11.59,
            "iexRealtimeSize": 1,
            "iexLastUpdated": 1616602768315,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 11.03,
            "previousVolume": 11595201,
            "change": 0.56,
            "changePercent": 0.05077,
            "volume": 17731827,
            "iexMarketPercent": 0.01171283703591288,
            "iexVolume": 207690,
            "avgTotalVolume": 8261781,
            "iexBidPrice": 11.47,
            "iexBidSize": 144,
            "iexAskPrice": 11.64,
            "iexAskSize": 500,
            "iexOpen": 11.59,
            "iexOpenTime": 1616602768315,
            "iexClose": 11.59,
            "iexCloseTime": 1616602768315,
            "marketCap": 231652598,
            "peRatio": null,
            "week52High": 14.6,
            "week52Low": 0.75,
            "ytdChange": 3.3094572586872584,
            "lastTradeTime": 1616602768315,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/ZKIN.png"
        }
    },
    {
        "symbol": "TSLA",
        "companyName": "Tesla Inc",
        "quote": {
            "symbol": "TSLA",
            "companyName": "Tesla Inc",
            "primaryExchange": "NASDAQ/NGS (GLOBAL SELECT MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 657.365,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:34 PM",
            "latestUpdate": 1616602774281,
            "latestVolume": 16724866,
            "iexRealtimePrice": 657.365,
            "iexRealtimeSize": 37,
            "iexLastUpdated": 1616602774281,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 662.16,
            "previousVolume": 30491870,
            "change": -4.795,
            "changePercent": -0.00724,
            "volume": 16724866,
            "iexMarketPercent": 0.028392095936672974,
            "iexVolume": 474854,
            "avgTotalVolume": 43709123,
            "iexBidPrice": 650,
            "iexBidSize": 100,
            "iexAskPrice": 700,
            "iexAskSize": 100,
            "iexOpen": 657.365,
            "iexOpenTime": 1616602774281,
            "iexClose": 657.365,
            "iexCloseTime": 1616602774281,
            "marketCap": 630974098657,
            "peRatio": 1031.81,
            "week52High": 900.4,
            "week52Low": 89.28,
            "ytdChange": -0.06889771536270492,
            "lastTradeTime": 1616602774281,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/TSLA.png"
        }
    },
    {
        "symbol": "BNGO",
        "companyName": "Bionano Genomics Inc",
        "quote": {
            "symbol": "BNGO",
            "companyName": "Bionano Genomics Inc",
            "primaryExchange": "NASDAQ/NMS (GLOBAL MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 8,
            "latestSource": "IEX real time price",
            "latestTime": "12:18:33 PM",
            "latestUpdate": 1616602713275,
            "latestVolume": 16220710,
            "iexRealtimePrice": 8,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602713275,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 8.37,
            "previousVolume": 19293299,
            "change": -0.37,
            "changePercent": -0.04421,
            "volume": 16220710,
            "iexMarketPercent": 0.02526338242900588,
            "iexVolume": 409790,
            "avgTotalVolume": 29413958,
            "iexBidPrice": 7.75,
            "iexBidSize": 200,
            "iexAskPrice": 7.99,
            "iexAskSize": 800,
            "iexOpen": 8,
            "iexOpenTime": 1616602713275,
            "iexClose": 8,
            "iexCloseTime": 1616602713275,
            "marketCap": 1225488000,
            "peRatio": null,
            "week52High": 15.69,
            "week52Low": 0.25,
            "ytdChange": 1.6733224675324672,
            "lastTradeTime": 1616602713275,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/BNGO.png"
        }
    },
    {
        "symbol": "T",
        "companyName": "AT&T, Inc.",
        "quote": {
            "symbol": "T",
            "companyName": "AT&T, Inc.",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 29.98,
            "openTime": 1616592615439,
            "openSource": "official",
            "close": 30,
            "closeTime": 1616529728685,
            "closeSource": "official",
            "high": 30.255,
            "highTime": 1616602716253,
            "highSource": "IEX real time price",
            "low": 29.97,
            "lowTime": 1616592603840,
            "lowSource": "15 minute delayed price",
            "latestPrice": 30.245,
            "latestSource": "IEX real time price",
            "latestTime": "12:18:57 PM",
            "latestUpdate": 1616602737259,
            "latestVolume": 14536068,
            "iexRealtimePrice": 30.245,
            "iexRealtimeSize": 10,
            "iexLastUpdated": 1616602737259,
            "delayedPrice": 30.2,
            "delayedPriceTime": 1616601873978,
            "oddLotDelayedPrice": 30.196,
            "oddLotDelayedPriceTime": 1616601872451,
            "extendedPrice": 29.99,
            "extendedChange": -0.255,
            "extendedChangePercent": -0.00843,
            "extendedPriceTime": 1616592599075,
            "previousClose": 30,
            "previousVolume": 36918964,
            "change": 0.245,
            "changePercent": 0.00817,
            "volume": 14536068,
            "iexMarketPercent": 0.02725386259888162,
            "iexVolume": 396164,
            "avgTotalVolume": 48687739,
            "iexBidPrice": 30.25,
            "iexBidSize": 1079,
            "iexAskPrice": 30.26,
            "iexAskSize": 1000,
            "iexOpen": 30.245,
            "iexOpenTime": 1616602737259,
            "iexClose": 30.245,
            "iexCloseTime": 1616602737259,
            "marketCap": 215700186937,
            "peRatio": -40.46,
            "week52High": 31.52,
            "week52Low": 25.4,
            "ytdChange": 0.06974112526539276,
            "lastTradeTime": 1616602737259,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/T.png"
        }
    },
    {
        "symbol": "MP",
        "companyName": "MP Materials Corporation - Class A",
        "quote": {
            "symbol": "MP",
            "companyName": "MP Materials Corporation - Class A",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 35.45,
            "openTime": 1616592716270,
            "openSource": "official",
            "close": 36.9,
            "closeTime": 1616529602269,
            "closeSource": "official",
            "high": 35.77,
            "highTime": 1616601873765,
            "highSource": "15 minute delayed price",
            "low": 34.06,
            "lowTime": 1616595567484,
            "lowSource": "15 minute delayed price",
            "latestPrice": 35.175,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:19 PM",
            "latestUpdate": 1616602759599,
            "latestVolume": 14047338,
            "iexRealtimePrice": 35.175,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602759599,
            "delayedPrice": 35,
            "delayedPriceTime": 1616601873765,
            "oddLotDelayedPrice": 34.993,
            "oddLotDelayedPriceTime": 1616601873292,
            "extendedPrice": 35.1,
            "extendedChange": -0.075,
            "extendedChangePercent": -0.00213,
            "extendedPriceTime": 1616592599433,
            "previousClose": 36.9,
            "previousVolume": 20944226,
            "change": -1.725,
            "changePercent": -0.04675,
            "volume": 14047338,
            "iexMarketPercent": 0.041412899725200604,
            "iexVolume": 581741,
            "avgTotalVolume": 6990159,
            "iexBidPrice": 35.16,
            "iexBidSize": 100,
            "iexAskPrice": 35.45,
            "iexAskSize": 100,
            "iexOpen": 35.175,
            "iexOpenTime": 1616602759599,
            "iexClose": 35.175,
            "iexCloseTime": 1616602759599,
            "marketCap": 6005721461,
            "peRatio": null,
            "week52High": 51.77,
            "week52Low": 9.78,
            "ytdChange": 0.10028139571028889,
            "lastTradeTime": 1616602759599,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/MP.png"
        }
    },
    {
        "symbol": "XOM",
        "companyName": "Exxon Mobil Corp.",
        "quote": {
            "symbol": "XOM",
            "companyName": "Exxon Mobil Corp.",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 56.36,
            "openTime": 1616592639769,
            "openSource": "official",
            "close": 55.22,
            "closeTime": 1616529849112,
            "closeSource": "official",
            "high": 57.28,
            "highTime": 1616601874308,
            "highSource": "15 minute delayed price",
            "low": 56.08,
            "lowTime": 1616592602004,
            "lowSource": "15 minute delayed price",
            "latestPrice": 57.06,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:30 PM",
            "latestUpdate": 1616602770242,
            "latestVolume": 13480411,
            "iexRealtimePrice": 57.06,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602770242,
            "delayedPrice": 56.839,
            "delayedPriceTime": 1616601874296,
            "oddLotDelayedPrice": 56.84,
            "oddLotDelayedPriceTime": 1616601874584,
            "extendedPrice": 56.05,
            "extendedChange": -1.01,
            "extendedChangePercent": -0.0177,
            "extendedPriceTime": 1616592599160,
            "previousClose": 55.22,
            "previousVolume": 33493304,
            "change": 1.84,
            "changePercent": 0.03332,
            "volume": 13480411,
            "iexMarketPercent": 0.022509328536051313,
            "iexVolume": 303435,
            "avgTotalVolume": 36065953,
            "iexBidPrice": 57.05,
            "iexBidSize": 600,
            "iexAskPrice": 57.06,
            "iexAskSize": 100,
            "iexOpen": 57.06,
            "iexOpenTime": 1616602770242,
            "iexClose": 57.06,
            "iexCloseTime": 1616602770242,
            "marketCap": 241564809085,
            "peRatio": -10.87,
            "week52High": 62.55,
            "week52Low": 29.87,
            "ytdChange": 0.3957104252720709,
            "lastTradeTime": 1616602770242,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/XOM.png"
        }
    },
    {
        "symbol": "MRO",
        "companyName": "Marathon Oil Corporation",
        "quote": {
            "symbol": "MRO",
            "companyName": "Marathon Oil Corporation",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 10.53,
            "openTime": 1616592601080,
            "openSource": "official",
            "close": 10.17,
            "closeTime": 1616529602057,
            "closeSource": "official",
            "high": 10.75,
            "highTime": 1616601874936,
            "highSource": "15 minute delayed price",
            "low": 10.48,
            "lowTime": 1616592601086,
            "lowSource": "15 minute delayed price",
            "latestPrice": 10.695,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:17 PM",
            "latestUpdate": 1616602757834,
            "latestVolume": 13131175,
            "iexRealtimePrice": 10.695,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602757834,
            "delayedPrice": 10.65,
            "delayedPriceTime": 1616601874933,
            "oddLotDelayedPrice": 10.65,
            "oddLotDelayedPriceTime": 1616601874933,
            "extendedPrice": 10.5,
            "extendedChange": -0.195,
            "extendedChangePercent": -0.01823,
            "extendedPriceTime": 1616592599086,
            "previousClose": 10.17,
            "previousVolume": 36120466,
            "change": 0.525,
            "changePercent": 0.05162,
            "volume": 13131175,
            "iexMarketPercent": 0.018126633755166616,
            "iexVolume": 238024,
            "avgTotalVolume": 31296951,
            "iexBidPrice": 10.69,
            "iexBidSize": 681,
            "iexAskPrice": 10.7,
            "iexAskSize": 719,
            "iexOpen": 10.695,
            "iexOpenTime": 1616602757834,
            "iexClose": 10.695,
            "iexCloseTime": 1616602757834,
            "marketCap": 8439167692,
            "peRatio": -5.84,
            "week52High": 13.29,
            "week52Low": 3,
            "ytdChange": 0.5763576311844079,
            "lastTradeTime": 1616602757834,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/MRO.png"
        }
    },
    {
        "symbol": "BA",
        "companyName": "Boeing Co.",
        "quote": {
            "symbol": "BA",
            "companyName": "Boeing Co.",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 244.2,
            "openTime": 1616592601131,
            "openSource": "official",
            "close": 241.25,
            "closeTime": 1616529602179,
            "closeSource": "official",
            "high": 248.8,
            "highTime": 1616601873568,
            "highSource": "15 minute delayed price",
            "low": 242.47,
            "lowTime": 1616601477545,
            "lowSource": "15 minute delayed price",
            "latestPrice": 244.54,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:25 PM",
            "latestUpdate": 1616602765676,
            "latestVolume": 12885468,
            "iexRealtimePrice": 244.54,
            "iexRealtimeSize": 50,
            "iexLastUpdated": 1616602765676,
            "delayedPrice": 243.445,
            "delayedPriceTime": 1616601873568,
            "oddLotDelayedPrice": 243.444,
            "oddLotDelayedPriceTime": 1616601874846,
            "extendedPrice": 243.72,
            "extendedChange": -0.82,
            "extendedChangePercent": -0.00335,
            "extendedPriceTime": 1616592599087,
            "previousClose": 241.25,
            "previousVolume": 21358225,
            "change": 3.29,
            "changePercent": 0.01364,
            "volume": 12885468,
            "iexMarketPercent": 0.020092789800106602,
            "iexVolume": 258905,
            "avgTotalVolume": 22844991,
            "iexBidPrice": 223.02,
            "iexBidSize": 100,
            "iexAskPrice": 244.59,
            "iexAskSize": 200,
            "iexOpen": 244.54,
            "iexOpenTime": 1616602765676,
            "iexClose": 244.54,
            "iexCloseTime": 1616602765676,
            "marketCap": 142566052144,
            "peRatio": -11.71,
            "week52High": 278.57,
            "week52Low": 113.89,
            "ytdChange": 0.14066046155283563,
            "lastTradeTime": 1616602765676,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/BA.png"
        }
    },
    {
        "symbol": "FCX",
        "companyName": "Freeport-McMoRan Inc",
        "quote": {
            "symbol": "FCX",
            "companyName": "Freeport-McMoRan Inc",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 32.79,
            "openTime": 1616592679734,
            "openSource": "official",
            "close": 32.2,
            "closeTime": 1616529746844,
            "closeSource": "official",
            "high": 33.35,
            "highTime": 1616601874295,
            "highSource": "15 minute delayed price",
            "low": 32.31,
            "lowTime": 1616593977805,
            "lowSource": "15 minute delayed price",
            "latestPrice": 33.23,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:32 PM",
            "latestUpdate": 1616602772905,
            "latestVolume": 12608302,
            "iexRealtimePrice": 33.23,
            "iexRealtimeSize": 400,
            "iexLastUpdated": 1616602772905,
            "delayedPrice": 33.075,
            "delayedPriceTime": 1616601874295,
            "oddLotDelayedPrice": 33.07,
            "oddLotDelayedPriceTime": 1616601869897,
            "extendedPrice": 32.78,
            "extendedChange": -0.45,
            "extendedChangePercent": -0.01354,
            "extendedPriceTime": 1616592598120,
            "previousClose": 32.2,
            "previousVolume": 40041525,
            "change": 1.03,
            "changePercent": 0.03199,
            "volume": 12608302,
            "iexMarketPercent": 0.07287095439179676,
            "iexVolume": 918779,
            "avgTotalVolume": 26292887,
            "iexBidPrice": 33.23,
            "iexBidSize": 100,
            "iexAskPrice": 33.24,
            "iexAskSize": 600,
            "iexOpen": 33.23,
            "iexOpenTime": 1616602772905,
            "iexClose": 33.23,
            "iexCloseTime": 1616602772905,
            "marketCap": 48465305221,
            "peRatio": 81.47,
            "week52High": 39.1,
            "week52Low": 5.99,
            "ytdChange": 0.26949960799385103,
            "lastTradeTime": 1616602772905,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/FCX.png"
        }
    },
    {
        "symbol": "NCLH",
        "companyName": "Norwegian Cruise Line Holdings Ltd",
        "quote": {
            "symbol": "NCLH",
            "companyName": "Norwegian Cruise Line Holdings Ltd",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 27.31,
            "openTime": 1616592601231,
            "openSource": "official",
            "close": 26.6,
            "closeTime": 1616529602568,
            "closeSource": "official",
            "high": 28.44,
            "highTime": 1616601875019,
            "highSource": "15 minute delayed price",
            "low": 27.17,
            "lowTime": 1616592818347,
            "lowSource": "15 minute delayed price",
            "latestPrice": 27.875,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:25 PM",
            "latestUpdate": 1616602765626,
            "latestVolume": 12296207,
            "iexRealtimePrice": 27.875,
            "iexRealtimeSize": 2,
            "iexLastUpdated": 1616602765626,
            "delayedPrice": 27.65,
            "delayedPriceTime": 1616601870578,
            "oddLotDelayedPrice": 27.66,
            "oddLotDelayedPriceTime": 1616601875019,
            "extendedPrice": 27.28,
            "extendedChange": -0.595,
            "extendedChangePercent": -0.02135,
            "extendedPriceTime": 1616592599120,
            "previousClose": 26.6,
            "previousVolume": 23673644,
            "change": 1.275,
            "changePercent": 0.04793,
            "volume": 12296207,
            "iexMarketPercent": 0.01857353247224937,
            "iexVolume": 228384,
            "avgTotalVolume": 28836108,
            "iexBidPrice": 27.87,
            "iexBidSize": 900,
            "iexAskPrice": 27.9,
            "iexAskSize": 100,
            "iexOpen": 27.875,
            "iexOpenTime": 1616602765626,
            "iexClose": 27.875,
            "iexCloseTime": 1616602765626,
            "marketCap": 5990337500,
            "peRatio": -1.77,
            "week52High": 34.48,
            "week52Low": 8.08,
            "ytdChange": 0.09393865119937092,
            "lastTradeTime": 1616602765626,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/NCLH.png"
        }
    },
    {
        "symbol": "MRVL",
        "companyName": "Marvell Technology Group Ltd",
        "quote": {
            "symbol": "MRVL",
            "companyName": "Marvell Technology Group Ltd",
            "primaryExchange": "NASDAQ/NGS (GLOBAL SELECT MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 46.815,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:26 PM",
            "latestUpdate": 1616602766642,
            "latestVolume": 11982580,
            "iexRealtimePrice": 46.815,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602766642,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 46.15,
            "previousVolume": 8492226,
            "change": 0.665,
            "changePercent": 0.01441,
            "volume": 11982580,
            "iexMarketPercent": 0.03607520250229917,
            "iexVolume": 432274,
            "avgTotalVolume": 14317761,
            "iexBidPrice": 46.8,
            "iexBidSize": 300,
            "iexAskPrice": 48,
            "iexAskSize": 100,
            "iexOpen": 46.815,
            "iexOpenTime": 1616602766642,
            "iexClose": 46.815,
            "iexCloseTime": 1616602766642,
            "marketCap": 31632895500,
            "peRatio": -112.92,
            "week52High": 55.7,
            "week52Low": 21.2,
            "ytdChange": -0.014828535969709712,
            "lastTradeTime": 1616602766642,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/MRVL.png"
        }
    },
    {
        "symbol": "CCIV",
        "companyName": "Churchill Capital Corp IV - Class A",
        "quote": {
            "symbol": "CCIV",
            "companyName": "Churchill Capital Corp IV - Class A",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 24.4,
            "openTime": 1616592601129,
            "openSource": "official",
            "close": 24.05,
            "closeTime": 1616529602279,
            "closeSource": "official",
            "high": 24.48,
            "highTime": 1616601873218,
            "highSource": "15 minute delayed price",
            "low": 23.02,
            "lowTime": 1616595530142,
            "lowSource": "15 minute delayed price",
            "latestPrice": 23.33,
            "latestSource": "IEX real time price",
            "latestTime": "12:18:17 PM",
            "latestUpdate": 1616602697378,
            "latestVolume": 11961920,
            "iexRealtimePrice": 23.33,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602697378,
            "delayedPrice": 23.24,
            "delayedPriceTime": 1616601873218,
            "oddLotDelayedPrice": 23.24,
            "oddLotDelayedPriceTime": 1616601875046,
            "extendedPrice": 24.45,
            "extendedChange": 1.12,
            "extendedChangePercent": 0.04801,
            "extendedPriceTime": 1616592598078,
            "previousClose": 24.05,
            "previousVolume": 23012620,
            "change": -0.72,
            "changePercent": -0.02994,
            "volume": 11961920,
            "iexMarketPercent": 0.00908541438163773,
            "iexVolume": 108679,
            "avgTotalVolume": 51136776,
            "iexBidPrice": 22.5,
            "iexBidSize": 100,
            "iexAskPrice": 25.6,
            "iexAskSize": 100,
            "iexOpen": 23.33,
            "iexOpenTime": 1616602697378,
            "iexClose": 23.33,
            "iexCloseTime": 1616602697378,
            "marketCap": 4829310000,
            "peRatio": null,
            "week52High": 64.86,
            "week52Low": 9.6,
            "ytdChange": 1.3726574025974025,
            "lastTradeTime": 1616602697378,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/CCIV.png"
        }
    },
    {
        "symbol": "GME",
        "companyName": "Gamestop Corporation - Class A",
        "quote": {
            "symbol": "GME",
            "companyName": "Gamestop Corporation - Class A",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 157,
            "openTime": 1616592607643,
            "openSource": "official",
            "close": 181.75,
            "closeTime": 1616529603070,
            "closeSource": "official",
            "high": 166.97,
            "highTime": 1616601874547,
            "highSource": "15 minute delayed price",
            "low": 141.27,
            "lowTime": 1616598752554,
            "lowSource": "15 minute delayed price",
            "latestPrice": 150.46,
            "latestSource": "IEX real time price",
            "latestTime": "12:18:41 PM",
            "latestUpdate": 1616602721269,
            "latestVolume": 11918322,
            "iexRealtimePrice": 150.46,
            "iexRealtimeSize": 5,
            "iexLastUpdated": 1616602721269,
            "delayedPrice": 149.28,
            "delayedPriceTime": 1616601874547,
            "oddLotDelayedPrice": 149.18,
            "oddLotDelayedPriceTime": 1616601874969,
            "extendedPrice": 157.65,
            "extendedChange": 7.19,
            "extendedChangePercent": 0.04779,
            "extendedPriceTime": 1616592599853,
            "previousClose": 181.75,
            "previousVolume": 14429145,
            "change": -31.29,
            "changePercent": -0.17216,
            "volume": 11918322,
            "iexMarketPercent": 0.018909457220571822,
            "iexVolume": 225369,
            "avgTotalVolume": 41183988,
            "iexBidPrice": 139,
            "iexBidSize": 200,
            "iexAskPrice": 151.4,
            "iexAskSize": 100,
            "iexOpen": 150.45,
            "iexOpenTime": 1616602721269,
            "iexClose": 150.46,
            "iexCloseTime": 1616602721269,
            "marketCap": 10494127602,
            "peRatio": -35.57,
            "week52High": 483,
            "week52Low": 2.57,
            "ytdChange": 8.474867600849256,
            "lastTradeTime": 1616602721269,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/GME.png"
        }
    },
    {
        "symbol": "GM",
        "companyName": "General Motors Company",
        "quote": {
            "symbol": "GM",
            "companyName": "General Motors Company",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 56.68,
            "openTime": 1616592601127,
            "openSource": "official",
            "close": 56.16,
            "closeTime": 1616529602520,
            "closeSource": "official",
            "high": 57.48,
            "highTime": 1616601874761,
            "highSource": "15 minute delayed price",
            "low": 56.15,
            "lowTime": 1616594150860,
            "lowSource": "15 minute delayed price",
            "latestPrice": 56.925,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:25 PM",
            "latestUpdate": 1616602765245,
            "latestVolume": 11896978,
            "iexRealtimePrice": 56.925,
            "iexRealtimeSize": 10,
            "iexLastUpdated": 1616602765245,
            "delayedPrice": 56.645,
            "delayedPriceTime": 1616601874761,
            "oddLotDelayedPrice": 56.64,
            "oddLotDelayedPriceTime": 1616601873859,
            "extendedPrice": 56.76,
            "extendedChange": -0.165,
            "extendedChangePercent": -0.0029,
            "extendedPriceTime": 1616592598131,
            "previousClose": 56.16,
            "previousVolume": 21280753,
            "change": 0.765,
            "changePercent": 0.01362,
            "volume": 11896978,
            "iexMarketPercent": 0.019909257628281735,
            "iexVolume": 236860,
            "avgTotalVolume": 23071585,
            "iexBidPrice": 56.92,
            "iexBidSize": 500,
            "iexAskPrice": 60.05,
            "iexAskSize": 100,
            "iexOpen": 56.925,
            "iexOpenTime": 1616602765245,
            "iexClose": 56.925,
            "iexCloseTime": 1616602765245,
            "marketCap": 82023962279,
            "peRatio": 13.15,
            "week52High": 62.24,
            "week52Low": 17.79,
            "ytdChange": 0.36232317002881836,
            "lastTradeTime": 1616602765245,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/GM.png"
        }
    },
    {
        "symbol": "MU",
        "companyName": "Micron Technology Inc.",
        "quote": {
            "symbol": "MU",
            "companyName": "Micron Technology Inc.",
            "primaryExchange": "NASDAQ/NGS (GLOBAL SELECT MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 85.36,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:15 PM",
            "latestUpdate": 1616602755328,
            "latestVolume": 11530820,
            "iexRealtimePrice": 85.36,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602755328,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 85.4,
            "previousVolume": 32043764,
            "change": -0.04,
            "changePercent": -0.00047,
            "volume": 11530820,
            "iexMarketPercent": 0.03667206668736482,
            "iexVolume": 422859,
            "avgTotalVolume": 23855558,
            "iexBidPrice": 85.31,
            "iexBidSize": 200,
            "iexAskPrice": 85.36,
            "iexAskSize": 200,
            "iexOpen": 85.36,
            "iexOpenTime": 1616602755328,
            "iexClose": 85.36,
            "iexCloseTime": 1616602755328,
            "marketCap": 95489798557,
            "peRatio": 32.21,
            "week52High": 95.75,
            "week52Low": 39.33,
            "ytdChange": 0.13547040968342647,
            "lastTradeTime": 1616602755328,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/MU.png"
        }
    },
    {
        "symbol": "MSFT",
        "companyName": "Microsoft Corporation",
        "quote": {
            "symbol": "MSFT",
            "companyName": "Microsoft Corporation",
            "primaryExchange": "NASDAQ/NGS (GLOBAL SELECT MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 236.63,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:29 PM",
            "latestUpdate": 1616602769698,
            "latestVolume": 11424481,
            "iexRealtimePrice": 236.63,
            "iexRealtimeSize": 31,
            "iexLastUpdated": 1616602769698,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 237.58,
            "previousVolume": 31638376,
            "change": -0.95,
            "changePercent": -0.004,
            "volume": 11424481,
            "iexMarketPercent": 0.03601178906945532,
            "iexVolume": 411416,
            "avgTotalVolume": 32386031,
            "iexBidPrice": 236.55,
            "iexBidSize": 100,
            "iexAskPrice": 236.58,
            "iexAskSize": 100,
            "iexOpen": 236.63,
            "iexOpenTime": 1616602769698,
            "iexClose": 236.63,
            "iexCloseTime": 1616602769698,
            "marketCap": 1784714516945,
            "peRatio": 35.27,
            "week52High": 245.56,
            "week52Low": 142.99,
            "ytdChange": 0.06661951999221305,
            "lastTradeTime": 1616602769698,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/MSFT.png"
        }
    },
    {
        "symbol": "FCEL",
        "companyName": "Fuelcell Energy Inc",
        "quote": {
            "symbol": "FCEL",
            "companyName": "Fuelcell Energy Inc",
            "primaryExchange": "NASDAQ/NMS (GLOBAL MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": 14.12,
            "highTime": 1616592601628,
            "highSource": "IEX real time price",
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 13.435,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:25 PM",
            "latestUpdate": 1616602765655,
            "latestVolume": 11128690,
            "iexRealtimePrice": 13.435,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602765655,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 13.94,
            "previousVolume": 25483316,
            "change": -0.505,
            "changePercent": -0.03623,
            "volume": 11128690,
            "iexMarketPercent": 0.02095269074796764,
            "iexVolume": 233176,
            "avgTotalVolume": 33759281,
            "iexBidPrice": 13.43,
            "iexBidSize": 100,
            "iexAskPrice": 13.44,
            "iexAskSize": 700,
            "iexOpen": 13.435,
            "iexOpenTime": 1616602765655,
            "iexClose": 13.435,
            "iexCloseTime": 1616602765655,
            "marketCap": 4331835268,
            "peRatio": -34.15,
            "week52High": 29.44,
            "week52Low": 1.26,
            "ytdChange": 0.21175567591763644,
            "lastTradeTime": 1616602765655,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/FCEL.png"
        }
    },
    {
        "symbol": "M",
        "companyName": "Macy`s Inc",
        "quote": {
            "symbol": "M",
            "companyName": "Macy`s Inc",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 16.45,
            "openTime": 1616592601061,
            "openSource": "official",
            "close": 16.09,
            "closeTime": 1616529602329,
            "closeSource": "official",
            "high": 17.25,
            "highTime": 1616601874516,
            "highSource": "15 minute delayed price",
            "low": 16.19,
            "lowTime": 1616601745470,
            "lowSource": "15 minute delayed price",
            "latestPrice": 16.365,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:15 PM",
            "latestUpdate": 1616602755716,
            "latestVolume": 11113701,
            "iexRealtimePrice": 16.365,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602755716,
            "delayedPrice": 16.27,
            "delayedPriceTime": 1616601874516,
            "oddLotDelayedPrice": 16.265,
            "oddLotDelayedPriceTime": 1616601874531,
            "extendedPrice": 16.37,
            "extendedChange": 0.005,
            "extendedChangePercent": 0.00031,
            "extendedPriceTime": 1616592599422,
            "previousClose": 16.09,
            "previousVolume": 24517190,
            "change": 0.275,
            "changePercent": 0.01709,
            "volume": 11113701,
            "iexMarketPercent": 0.015969477674448864,
            "iexVolume": 177480,
            "avgTotalVolume": 21567711,
            "iexBidPrice": 16.36,
            "iexBidSize": 700,
            "iexAskPrice": 16.37,
            "iexAskSize": 200,
            "iexOpen": 16.365,
            "iexOpenTime": 1616602755716,
            "iexClose": 16.365,
            "iexCloseTime": 1616602755716,
            "marketCap": 5080970981,
            "peRatio": -1.35,
            "week52High": 22.3,
            "week52Low": 4.38,
            "ytdChange": 0.4473122222222223,
            "lastTradeTime": 1616602755716,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/M.png"
        }
    },
    {
        "symbol": "SKLZ",
        "companyName": "Skillz Inc - Class A",
        "quote": {
            "symbol": "SKLZ",
            "companyName": "Skillz Inc - Class A",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 23.45,
            "openTime": 1616592601086,
            "openSource": "official",
            "close": 23.24,
            "closeTime": 1616529614317,
            "closeSource": "official",
            "high": 23.63,
            "highTime": 1616601874277,
            "highSource": "15 minute delayed price",
            "low": 21.42,
            "lowTime": 1616596388723,
            "lowSource": "15 minute delayed price",
            "latestPrice": 21.83,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:07 PM",
            "latestUpdate": 1616602747849,
            "latestVolume": 10706779,
            "iexRealtimePrice": 21.83,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602747849,
            "delayedPrice": 21.6,
            "delayedPriceTime": 1616601874277,
            "oddLotDelayedPrice": 21.59,
            "oddLotDelayedPriceTime": 1616601872604,
            "extendedPrice": 23.34,
            "extendedChange": 1.51,
            "extendedChangePercent": 0.06917,
            "extendedPriceTime": 1616592598026,
            "previousClose": 23.24,
            "previousVolume": 13660824,
            "change": -1.41,
            "changePercent": -0.06067,
            "volume": 10706779,
            "iexMarketPercent": 0.030314532503192604,
            "iexVolume": 324571,
            "avgTotalVolume": 14433183,
            "iexBidPrice": 21.81,
            "iexBidSize": 100,
            "iexAskPrice": 21.83,
            "iexAskSize": 100,
            "iexOpen": 21.83,
            "iexOpenTime": 1616602747849,
            "iexClose": 21.83,
            "iexCloseTime": 1616602747849,
            "marketCap": 8072509762,
            "peRatio": null,
            "week52High": 46.3,
            "week52Low": 9.81,
            "ytdChange": 0.10132999999999992,
            "lastTradeTime": 1616602747849,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/SKLZ.png"
        }
    },
    {
        "symbol": "PLUG",
        "companyName": "Plug Power Inc",
        "quote": {
            "symbol": "PLUG",
            "companyName": "Plug Power Inc",
            "primaryExchange": "NASDAQ CAPITAL MARKET",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 36.25,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:27 PM",
            "latestUpdate": 1616602767052,
            "latestVolume": 10631293,
            "iexRealtimePrice": 36.25,
            "iexRealtimeSize": 1,
            "iexLastUpdated": 1616602767052,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 36.79,
            "previousVolume": 23159529,
            "change": -0.54,
            "changePercent": -0.01468,
            "volume": 10631293,
            "iexMarketPercent": 0.01872848392006504,
            "iexVolume": 199108,
            "avgTotalVolume": 38107786,
            "iexBidPrice": 36.23,
            "iexBidSize": 400,
            "iexAskPrice": 36.25,
            "iexAskSize": 400,
            "iexOpen": 36.25,
            "iexOpenTime": 1616602767052,
            "iexClose": 36.25,
            "iexCloseTime": 1616602767052,
            "marketCap": 17010956590,
            "peRatio": -116.78,
            "week52High": 75.49,
            "week52Low": 3.22,
            "ytdChange": 0.0702506989088765,
            "lastTradeTime": 1616602767052,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/PLUG.png"
        }
    },
    {
        "symbol": "WFC",
        "companyName": "Wells Fargo & Co.",
        "quote": {
            "symbol": "WFC",
            "companyName": "Wells Fargo & Co.",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 38.46,
            "openTime": 1616592648846,
            "openSource": "official",
            "close": 38.24,
            "closeTime": 1616529756193,
            "closeSource": "official",
            "high": 39.02,
            "highTime": 1616601873524,
            "highSource": "15 minute delayed price",
            "low": 38.35,
            "lowTime": 1616594143273,
            "lowSource": "15 minute delayed price",
            "latestPrice": 38.675,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:34 PM",
            "latestUpdate": 1616602774196,
            "latestVolume": 10407077,
            "iexRealtimePrice": 38.675,
            "iexRealtimeSize": 94,
            "iexLastUpdated": 1616602774196,
            "delayedPrice": 38.616,
            "delayedPriceTime": 1616601873524,
            "oddLotDelayedPrice": 38.615,
            "oddLotDelayedPriceTime": 1616601873820,
            "extendedPrice": 38.34,
            "extendedChange": -0.335,
            "extendedChangePercent": -0.00866,
            "extendedPriceTime": 1616592599670,
            "previousClose": 38.24,
            "previousVolume": 32494370,
            "change": 0.435,
            "changePercent": 0.01138,
            "volume": 10407077,
            "iexMarketPercent": 0.020849562273825784,
            "iexVolume": 216983,
            "avgTotalVolume": 35966697,
            "iexBidPrice": 38.66,
            "iexBidSize": 200,
            "iexAskPrice": 38.68,
            "iexAskSize": 600,
            "iexOpen": 38.675,
            "iexOpenTime": 1616602774196,
            "iexClose": 38.675,
            "iexCloseTime": 1616602774196,
            "marketCap": 159888034477,
            "peRatio": 93.51,
            "week52High": 41.54,
            "week52Low": 20.6,
            "ytdChange": 0.2824537648247621,
            "lastTradeTime": 1616602774196,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/WFC.png"
        }
    },
    {
        "symbol": "RIG",
        "companyName": "Transocean Ltd",
        "quote": {
            "symbol": "RIG",
            "companyName": "Transocean Ltd",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 3.46,
            "openTime": 1616592600955,
            "openSource": "official",
            "close": 3.28,
            "closeTime": 1616529601927,
            "closeSource": "official",
            "high": 3.58,
            "highTime": 1616601871810,
            "highSource": "15 minute delayed price",
            "low": 3.38,
            "lowTime": 1616601408777,
            "lowSource": "15 minute delayed price",
            "latestPrice": 3.46,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:07 PM",
            "latestUpdate": 1616602747577,
            "latestVolume": 10360330,
            "iexRealtimePrice": 3.46,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602747577,
            "delayedPrice": 3.405,
            "delayedPriceTime": 1616601871790,
            "oddLotDelayedPrice": 3.41,
            "oddLotDelayedPriceTime": 1616601871621,
            "extendedPrice": 3.47,
            "extendedChange": 0.01,
            "extendedChangePercent": 0.00289,
            "extendedPriceTime": 1616592565828,
            "previousClose": 3.28,
            "previousVolume": 28940555,
            "change": 0.18,
            "changePercent": 0.05488,
            "volume": 10360330,
            "iexMarketPercent": 0.012114189412885498,
            "iexVolume": 125507,
            "avgTotalVolume": 32714424,
            "iexBidPrice": 3.46,
            "iexBidSize": 400,
            "iexAskPrice": 3.47,
            "iexAskSize": 2500,
            "iexOpen": 3.46,
            "iexOpenTime": 1616602747577,
            "iexClose": 3.46,
            "iexCloseTime": 1616602747577,
            "marketCap": 2131446998,
            "peRatio": -3.75,
            "week52High": 4.81,
            "week52Low": 0.65,
            "ytdChange": 0.4747934199134198,
            "lastTradeTime": 1616602747577,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/RIG.png"
        }
    },
    {
        "symbol": "PCG",
        "companyName": "PG&E Corp.",
        "quote": {
            "symbol": "PCG",
            "companyName": "PG&E Corp.",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 10.96,
            "openTime": 1616592601136,
            "openSource": "official",
            "close": 10.92,
            "closeTime": 1616529601950,
            "closeSource": "official",
            "high": 11.76,
            "highTime": 1616601874437,
            "highSource": "15 minute delayed price",
            "low": 10.92,
            "lowTime": 1616592612472,
            "lowSource": "15 minute delayed price",
            "latestPrice": 11.455,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:34 PM",
            "latestUpdate": 1616602774352,
            "latestVolume": 10318696,
            "iexRealtimePrice": 11.455,
            "iexRealtimeSize": 200,
            "iexLastUpdated": 1616602774352,
            "delayedPrice": 11.44,
            "delayedPriceTime": 1616601871219,
            "oddLotDelayedPrice": 11.445,
            "oddLotDelayedPriceTime": 1616601870094,
            "extendedPrice": 11.11,
            "extendedChange": -0.345,
            "extendedChangePercent": -0.03012,
            "extendedPriceTime": 1616592524715,
            "previousClose": 10.92,
            "previousVolume": 8775693,
            "change": 0.535,
            "changePercent": 0.04899,
            "volume": 10318696,
            "iexMarketPercent": 0.032705392231731606,
            "iexVolume": 337477,
            "avgTotalVolume": 16097407,
            "iexBidPrice": 11.45,
            "iexBidSize": 300,
            "iexAskPrice": 11.46,
            "iexAskSize": 8600,
            "iexOpen": 11.455,
            "iexOpenTime": 1616602774352,
            "iexClose": 11.455,
            "iexCloseTime": 1616602774352,
            "marketCap": 22734553158,
            "peRatio": -10.91,
            "week52High": 13.34,
            "week52Low": 7.85,
            "ytdChange": -0.0746055056179776,
            "lastTradeTime": 1616602774352,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/PCG.png"
        }
    },
    {
        "symbol": "IDEX",
        "companyName": "Ideanomics Inc",
        "quote": {
            "symbol": "IDEX",
            "companyName": "Ideanomics Inc",
            "primaryExchange": "NASDAQ CAPITAL MARKET",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 2.835,
            "latestSource": "IEX real time price",
            "latestTime": "12:18:50 PM",
            "latestUpdate": 1616602730248,
            "latestVolume": 10315456,
            "iexRealtimePrice": 2.835,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602730248,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 2.82,
            "previousVolume": 24077774,
            "change": 0.015,
            "changePercent": 0.00532,
            "volume": 10315456,
            "iexMarketPercent": 0.007305154517648081,
            "iexVolume": 75356,
            "avgTotalVolume": 28996561,
            "iexBidPrice": 2.83,
            "iexBidSize": 200,
            "iexAskPrice": 2.84,
            "iexAskSize": 250,
            "iexOpen": 2.835,
            "iexOpenTime": 1616602730248,
            "iexClose": 2.835,
            "iexCloseTime": 1616602730248,
            "marketCap": 677483823,
            "peRatio": null,
            "week52High": 5.53,
            "week52Low": 0.37,
            "ytdChange": 0.4224054271356783,
            "lastTradeTime": 1616602730248,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/IDEX.png"
        }
    },
    {
        "symbol": "NLY",
        "companyName": "Annaly Capital Management Inc",
        "quote": {
            "symbol": "NLY",
            "companyName": "Annaly Capital Management Inc",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 8.85,
            "openTime": 1616592600946,
            "openSource": "official",
            "close": 8.81,
            "closeTime": 1616529601929,
            "closeSource": "official",
            "high": 9.019,
            "highTime": 1616601853742,
            "highSource": "15 minute delayed price",
            "low": 8.815,
            "lowTime": 1616600126780,
            "lowSource": "15 minute delayed price",
            "latestPrice": 8.88,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:12 PM",
            "latestUpdate": 1616602752094,
            "latestVolume": 10299450,
            "iexRealtimePrice": 8.88,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602752094,
            "delayedPrice": 8.865,
            "delayedPriceTime": 1616601853742,
            "oddLotDelayedPrice": 8.869,
            "oddLotDelayedPriceTime": 1616601866741,
            "extendedPrice": 8.87,
            "extendedChange": -0.01,
            "extendedChangePercent": -0.00113,
            "extendedPriceTime": 1616592599065,
            "previousClose": 8.81,
            "previousVolume": 15485220,
            "change": 0.07,
            "changePercent": 0.00795,
            "volume": 10299450,
            "iexMarketPercent": 0.020832957099650953,
            "iexVolume": 214568,
            "avgTotalVolume": 15350081,
            "iexBidPrice": 8.87,
            "iexBidSize": 5431,
            "iexAskPrice": 8.88,
            "iexAskSize": 3100,
            "iexOpen": 8.88,
            "iexOpenTime": 1616602752094,
            "iexClose": 8.88,
            "iexCloseTime": 1616602752094,
            "marketCap": 12418705805,
            "peRatio": -12.16,
            "week52High": 9.019,
            "week52Low": 3.5,
            "ytdChange": 0.05055355029585816,
            "lastTradeTime": 1616602752094,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/NLY.png"
        }
    },
    {
        "symbol": "AMAT",
        "companyName": "Applied Materials Inc.",
        "quote": {
            "symbol": "AMAT",
            "companyName": "Applied Materials Inc.",
            "primaryExchange": "NASDAQ/NGS (GLOBAL SELECT MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 124.17,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:30 PM",
            "latestUpdate": 1616602770249,
            "latestVolume": 9973595,
            "iexRealtimePrice": 124.17,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602770249,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 116.38,
            "previousVolume": 8263821,
            "change": 7.79,
            "changePercent": 0.06694,
            "volume": 9973595,
            "iexMarketPercent": 0.04586149728357729,
            "iexVolume": 457404,
            "avgTotalVolume": 9580578,
            "iexBidPrice": 117,
            "iexBidSize": 100,
            "iexAskPrice": 124.2,
            "iexAskSize": 100,
            "iexOpen": 124.17,
            "iexOpenTime": 1616602770249,
            "iexClose": 124.17,
            "iexCloseTime": 1616602770249,
            "marketCap": 113945945509,
            "peRatio": 29.71,
            "week52High": 124.26,
            "week52Low": 41.08,
            "ytdChange": 0.4180513175018721,
            "lastTradeTime": 1616602770249,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/AMAT.png"
        }
    },
    {
        "symbol": "SENS",
        "companyName": "Senseonics Holdings Inc",
        "quote": {
            "symbol": "SENS",
            "companyName": "Senseonics Holdings Inc",
            "primaryExchange": "NYSE MKT LLC",
            "calculationPrice": "tops",
            "open": 3.01,
            "openTime": 1616592600113,
            "openSource": "official",
            "close": 3,
            "closeTime": 1616529600073,
            "closeSource": "official",
            "high": 3.017,
            "highTime": 1616601874216,
            "highSource": "15 minute delayed price",
            "low": 2.845,
            "lowTime": 1616594054255,
            "lowSource": "15 minute delayed price",
            "latestPrice": 2.885,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:28 PM",
            "latestUpdate": 1616602768094,
            "latestVolume": 9802124,
            "iexRealtimePrice": 2.885,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602768094,
            "delayedPrice": 2.88,
            "delayedPriceTime": 1616601871180,
            "oddLotDelayedPrice": 2.885,
            "oddLotDelayedPriceTime": 1616601874613,
            "extendedPrice": 3.02,
            "extendedChange": 0.135,
            "extendedChangePercent": 0.04679,
            "extendedPriceTime": 1616592598052,
            "previousClose": 3,
            "previousVolume": 15104804,
            "change": -0.115,
            "changePercent": -0.03833,
            "volume": 9802124,
            "iexMarketPercent": 0.01281385544602374,
            "iexVolume": 125603,
            "avgTotalVolume": 43044746,
            "iexBidPrice": 2.88,
            "iexBidSize": 1100,
            "iexAskPrice": 2.89,
            "iexAskSize": 1200,
            "iexOpen": 2.885,
            "iexOpenTime": 1616602768094,
            "iexClose": 2.885,
            "iexCloseTime": 1616602768094,
            "marketCap": 1090193225,
            "peRatio": null,
            "week52High": 5.56,
            "week52Low": 0.3497,
            "ytdChange": 2.4028262284927733,
            "lastTradeTime": 1616602768094,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/SENS.png"
        }
    },
    {
        "symbol": "PFE",
        "companyName": "Pfizer Inc.",
        "quote": {
            "symbol": "PFE",
            "companyName": "Pfizer Inc.",
            "primaryExchange": "NEW YORK STOCK EXCHANGE, INC.",
            "calculationPrice": "tops",
            "open": 35.41,
            "openTime": 1616592772263,
            "openSource": "official",
            "close": 35.36,
            "closeTime": 1616529742771,
            "closeSource": "official",
            "high": 35.93,
            "highTime": 1616601871239,
            "highSource": "15 minute delayed price",
            "low": 35.37,
            "lowTime": 1616592707354,
            "lowSource": "15 minute delayed price",
            "latestPrice": 35.885,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:34 PM",
            "latestUpdate": 1616602774106,
            "latestVolume": 9723388,
            "iexRealtimePrice": 35.885,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602774106,
            "delayedPrice": 35.895,
            "delayedPriceTime": 1616601871239,
            "oddLotDelayedPrice": 35.896,
            "oddLotDelayedPriceTime": 1616601871652,
            "extendedPrice": 35.47,
            "extendedChange": -0.415,
            "extendedChangePercent": -0.01156,
            "extendedPriceTime": 1616592598982,
            "previousClose": 35.36,
            "previousVolume": 27970481,
            "change": 0.525,
            "changePercent": 0.01485,
            "volume": 9723388,
            "iexMarketPercent": 0.030175387426687076,
            "iexVolume": 293407,
            "avgTotalVolume": 31776359,
            "iexBidPrice": 35.88,
            "iexBidSize": 100,
            "iexAskPrice": 35.89,
            "iexAskSize": 1000,
            "iexOpen": 35.885,
            "iexOpenTime": 1616602774106,
            "iexClose": 35.885,
            "iexCloseTime": 1616602774106,
            "marketCap": 200153234285,
            "peRatio": 20.99,
            "week52High": 42.62,
            "week52Low": 27.84,
            "ytdChange": -0.01409224458242589,
            "lastTradeTime": 1616602774106,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/PFE.png"
        }
    },
    {
        "symbol": "RIOT",
        "companyName": "Riot Blockchain Inc",
        "quote": {
            "symbol": "RIOT",
            "companyName": "Riot Blockchain Inc",
            "primaryExchange": "NASDAQ CAPITAL MARKET",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 50.29,
            "latestSource": "IEX real time price",
            "latestTime": "12:17:10 PM",
            "latestUpdate": 1616602630362,
            "latestVolume": 9680771,
            "iexRealtimePrice": 50.29,
            "iexRealtimeSize": 5,
            "iexLastUpdated": 1616602630362,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 50.12,
            "previousVolume": 18373722,
            "change": 0.17,
            "changePercent": 0.00339,
            "volume": 9680771,
            "iexMarketPercent": 0.006430892746042645,
            "iexVolume": 62256,
            "avgTotalVolume": 26113993,
            "iexBidPrice": 49.9,
            "iexBidSize": 100,
            "iexAskPrice": 0,
            "iexAskSize": 0,
            "iexOpen": 50.29,
            "iexOpenTime": 1616602630362,
            "iexClose": 50.29,
            "iexCloseTime": 1616602630362,
            "marketCap": 3396079023,
            "peRatio": null,
            "week52High": 79.5,
            "week52Low": 0.735,
            "ytdChange": 1.953360570924073,
            "lastTradeTime": 1616602630362,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/RIOT.png"
        }
    },
    {
        "symbol": "DISCA",
        "companyName": "Discovery Inc - Class A",
        "quote": {
            "symbol": "DISCA",
            "companyName": "Discovery Inc - Class A",
            "primaryExchange": "NASDAQ/NGS (GLOBAL SELECT MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 65.555,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:27 PM",
            "latestUpdate": 1616602767926,
            "latestVolume": 9389362,
            "iexRealtimePrice": 65.555,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602767926,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 71.68,
            "previousVolume": 12233838,
            "change": -6.125,
            "changePercent": -0.08545,
            "volume": 9389362,
            "iexMarketPercent": 0.042239930678996084,
            "iexVolume": 396606,
            "avgTotalVolume": 11169647,
            "iexBidPrice": 65.36,
            "iexBidSize": 100,
            "iexAskPrice": 65.37,
            "iexAskSize": 100,
            "iexOpen": 65.555,
            "iexOpenTime": 1616602767926,
            "iexClose": 65.555,
            "iexCloseTime": 1616602767926,
            "marketCap": 53336375630,
            "peRatio": 47.85,
            "week52High": 78.14,
            "week52Low": 17.69,
            "ytdChange": 1.2967367730142907,
            "lastTradeTime": 1616602767926,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/DISCA.png"
        }
    },
    {
        "symbol": "UAL",
        "companyName": "United Airlines Holdings Inc",
        "quote": {
            "symbol": "UAL",
            "companyName": "United Airlines Holdings Inc",
            "primaryExchange": "NASDAQ/NGS (GLOBAL SELECT MARKET)",
            "calculationPrice": "tops",
            "open": null,
            "openTime": null,
            "openSource": "official",
            "close": null,
            "closeTime": null,
            "closeSource": "official",
            "high": null,
            "highTime": null,
            "highSource": null,
            "low": null,
            "lowTime": null,
            "lowSource": null,
            "latestPrice": 56.07,
            "latestSource": "IEX real time price",
            "latestTime": "12:19:28 PM",
            "latestUpdate": 1616602768433,
            "latestVolume": 9306242,
            "iexRealtimePrice": 56.07,
            "iexRealtimeSize": 100,
            "iexLastUpdated": 1616602768433,
            "delayedPrice": null,
            "delayedPriceTime": null,
            "oddLotDelayedPrice": null,
            "oddLotDelayedPriceTime": null,
            "extendedPrice": null,
            "extendedChange": null,
            "extendedChangePercent": null,
            "extendedPriceTime": null,
            "previousClose": 54.36,
            "previousVolume": 21698204,
            "change": 1.71,
            "changePercent": 0.03146,
            "volume": 9306242,
            "iexMarketPercent": 0.02108283880861899,
            "iexVolume": 196202,
            "avgTotalVolume": 19633873,
            "iexBidPrice": 56.13,
            "iexBidSize": 100,
            "iexAskPrice": 56.15,
            "iexAskSize": 300,
            "iexOpen": 56.07,
            "iexOpenTime": 1616602768433,
            "iexClose": 56.07,
            "iexCloseTime": 1616602768433,
            "marketCap": 17856965020,
            "peRatio": null,
            "week52High": 63.7,
            "week52Low": 18.18,
            "ytdChange": 0.28833861271676303,
            "lastTradeTime": 1616602768433,
            "isUSMarketOpen": true
        },
        "logo": {
            "url": "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/UAL.png"
        }
    }
]

export default stocks
import React,{useState,useEffect} from 'react';
import {CanvasJSChart} from 'canvasjs-react-charts';
import Loading from '../../Components/Loading';
import {Layout, Row,Card} from 'antd'

const {Content}= Layout;

const NewChart = (props) => {

    
    const [price,setPrice] = useState(props.stockData[0].close)
    
    let chartUpdated = (e)=>{
        console.log('e:',e)
    }
    useEffect(()=>
    {
      console.log('PRICE:',price)
    },[price])

    let chartLoading = props.chartLoading;
    console.log('IN CHART CHARLOADING:', chartLoading)
    // let chartTimeFrame
    
    //   const handleSymbolChange = event =>{
    //       const newSymbol = event.target.value;
    //       setSymbol(newSymbol);
    //   }

    let stockData = props.stockData;
    let chartType = props.chartType;
    let chartData;
    //chartType="candle"
    if(chartType == "Line")
    {
        
        chartData ={data:[
            {
                axisYType:'secondary',
                type: 'splineArea',
                fillOpacity:.1,
                color:'#F68C1D',
                dataPoints: stockData.map(stockData => ({
                    x: stockData.index,
                   // x:stockData.date+stockData.minute?stockData.minute:'',
                   //x:stockData.minute,
                    y:stockData.marketAverage?stockData.marketAverage:stockData.close,
                    label:stockData.label,
                    date:stockData.date,
                    // mousemove:function(e){
                    //     console.log(e.dataPoint.y)
                    //     props.setPrice(e.dataPoint.y)
                    // },
                    // click:function(e){
                    //     props.setPrice(e.dataPoint.y)
                    // },
                    // mouseover:function(e){
                    //     props.setPrice(e.dataPoint.y)
                    // }
                    
                   
                    // The OHLC for the data point
                    // The order is IMPORTANT!
                    
                }))
            }]
        
        }
    }
    else{

        chartData ={
            data: [
                {
                    
                    axisYType:'secondary',
                    type: 'candlestick',
                    risingColor:"green",
                    fallingColor:'red',
                    dataPoints: stockData.map(stockData => ({
                        //x: new Date(stockData.date),
                        x:stockData.index,
                        
                        color:stockData.close>=stockData.open? 'green':'red',
                        // The OHLC for the data point
                        // The order is IMPORTANT!
                        y: [
                            stockData.open,
                            stockData.high,
                            stockData.low,
                            stockData.close
                        ],
                        label:stockData.label,
                        date:stockData.date,
                    }))
                }
            ]
        }
       
    }

    let chart = <CanvasJSChart
    //   onRef={ref=>this.NewChart=ref}
     options={ {
        toolTip:{
            animationEnabled:true,
          
          enabled: true,
          //backgroundColor:'#141414',
        backgroundColor:'#141414',

          fontColor:'#F68C1D',
          content:"{date} @ {label} : ${y}",
        //   updated:function(e){
        //     console.log('Crosshair:',e.entries[0].xValue)
        // },
          
         
         },
         
         height:200,
         //width:'95%',
         backgroundColor:'#141414',
        //backgroundColor:'red',
        //  title:{
        //     text:props.symbol,
        //     fontColor:'#F68C1D',
        //     horizontalAlign:"left"
        //  },
         
        // zoomEnabled:true,
         zoomType:'xy',
            axisY2: {
                // Minimum value is 10% less than the lowest price in the dataset
                minimum: Math.min(...stockData.map(data => data.low)) / 1.05,
                // Minimum value is 10% more than the highest price in the dataset
                maximum: Math.max(...stockData.map(data => data.high)) * 1.05,

                // crosshair: {
                //     enabled: true,
                //     snapToDataPoint: false
                // },
                lineThickness:0,
                gridThickness:0,

                labelFontColor:'red',
                labelMaxWidth:0,
                labelFontSize:0,
                labelPlacement:'inside',
                tickLength:0,
                margin:0,
            },
            axisY:{
            // Minimum value is 10% less than the lowest price in the dataset
            minimum: Math.min(...stockData.map(data => data.low)) / 1,
            // Minimum value is 10% more than the highest price in the dataset
            maximum: Math.max(...stockData.map(data => data.high)) * 1,

            
            lineThickness:0,
            gridThickness:0,

            labelFontColor:'red',
            labelMaxWidth:0,
            labelFontSize:0,
            labelPlacement:'inside',
            tickLength:0,
            margin:0,
            },
            axisX: {
                // crosshair: {
                //     enabled: true,
                //     snapToDataPoint: true,
                //     updated:function(e){
                //         console.log('Crosshair:',e.dataPoint.y)
                //     },
                //     mousemove:function(e){
                //         console.log(e.dataPoint.y)
                //         props.setPrice(e.dataPoint.y)
                //     },
                //     click:function(e){
                //         props.setPrice(e.dataPoint.y)
                //     },
                //     mouseover:function(e){
                //         props.setPrice(e.dataPoint.y)
                //     }
                // },
                    
                // },
                labelFontColor:'red',
                labelFontSize:0,
                labelMaxWidth:0,
                tickLength:0,
                lineThickness:0,
                gridThickness:0,

                scaleBreaks: {
                    spacing: 0,
                    fillOpacity: 0,
                    lineThickness: 0,
                    customBreaks: stockData.reduce((breaks, value, index, array) => {
                        // Just return on the first iteration
                        // Since there is no previous data point
                        if (index === 0) return breaks;

                        // Time in UNIX for current and previous data points
                        const currentDataPointUnix = Number(new Date(value.date));
                        const previousDataPointUnix = Number(new Date(array[index - 1].date));

                        // One day converted to milliseconds
                        const oneDayInMs = 86400000;
                        const oneMinInMs = 6000;

                        // Difference between the current and previous data points
                        // In milliseconds
                        const difference = previousDataPointUnix - currentDataPointUnix;

                        return difference === oneDayInMs
                            // Difference is 1 day, no scale break is needed
                            ? breaks
                            // Difference is more than 1 day, need to create
                            // A new scale break
                            : [
                                ...breaks,
                                {
                                    startValue: currentDataPointUnix,
                                    endValue: previousDataPointUnix - oneDayInMs
                                }
                            ]
                    }, [])
                },
               
            },
            data:chartData.data
        } }
    />

    let chartLoadingScreen =<Loading message={'Making the charts pretty...'} customStyle={{height:200, alignItems:'center'}}></Loading>
   
        

    

   
      console.log('stockdata:',stockData);
      return (
        <Content style={{position:'sticky',left:0,display:'flex',justifyContent:'center',marginLeft:'2vw'}} onScroll={()=>{}}>

            {chartLoading?chartLoadingScreen:chart}
          
         </Content>
       
    );
};




export default NewChart;





// class NewChart extends Component{
//     constructor(props){
//         super(props)
//       }
// render()
// {

//     const {Content} = Layout;
//     let stockData = this.props.stockData;
//     let chartType = this.props.chartType;
//     let chartData;
//     //chartType="candle"
//     if(chartType == "Line")
//     {
        
//         chartData ={data:[
//             {
//                 axisYType:'secondary',
//                 type: 'splineArea',
//                 fillOpacity:.1,
//                 color:'#F68C1D',
//                 dataPoints: stockData.map(stockData => ({
//                     x: new Date(stockData.date),
//                     y:stockData.close,
//                     mouseover:function(e){console.log(e.dataPoint.y)}
                    
                   
//                     // The OHLC for the data point
//                     // The order is IMPORTANT!
                    
//                 }))
//             }]
        
//         }
//     }
//     else{

//         chartData ={
//             data: [
//                 {
                    
//                     axisYType:'secondary',
//                     type: 'candlestick',
//                     risingColor:"green",
//                     fallingColor:'red',
//                     dataPoints: stockData.map(stockData => ({
//                         x: new Date(stockData.date),
                        
                        
//                         color:stockData.close>stockData.open? 'green':'red',
//                         // The OHLC for the data point
//                         // The order is IMPORTANT!
//                         y: [
//                             stockData.open,
//                             stockData.high,
//                             stockData.low,
//                             stockData.close
//                         ]
//                     }))
//                 }
//             ]
//         }
       
//     }

   

//     return(
//         <Content style={{display:'flex',justifyContent:'center',marginLeft:'2vw'}} onScroll={()=>{}}>
//         <CanvasJSChart
//     //   onRef={ref=>this.NewChart=ref}
//         onRef ={ref=>this.NewChart=ref}
//         options={ {
        
//         toolTip:{
//             animationEnabled:true,
          
//           enabled: true,
//           backgroundColor:'#141414',
//           fontColor:'#F68C1D',
//           content:"{x}",
          
//           shared:true
         
//          },
         
         
//          height:200,
//          //width:'95%',
//          backgroundColor:'#141414',
//         //backgroundColor:'red',
//         //  title:{
//         //     text:props.symbol,
//         //     fontColor:'#F68C1D',
//         //     horizontalAlign:"left"
//         //  },
         
//         // zoomEnabled:true,
//          zoomType:'xy',
//             axisY2: {
//                 // Minimum value is 10% less than the lowest price in the dataset
//                 minimum: Math.min(...stockData.map(data => data.low)) / 1,
//                 // Minimum value is 10% more than the highest price in the dataset
//                 maximum: Math.max(...stockData.map(data => data.high)) * 1.05,

//                 // crosshair: {
//                 //     enabled: true,
//                 //     snapToDataPoint: false
//                 // },
//                 lineThickness:0,
//                 gridThickness:0,

//                 labelFontColor:'red',
//                 labelMaxWidth:0,
//                 labelFontSize:0,
//                 labelPlacement:'inside',
//                 tickLength:0,
//                 margin:0,
//             },
//             axisY:{
//             // Minimum value is 10% less than the lowest price in the dataset
//             minimum: Math.min(...stockData.map(data => data.low)) / 1,
//             // Minimum value is 10% more than the highest price in the dataset
//             maximum: Math.max(...stockData.map(data => data.high)) * 1,

//             // crosshair: {
//             //     enabled: true,
//             //     snapToDataPoint: false
//             // },
//             lineThickness:0,
//             gridThickness:0,

//             labelFontColor:'red',
//             labelMaxWidth:0,
//             labelFontSize:0,
//             labelPlacement:'inside',
//             tickLength:0,
//             margin:0,
//             },
//             axisX: {
//                 // crosshair: {
//                 //     enabled: true,
//                 //     snapToDataPoint: true,
//                 //     updated: function(e){
//                 //         console.log('value:',e)
//                 //     }
                    
//                 // },
//                 labelFontColor:'red',
//                 labelFontSize:0,
//                 labelMaxWidth:0,
//                 tickLength:0,
//                 lineThickness:0,
//                 gridThickness:0,

//                 scaleBreaks: {
//                     spacing: 0,
//                     fillOpacity: 0,
//                     lineThickness: 0,
//                     customBreaks: stockData.reduce((breaks, value, index, array) => {
//                         // Just return on the first iteration
//                         // Since there is no previous data point
//                         if (index === 0) return breaks;

//                         // Time in UNIX for current and previous data points
//                         const currentDataPointUnix = Number(new Date(value.date));
//                         const previousDataPointUnix = Number(new Date(array[index - 1].date));

//                         // One day converted to milliseconds
//                         const oneDayInMs = 86400000;

//                         // Difference between the current and previous data points
//                         // In milliseconds
//                         const difference = previousDataPointUnix - currentDataPointUnix;

//                         return difference === oneDayInMs
//                             // Difference is 1 day, no scale break is needed
//                             ? breaks
//                             // Difference is more than 1 day, need to create
//                             // A new scale break
//                             : [
//                                 ...breaks,
//                                 {
//                                     startValue: currentDataPointUnix,
//                                     endValue: previousDataPointUnix - oneDayInMs
//                                 }
//                             ]
//                     }, [])
//                 },
               
//             },
//             data:chartData.data
//         } }
//     />
       
      
//      </Content>
//     )

// }


// }

    
    //const [price,setPrice] = useState(props.stockData[0].close)
    
    // let chartUpdated = (e)=>{
    //     console.log('e:',e)
    // }
    // useEffect(()=>
    // {
    //   console.log('PRICE:',price)
    // },[price])
    
    //   const handleSymbolChange = event =>{
    //       const newSymbol = event.target.value;
    //       setSymbol(newSymbol);
    //   }

    

//     console.log('CHART TEST:', chart.title)
//       console.log('stockdata:',stockData);
//       return (
        
       
//     );
// };
import {Auth, API} from 'aws-amplify';

const getPlayerBalances = (UserTournObj) => {
    let apiName = 'MarketKingsAPI'
   // let path = '/standings/api/getPlayerHoldings'
   let path = '/standings/api/getPlayerBalances'
    let myInit = {body:UserTournObj}
    console.log('in getPlayerbalances: ',UserTournObj)
    return API.post(apiName, path, myInit)
    .then(response =>{
     // console.log(response)
      return response
    })
    .catch(err=>console.log(err))
  
};

export default getPlayerBalances;
import React, {useState, useEffect} from 'react';

import {Layout, Row,Col, Button,Space, Divider, Card, Image, Descriptions, Typography,} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';

const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let News =(props) =>{

    const [selected, setSelection] = useState(1);
    let news = props.news;
    //console.log(news[0])

   
  return(
    <Content style={{ marginTop:'2vh',backgroundColor:'',marginLeft:'5vw',marginRight:'5vw',}}>

        <Row style={{color:'#F68C1D', fontSize:24,display:'flex',}}>News</Row>
        <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',marginTop:0}}></Divider>

           {news.map((anArticle,index)=>{
               return(
                <a href={anArticle.url}>
                <Button style={{backgroundColor:'black',width:'90vw', height:'10vh', marginBottom:'3vh',marginTop:'-1vh',borderColor:'#F68C1D',borderWidth:1, borderRadius:10 }}>
                
                
                    <Row style={{display:'flex',marginLeft:'1vw',color:'#F68C1D',position:'absolute',top:0, alignItems:'center',}}>
                        <Space>
                       <Col><h2 style={{color:'#F68C1D'}}>{anArticle.source}</h2></Col> 
                       <Col style={{marginLeft:'1vw'}}><h5 style={{color:'#F68C1D'}}>{new Date(anArticle.datetime).toDateString()}</h5> </Col> 
                       </Space>
                    </Row>
                   
                    <Row style={{display:'flex',marginLeft:'1vw',color:'#F68C1D', marginRight:'1vw',alignItems:'center'}}>
                        <Col style={{ width:'60%', display:'flex',flexDirection:'row'  }}>
                        
                       <Typography.Text ellipsis={true} style={{color:'#F68C1D',}}>{anArticle.headline}</Typography.Text>
                        
                           
                            
                        </Col>
                        <Col style={{color:'#F68C1D', width:'30%',height:'50%',marginLeft:'10%'}}>
                            <Image width={50} height={50} src={anArticle.image} preview={false}></Image>
                        </Col>

                    </Row>
                           
                       
                </Button>
                </a>
               )

           })}

           <Button  style={{borderWidth:0,borderColor:'#F68C1D',backgroundColor:'#141414', color:'#F68C1D'}} >Show More Articles</Button>
           <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',}}></Divider>

           
          </Content>  
           )


        
   



}

export default News;

   
{/* <Button onClick={props.onClick} style={{marginTop:'2vh',marginBottom:'1vh',width:'95vw', height:'10vh',color:'#F68C1D', backgroundColor:'black',  borderColor:color}}>

            
<Row style={{justifyContent:'space-between', alignItems:'center'}}>
<Col>
{PremiumTournament}
</Col>

<Col>
    {Placement}
</Col>

<Col>
${PortfolioValue}
</Col>

<Col>
{PercentChange}
</Col>

<Col>
{EndDate}
</Col>
</Row>
</Button> */}
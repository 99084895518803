import React, {useState, useEffect, } from 'react';

import {Layout, Row,Card, Col, Button, Alert,Divider} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined, CloseCircleFilled,
    CheckCircleFilled} from '@ant-design/icons';

import StockCard from '../ExploreScreenComponents/StockCard'
import ATournamentCard from '../ATournamentCard';
import Loading from '../Loading'
import {Link} from 'react-router-dom'


let error = <CloseCircleFilled style={{color:'#F68C1D'}}/>
let success = <CheckCircleFilled style={{color:'#F68C1D'}}/>
//const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let StockCardSectionContainer =(props) =>{


    let TournamentID = props.TournamentID
    console.log('StockCardSection TOurnament ID: ', TournamentID)
    //let TournamentID = aTourn.TournamentID;
    let stocks = props.stocks
        console.log('stocks:', stocks)
    if(stocks && TournamentID)
    {

   
    return(
        <Content >
            {stocks.map((aStock, key)=>{
                console.log('aStock',aStock)
                return(
                    <Link key={key} to={"/Trade/"+TournamentID+"/"+aStock.Symbol}>
                        <StockCard stock={aStock}></StockCard>

                    </Link>
                )
            })}
            
        </Content>
        
          
    );
      
}
else if(stocks &&(TournamentID ==null))
{
    console.log("STOCKS EXISTS AND TOURNY NULL")
    return(
        <Content >
            {stocks.map((aStock, key)=>{
             
                console.log('aStockz',aStock)
                return(
                    <Link key={key} to={"/ViewAsset/"+aStock.Symbol}>
                        <StockCard stock={aStock}></StockCard>

                    </Link>
                )
            })}
            
        </Content>
        
          
    ); 
}
else
{return(
    <Content >
    <Loading message="Getting that list for you! "></Loading>
    </Content>
)
    
}
}

export default StockCardSectionContainer;
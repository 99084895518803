import {Auth, API} from 'aws-amplify';

const getLatestQuote = (symbol) => {
 
    let apiName = 'StockDataAPI'
    let path = '/stockData/getLatestQuote/'
    let p = path+symbol
    let myInit = {}
    console.log('In get tradescreenData: ', symbol)
    return API.get(apiName, p, myInit)
    .then(response =>{
      console.log(response)
      return response.Data
    })
    .catch(err=>console.log(err))
  
};

export default getLatestQuote;
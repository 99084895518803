import React,{useState, useEffect} from 'react';
import '../App.css'
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import {Auth, API, ServiceWorker} from 'aws-amplify';
import {
    isPushNotificationSupported,
    askUserPermission,
    registerServiceWorker,
    createNotificationSubscription,
    getUserSubscription,
    sendNotification
  } from "../Utils/push-notifications";
  import Loading from '../Components/Loading'
  import ErrorLoading from '../Components/ErrorLoading';
  import MainFooter from '../Components/MainFooter'
  import {Link} from 'react-router-dom'

import { Layout, Menu, Breadcrumb, Col, Divider,Card , Carousel, Image,Button } from 'antd';
import {useLocation} from "react-router-dom";
import TopHomeContainer from '../Components/TopHomeContainer';

import StockTickerScroll from '../Components/HomeScreenComponents/StockTickerScroll';
import NewsCarousel from '../Components/HomeScreenComponents/NewsCarousel';
import SectorPerformance from '../Components/HomeScreenComponents/SectorPerformance';

const { Header, Content, Footer, Sider} = Layout;
const { SubMenu } = Menu;
//const pushNotificationSupported = isPushNotificationSupported();

//const serviceWorker = new ServiceWorker();

let Main = () =>{
    // const [userConsent, setSuserConsent] = useState(Notification.permission);
    // const [error, setError] = useState(null);
     const [loading, setLoading] = useState(false);
     const [error, setError] = useState(false);

   

  if(loading == true){
    return(
    
      <Loading message={'Bringing you home.. :)'}></Loading>
   
    );
  }
  else if(error == true){
      return(
          <ErrorLoading ></ErrorLoading>
      )
  }
else{


    return (
        <Content className='Content' >
          <TopHomeContainer centeredMessage="Market Kings"/>
            <div style={{marginTop:'6vh'}}/>
              {/* <div style={{width:'100vw', display:'flex',alignItems:'center',justifyContent:'center'}}>
                <Card style={{ borderColor:'#F68C1D', borderWidth:'3px'}}>Welcome to MarketKings!</Card>

              </div> 
              <div style={{width:'100vw', justifyContent:'center', alignItems:'center'}}>*/}
                  
                      <StockTickerScroll></StockTickerScroll>
                     
                     <NewsCarousel></NewsCarousel>
                     <Link to={"/BrowseAssets/"}  style={{marginTop:'1vh'}}>
                     <Button shape="round" style={{backgroundColor:'#F68C1D', color:'black',  borderColor:'#F68C1D', width:'95vw'}}type="primary" block  >Browse Assets</Button> 
                     </Link>
                      <Button shape="round" style={{backgroundColor:'#F68C1D', color:'black',  borderColor:'#F68C1D', width:'95vw'}}type="primary" block  >How to Play?</Button> 
                      <SectorPerformance></SectorPerformance>
                   

              
            
          <MainFooter defaultKey='1'></MainFooter>
      </Content>
      
    )
}
}
export default Main
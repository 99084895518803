let industries = [
    { name: 'Other Services (except Public Administration)' },
    { name: 'Commercial Services' },
    { name: 'Retail Trade' },
    { name: 'Distribution Services' },
    { name: 'Process Industries' },
    { name: 'Professional, Scientific, and Technical Services' },
    { name: 'Wholesale Trade' },
    {
      name: 'Administrative and Support and Waste Management and Remediation Services'
    },
    { name: 'Technology Services' },
    { name: 'Educational Services' },
    { name: 'Consumer Durables' },
    { name: 'Miscellaneous' },
    { name: 'Government' },
    { name: 'Real Estate and Rental and Leasing' },
    { name: 'Consumer Non-Durables' },
    { name: 'Communications' },
    { name: 'Health Services' },
    { name: 'Finance' },
    { name: 'Producer Manufacturing' },
    { name: 'Manufacturing' },
    { name: 'Information' },
    { name: 'Health Care and Social Assistance' },
    { name: 'Transportation' },
    { name: 'Agriculture, Forestry, Fishing and Hunting' },
    { name: 'Accommodation and Food Services' },
    { name: 'Energy Minerals' },
    { name: 'Health Technology' },
    { name: 'Construction' },
    { name: 'Public Administration' },
    { name: 'Arts, Entertainment, and Recreation' },
    { name: 'Mining, Quarrying, and Oil and Gas Extraction' },
    { name: 'Consumer Services' },
    { name: 'Finance and Insurance' },
    { name: 'Utilities' },
    { name: 'Management of Companies and Enterprises' },
    { name: 'Non-Energy Minerals' },
    { name: 'Electronic Technology' },
    { name: 'Transportation and Warehousing' },
    { name: 'Industrial Services' }
  ];

  let sectorPerformance=[
    {
      type: 'sector',
      name: 'Energy',
      performance: 0.01514,
      lastUpdated: 1616782255218
    },
    {
      type: 'sector',
      name: 'Health Care',
      performance: 0.01119,
      lastUpdated: 1616782246008
    },
    {
      type: 'sector',
      name: 'Materials',
      performance: 0.01106,
      lastUpdated: 1616782095556
    },
    {
      type: 'sector',
      name: 'Real Estate',
      performance: 0.01027,
      lastUpdated: 1616782007313
    },
    {
      type: 'sector',
      name: 'Technology',
      performance: 0.00858,
      lastUpdated: 1616782198703
    },
    {
      type: 'sector',
      name: 'Consumer Staples',
      performance: 0.00831,
      lastUpdated: 1616782230552
    },
    {
      type: 'sector',
      name: 'Industrials',
      performance: 0.0061,
      lastUpdated: 1616782237393
    },
    {
      type: 'sector',
      name: 'Financials',
      performance: 0.00606,
      lastUpdated: 1616782244405
    },
    {
      type: 'sector',
      name: 'Utilities',
      performance: -0.00103,
      lastUpdated: 1616782254030
    },
    {
      type: 'sector',
      name: 'Consumer Discretionary',
      performance: -0.00644,
      lastUpdated: 1616782242298
    },
    {
      type: 'sector',
      name: 'Communication Services',
      performance: -0.02381,
      lastUpdated: 1616782020270
    }
  ]

  export default sectorPerformance;
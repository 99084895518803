import {Auth, API} from 'aws-amplify';

const getTournamentDetails= async (userInfo) => {
    
        let apiName = 'MarketKingsAPI'
        let path = '/standings/api/getTournamentDetails'
         let myInit = {body:userInfo}
        //let myInit ={}
        return API.post(apiName, path, myInit)
        .then(response =>{
         console.log(response)
          return response
        })
        .catch(err=>console.log(err))
      
};

export default getTournamentDetails;
import React, {useState, useEffect, } from 'react';

import {Layout, Row,Card, Col, Button, Alert,Image} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined, CloseCircleFilled,
    CheckCircleFilled} from '@ant-design/icons';
import premium from './assets/Premium.png'
import standard from './assets/Standard.png'
let error = <CloseCircleFilled style={{color:'#F68C1D'}}/>
let success = <CheckCircleFilled style={{color:'#F68C1D'}}/>
//const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let TournamentTypeCard =(props) =>{
    //let placement = 99
   let type = props.TournamentType
   console.log('Type:',type)
   let imageSrc;
   if(type ==0)
   {
       imageSrc=standard;
   }
   else{
       imageSrc=premium
   }
 

    return(
        //<Card style={{display:'flex', justifyContent:'center', width:'15vw', height:'15vh', alignItems:'center',backgroundColor:'black', borderRadius:'20px', color:'Black',borderColor:'transparent'}}>


        
         <Image style={{borderRadius:'20px',width:'30vw',height:'14vh', position:'relative',}}preview={false} width={'30vw'} height={'14vh'} src={imageSrc}></Image>
        // </Card>
    )
}

export default TournamentTypeCard;

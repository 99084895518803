/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:f462bf85-8ea9-4660-8593-13e3d58c3b0d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_pWJjkq9D7",
    "aws_user_pools_web_client_id": "71jv32dkmbba182kiarcmr16a8",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "MarketKingsAPI",
            "endpoint": "https://d4zdsed9gl.execute-api.us-east-2.amazonaws.com/mkenv",
            "region": "us-east-2"
        },
        {
            "name": "StockDataAPI",
            "endpoint": "https://pge2uyfr5k.execute-api.us-east-2.amazonaws.com/mkenv",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;

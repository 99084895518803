import React, {useState, useEffect} from 'react';

import {Layout, Row,Col, Button,Space, Divider} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';
import getStockData from '../../Utils/getStockData'

const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let KeyStats =(props) =>{

    // const [selected, setSelection] = useState(1);

    // useEffect(() => {
    //     let checkStockData =(symbol)=>{
    //         console.log('HERE HERE HEREH HERE HEREHERE')
    //         getStockData(symbol).then(data =>{
    //             console.log('data', data.data)
    //         })
    //         .catch(err=>console.log(err))
    //     }
    //     checkStockData('aapl')
    // }, [])
    

    let quote = props.quote;
    let open = quote.open?quote.open:quote.iexOpen;
    let high = quote.high?quote.high:'N/A';
    let low = quote.low?quote.low:'N/A';
    let close= quote.close?quote.close:quote.iexClose;
    let week52High = quote.week52High?quote.week52High:'N/A';
    let week52Low = quote.week52Low?quote.week52Low:'N/A';
    let volume = quote.latestVolume?quote.latestVolume:'N/A';
    if(volume/1000000>1)
    {
        volume=(volume/1000000).toFixed(2) +' M'
    }
    let avgVolume= quote.avgTotalVolume?quote.avgTotalVolume:'N/A';
    if(avgVolume/1000000>1)
    {
        avgVolume=(avgVolume/1000000).toFixed(2) +' M'
    }
    let marketCap= quote.marketCap?quote.marketCap:'N/A';
        if(marketCap/1000000000 >10)
        {
            
            marketCap=(marketCap/1000000000).toFixed(2) +' B'
        }
        else if(marketCap/1000000>1)
        {
            marketCap=(marketCap/1000000).toFixed(2) +' M'
        }
    let peRatio = quote.peRatio?quote.peRatio:'N/A';
    let divYeild = 'N/A';



   
    return(
        <Content style={{ marginTop:'2vh',backgroundColor:'',marginLeft:'5vw',marginRight:'5vw'}}>
            <Row style={{color:'#F68C1D', fontSize:24,display:'flex',}}>Stats</Row>
            <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',marginTop:0}}></Divider>

           <Col style={{display:'flex',justifyContent:'space-between',flexDirection:'row',alignItems:'center',marginTop:'-3vh'}}>
               
                <Col style={{justifyContent:'space-between',width:'40vw'}}>
                    <Row style={{display:'flex',justifyContent:'space-between',alignItems:'center', height:'4vh'}}>
                        <Col style={{marginLeft:'2vw',color:'#F68C1D'}}>Open</Col>
                        <Col style={{marginRight:'2vw',color:'#F68C1D'}}>${open}</Col>
                    </Row>
                </Col>
                
                <Col style={{justifyContent:'space-between',width:'40vw'}}>
                    <Row style={{display:'flex',justifyContent:'space-between',alignItems:'center', height:'4vh'}}>
                        <Col style={{marginLeft:'2vw',color:'#F68C1D'}}>Volume</Col>
                        <Col style={{marginRight:'2vw',color:'#F68C1D'}}>{volume}</Col>
                    </Row>
                </Col>
               
                
            </Col> 


            <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',marginTop:0}}></Divider>

            <Col style={{display:'flex',justifyContent:'space-between',flexDirection:'row',alignItems:'center',marginTop:'-3vh'}}>
               
               <Col style={{justifyContent:'space-between',width:'40vw'}}>
                   <Row style={{display:'flex',justifyContent:'space-between',alignItems:'center', height:'4vh'}}>
                       <Col style={{marginLeft:'2vw',color:'#F68C1D'}}>High</Col>
                       <Col style={{marginRight:'2vw',color:'#F68C1D'}}>${high}</Col>
                   </Row>
               </Col>
               
               <Col style={{justifyContent:'space-between',width:'40vw'}}>
                   <Row style={{display:'flex',justifyContent:'space-between',alignItems:'center', height:'4vh'}}>
                       <Col style={{marginLeft:'2vw',color:'#F68C1D'}}>Avg Vol.</Col>
                       <Col style={{marginRight:'2vw',color:'#F68C1D'}}>{avgVolume}</Col>
                   </Row>
               </Col>
              
               
           </Col> 
           <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',marginTop:0}}></Divider>


           <Col style={{display:'flex',justifyContent:'space-between',flexDirection:'row',alignItems:'center',marginTop:'-3vh'}}>
               
                <Col style={{justifyContent:'space-between',width:'40vw'}}>
                    <Row style={{display:'flex',justifyContent:'space-between',alignItems:'center', height:'4vh'}}>
                        <Col style={{marginLeft:'2vw',color:'#F68C1D'}}>Low</Col>
                        <Col style={{marginRight:'2vw',color:'#F68C1D'}}>${low}</Col>
                    </Row>
                </Col>
                
                <Col style={{justifyContent:'space-between',width:'40vw'}}>
                    <Row style={{display:'flex',justifyContent:'space-between',alignItems:'center', height:'4vh'}}>
                        <Col style={{marginLeft:'2vw',color:'#F68C1D'}}>Mkt Cap</Col>
                        <Col style={{marginRight:'2vw',color:'#F68C1D'}}>${marketCap}</Col>
                    </Row>
                </Col>
               
                
            </Col> 
            <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',marginTop:0}}></Divider>

            <Col style={{display:'flex',justifyContent:'space-between',flexDirection:'row',alignItems:'center',marginTop:'-3vh'}}>
               
                <Col style={{justifyContent:'space-between',width:'40vw'}}>
                    <Row style={{display:'flex',justifyContent:'space-between',alignItems:'center', height:'4vh'}}>
                        <Col style={{marginLeft:'2vw',color:'#F68C1D'}}>52 Wk High</Col>
                        <Col style={{marginRight:'2vw',color:'#F68C1D'}}>${week52High}</Col>
                    </Row>
                </Col>
                
                <Col style={{justifyContent:'space-between',width:'40vw'}}>
                    <Row style={{display:'flex',justifyContent:'space-between',alignItems:'center', height:'4vh'}}>
                        <Col style={{marginLeft:'2vw',color:'#F68C1D'}}>P/E Ratio</Col>
                        <Col style={{marginRight:'2vw',color:'#F68C1D'}}>{peRatio}</Col>
                    </Row>
                </Col>
               
                
            </Col> 
            <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',marginTop:0}}></Divider>

            <Col style={{display:'flex',justifyContent:'space-between',flexDirection:'row',alignItems:'center',marginTop:'-3vh'}}>
               
                <Col style={{justifyContent:'space-between',width:'40vw'}}>
                    <Row style={{display:'flex',justifyContent:'space-between',alignItems:'center', height:'4vh'}}>
                        <Col style={{marginLeft:'2vw',color:'#F68C1D'}}>52 Wk Low</Col>
                        <Col style={{marginRight:'2vw',color:'#F68C1D'}}>${week52Low}</Col>
                    </Row>
                </Col>
                
                <Col style={{justifyContent:'space-between',width:'40vw'}}>
                    <Row style={{display:'flex',justifyContent:'space-between',alignItems:'center', height:'4vh'}}>
                        <Col style={{marginLeft:'2vw',color:'#F68C1D'}}>Div/Yield</Col>
                        <Col style={{marginRight:'2vw',color:'#F68C1D'}}>NA</Col>
                    </Row>
                </Col>
               
                
            </Col> 
            <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',marginTop:0}}></Divider>


        </Content>
    );



}

export default KeyStats;
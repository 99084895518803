import React, {useState, useEffect} from 'react';

import {Layout, Select} from 'antd'
import {DownOutlined} from '@ant-design/icons';
const dropDownIcon = <DownOutlined style={{ color:'#F68C1D',}}/>
const {Option}= Select;
const {Content} = Layout;



let ChartTypeDropDown =(props) =>{

    let setChartType = props.setChartType;

    return(
        <Select defaultValue="Line" bordered={false} style={{width:'37.5%',backgroundColor:'#141414' ,borderColor:'#F68C1D', color:'#F68C1D'}}suffixIcon={dropDownIcon} dropdownStyle= {{backgroundColor:'#141414'}}onSelect={setChartType}>
                 <Option value="Line" style={{backgroundColor:'#141414' , color:'#F68C1D'}}>Line</Option>
                 <Option value="Candlestick" style={{backgroundColor:'#141414',color:'#F68C1D'}}>Candle</Option>
        </Select>
    );



}

export default ChartTypeDropDown;
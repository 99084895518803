import '../../App.css'
import React,{useState,useEffect} from 'react'
import {Card, Space, Row, Divider, Statistic,Col,Layout,Alert,Table} from 'antd'
import {
  CaretUpOutlined,
  CaretDownOutlined
} from '@ant-design/icons';

const {Content} = Layout;
const {Column} = Table;

// const dataSource = [
   
//     {
//     Symbol:"TSLA-Multiple",
//     TournamentID: 15,
//     UserID: "7eb55d5f-fe42-4559-aaea-c60d2c4d9ad5",
//     Price: 62.5,
//     NumberOfShares: 20,
//     Cost:1250,
//     CurrentPrice: 55,
//     Value:1100,
//     key:0,
//     TotalGain:-150,
//     PercentChange:-12,
//     TxType: "Multiple",
    
//     children:[
//         {
//             Symbol:"TSLA",
//             Price: 50,
//             NumberOfShares: 10,
//             Cost:500,
//             CurrentPrice:55,
//             Value:550,
//             TotalGain:50,
//             PercentChange:10,
//             TxType: "BUY",
//             TransactionID: 363,
//             key:363,
//             Status: "Open",

//           },
//           {
//             Symbol:"TSLA",
//             Price: 75,
//             NumberOfShares: 10,
//             Cost:750,
//             CurrentPrice: 55,
//             Value:550,
//             TotalGain:-200,
//             PercentChange:-26.67,
//             TxType: "BUY",
//             TransactionID: 364,
//             key:364,
//             Status: "Open",

//           },
//     ]
// },
// {
//     Symbol:"AAPL",
//     TournamentID: 15,
//     UserID: "7eb55d5f-fe42-4559-aaea-c60d2c4d9ad5",
//     Price: 100,
//     NumberOfShares: 10,
//     Cost:1000,
//     CurrentPrice: 120,
//     Value:1200,
//     TotalGain:200,
//     PercentChange:10,
//     TxType: "BUY",
//     Status:"Open",
//     // children:[]
//     }
   
//   ];
const columns = [
    {
        title:'',
        dataIndex:'',
        key:'',
        
        colSpan:1,
        

    },
    {
      // <div style={{backgroundColor:'#141414', color:'#F68C1D', display:'flex', width:50,height:50, justifyContent:'center', alignItems:'center'}}>Symbol</div>,
        // title:"Symbol",
        title:"Symbol",//<span className='Header'>Symbol</span>,
        dataIndex: 'Symbol',
        key: 'Symbol',
        width:100,
      },
      {
        title: 'Price',
        width:100,
        dataIndex: 'CurrentPrice',
        key: 'CurrentPrice',
        render:(CurrentPrice)=>(<Row>${CurrentPrice}</Row>)
      },
     
     
      {
        title: 'Shares',
        width:100,
        dataIndex: 'NumberOfShares',
        key: 'NumberOfShares',
        
      },
      {
        title: 'TxType',
        width:100,
        dataIndex: 'TxType',
        key: 'TxType',
        
      },
      {
        title:'Total Gain',
        width:100,
        dataIndex:'TotalGain',
        key:'TotalGain',
        render:(TotalGain)=>(TotalGain>0?<Row style={{color:'green'}}>${TotalGain}</Row>:<Row style={{color:'red'}}>${TotalGain}</Row>)

    },
    {
      title:'Percent Change',
      width:100,
      dataIndex:'PercentChange',
      key:'PercentChange',
      render:(PercentChange)=>(PercentChange>0?<Row style={{color:'green'}}>{PercentChange}%</Row>:<Row style={{color:'red'}}>{PercentChange}%</Row>)
  },
    {
      title: 'Total Value',
      width:100,
      dataIndex: 'Value',
      key: 'Value',
      render:(Value)=>(<Row>${Value}</Row>)
    },
      {
        title: 'Price Paid',
        dataIndex: 'Price',
        key: 'Price',
        width:100,
        render:(Price)=>(<Row>${Price}</Row>)
      },
      {
          title:'Total Cost',
          width:100,
          dataIndex:'Cost',
          key:'Cost',
          render:(Cost)=>(<Row>${Cost}</Row>)
      },
      
    

      
      
     
      
]


const PortfolioHoldings = (props) => {

  // let [selected, setSelected] = useState();

  // useEffect(() => {
  //   let selectedHolding =()=>{
  //     props.selectedHolding(selected);
  //   }
  //   selectedHolding();
    
  // }, [selected])
 let dataSource= props.holdings;
console.log('DataSource:',dataSource)
  let customHeader = ()=>{
    let res=[];
      for(let i=0;i<columns.length;i++)
      {
        res.push(<th  style={{backgroundColor:'#F68C1D',color:'black', width:100,  textAlign:'center',border:'1.5px solid black'}} key={columns[i].title}>{columns[i].title}</th>)
      }
      return(res)
  }
  const components = {
    header:{
      row: customHeader
    }
  }
if(dataSource.length>0)
{
return(
      <Table loading ={props.holding?true:false} components={components} columns={columns} dataSource={dataSource} pagination={false} scroll={{x:true}} bordered={false} rowClassName={'Portfolio-Column'} size="large"
      expandable={{expandRowByClick:true}}
      onRow ={(record, rowIndex) => {
        return {
          onClick: event => {
           // console.log('Event:', event)
           if(record.Symbol.includes('Multiple'))
           {
            console.log('Selected a Multiple Record:',record)
           }
           else{
             console.log('Selected a position:', record)
            // setSelected(record)
            props.selectedHolding(record)
           }
            
          }, // click row
         // onDoubleClick: event => {}, // double click row
         // onContextMenu: event => {}, // right button click row
         // onMouseEnter: event => {}, // mouse enter row
         // onMouseLeave: event => {}, // mouse leave row
        };
      }}/>
    
  )
    }
    else
    {
      return(
        <span>

     
        <h1 style={{color:'#F68C1D'}}>No Holdings... </h1>
        <h4 style={{color:'#F68C1D'}}>Visit the explore page to find buying opportunities</h4>
        </span>
      )
    }
}

export default PortfolioHoldings;


import React,{useState,useEffect} from 'react'
import {Card, Space, Row, Divider, Statistic,Col,Layout,Alert} from 'antd'
import {
  CaretUpOutlined,
  CaretDownOutlined
} from '@ant-design/icons';


const PortfolioValue = (props) => {
  let Balance,PortfolioValue =0;
  if(props.Balances)
  {
    Balance = props.Balances.Balance
    PortfolioValue = props.Balances.PortfolioValue;
  }

return(


<Card
style={{backgroundColor:'#141414', borderColor:'#F68C1D', marginTop:'6vh',borderRightWidth:0, borderLeftWidth:0}}
  >
    
  <Row gutter={16} style={{backgroundColor:'#141414'}}>
  <Col span={12}>
    <Statistic valueStyle={{color:'#F68C1D'}} style={{color:'#F68C1D'}} title={<h5 style={{color:'#F68C1D'}}>Account Balance (VUSD) </h5>} value={'$'+Balance} precision={2} />
  </Col>
  <Col span={12}>
      <Statistic valueStyle={{color:'#F68C1D'}} title={<h5 style={{color:'#F68C1D'}}>Portfolio Value (VUSD) </h5>}  value={'$'+PortfolioValue} />
  </Col>
  

  </Row>

  </Card>
  )
}

export default PortfolioValue;
import React, {useState, useEffect} from 'react';

import {Layout, Row, Button} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';

const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let ChartOptions =(props) =>{

    const [selected, setSelection] = useState(1);
    const [chartType,setChartType]= useState('Line')

    useEffect(() => {
        let changeChart = ()=>{
            props.setChartTimeSelection(selected)
        }
        changeChart();

    }, [selected])

    useEffect(() => {
        let changeChartType =()=>{
        console.log('chartType:',chartType)
        props.setChartType(chartType)
        }
        changeChartType();
       
    }, [chartType])
    

   
    return(
        <Content style={{marginLeft:'2vw', marginRight:'2vw', marginTop:'1vh',backgroundColor:''}}>

            <Row style={{justifyContent:'space-between'}}>
               <Button ghost style={{backgroundColor:'transparent', borderColor:selected==1?'#F68C1D':'transparent',color:'#F68C1D',}} onClick={()=>setSelection(1)}>

                   1D
               </Button>
               <Button ghost style={{backgroundColor:'transparent', borderColor:selected==2?'#F68C1D':'transparent',color:'#F68C1D',}}onClick={()=>setSelection(2)}>
                   1W
               </Button>
               <Button ghost style={{backgroundColor:'transparent', borderColor:selected==3?'#F68C1D':'transparent',color:'#F68C1D',}}onClick={()=>setSelection(3)}>
                   1M
               </Button>
               <Button ghost style={{backgroundColor:'transparent', borderColor:selected==4?'#F68C1D':'transparent',color:'#F68C1D',}}onClick={()=>setSelection(4)}>
                   3M
               </Button>
               <Button ghost style={{backgroundColor:'transparent', borderColor:selected==5?'#F68C1D':'transparent',color:'#F68C1D',}}onClick={()=>setSelection(5)}>
                   1Y
               </Button>
               <Button ghost style={{backgroundColor:'transparent', borderColor:selected==6?'#F68C1D':'transparent',color:'#F68C1D',}}onClick={()=>{chartType=="Line"?setChartType("Candle"):setChartType("Line")}}>
                   {chartType}
               </Button>
               
               

               
            </Row>
        </Content>
    );



}

export default ChartOptions;
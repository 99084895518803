import React,{useState,useEffect} from 'react'
import {Card, Space, Row, Divider, Statistic,Col, Layout, Spin, Button} from 'antd'
import {FrownOutlined, RedoOutlined
} from '@ant-design/icons';
import {useLocation, useHistory, Link} from "react-router-dom";


const { Header, Content, Footer, Sider} = Layout;
const ErrorLoading = (props) => {

    let path = useLocation().pathname
    let history = useHistory()
   
    //let path = props.path
    let refresh= ()=>{
        console.log('error loading paths',path)

    history.push('/temp');
    history.goBack();
    console.log('error loading history',history)

      //  history.go(0)
        //history.go(0)
        //window.location.replace
       //window.location.reload(false)
    }

    let LoadingMessage = props.message;
    return(
        <Content style={{backgroundColor:'#141414'}}>
            
            <Col style={{ flex:'display',marginTop:'25vh'}}>
            <Link to={'/Tournaments'}>
            </Link>
            <Space></Space>
            <Row style={{ flex:'display',justifyContent:'center'}}>
            <FrownOutlined style={{fontSize:72, color:'#F68C1D'}}/>
            
            </Row>
            <Space></Space>

            <h2 style={{color:'#F68C1D'}}>Somthing went wrong...</h2>
            <Space></Space>
            
            <Button style={{backgroundColor:'black',borderColor:'#F68C1D',color:'#F68C1D', width:'30vw',height:'10vh',flex:'display' , alignItems:'center', justifyContent:'center'}} shape="round" type="primary" primary  onClick={()=>refresh()}>
            <RedoOutlined style={{fontSize:72, color:'#F68C1D'}}/></Button> 
             

            </Col>
            <Col style={{ flex:'display',marginTop:'40vh',alignItems:'center'}}>

<Link to={'/Tournaments'}>
<Button style={{backgroundColor:'black',borderColor:'#F68C1D', color:'#F68C1D', width:'60vw',}} shape="round" type="primary" primary  onClick={()=>console.log('Going to home!')}>Go to Home</Button>

</Link>
            </Col>
        </Content>
)

}

export default ErrorLoading;
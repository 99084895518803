import React, {useEffect, useState} from 'react';
import { Layout, Menu, Breadcrumb, Space, Row,Col} from 'antd';
import {
  DesktopOutlined,
  HomeOutlined,
  SendOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  SettingOutlined,
  TrophyOutlined,
  SlidersOutlined,
  CrownOutlined,
  UserOutliend
} from '@ant-design/icons';
import '.././App.css';
import { Link } from 'react-router-dom'

//#F68C1D GOLD COLOR


const { Header, Content, Sider, Footer  } = Layout;
const { SubMenu } = Menu;

const TournamentTabs = (props) =>{
  let defaultKeyz = props.defaultKey;
  
  let [defaultKey,setDefaultKey] =useState(Number(defaultKeyz))
  let [tabOne,setTabOne] = useState(false)
  let [tabTwo,setTabTwo] = useState(true)
  let [tabThree,setTabThree] = useState(false)

  useEffect(()=>{
    menuSelect(defaultKey)
  },[])

  

  let menuSelect =(key)=>
  {
   
    console.log('here', key)
      if(key=='1')
      {
          setTabOne(true);
          setTabTwo(false);
          setTabThree(false);
          props.setTab('1')
      }
      if(key=='2')
      {
          setTabOne(false);
          setTabTwo(true);
          setTabThree(false);
          props.setTab('2')
      }
      if(key=='3')
      {
          setTabOne(false);
          setTabTwo(false);
          setTabThree(true)
          props.setTab('3')
      }

  }


    return (
     
        <Header style={{ padding: 0,position:"fixed", Top:0,width:'100vw',zIndex:1}}>
          
          <Menu  theme="dark" mode="horizontal" defaultSelectedKeys={[defaultKey]} style={{height:'10vh',backgroundColor:'black' }}  mode='horizontal' >
            
            <Menu.Item onClick={()=>menuSelect('1')} style={{width:"33.33%",height:"100%",   backgroundColor:tabOne? '#141414':'black'}} selectable={!tabOne} key="1" >
                    
                    <div style={{display:'flex',justifyContent:'center',alignContent:'center',marginTop:'1vh',width:'100%'}} >
                    
                      <Col >
                        <Row style={{alignItems:'center',justifyContent:'center',margin:0}}> <HomeOutlined style={{margin:0,padding:0,color:tabOne?'#F68C1D':'#8c8c8c',fontSize:tabOne?32:18}}/></Row>
                        <Row style={{margin:"-1vh",display:'flex',alignItems:'center',justifyContent:'center',color:tabTwo?'#F68C1D':'#8c8c8c',color:tabOne?'#F68C1D':'#8c8c8c'}}>Standard </Row>
                      </Col>
                    </div>   
                     
            </Menu.Item>

            <Menu.Item onClick={()=>menuSelect('2')} style={{width:"33.33%",height:"100%",  backgroundColor:tabTwo? '#141414':'black'}}selectable={!tabTwo} key="2">
                   
                    <div style={{display:'flex',justifyContent:'center',alignContent:'center',marginTop:'1vh'}}>
                      <Col >
                      {/* <Link to={"/Portfolio"}></Link> */}
                        <Row style={{alignItems:'center',justifyContent:'center',margin:0}}><CrownOutlined style={{margin:0,padding:0,color:tabTwo?'#F68C1D':'#8c8c8c',fontSize:tabTwo?32:18}}/></Row>
                        <Row style={{margin:"-1vh",display:'flex',alignItems:'center',justifyContent:'center',color:tabTwo?'#F68C1D':'#8c8c8c',color:tabTwo?'#F68C1D':'#8c8c8c'}}>My Tournaments </Row>
                      </Col>
                    </div>  
                       
            </Menu.Item>

            <Menu.Item onClick={()=>{alert('Coming soon! 😄')/*menuSelect('3')*/}} style={{width:"33.33%",height:"100%",  backgroundColor:tabThree? '#141414':'black',}}selectable={!tabThree} key="3">
                    
                    <div style={{display:'flex',justifyContent:'center',alignContent:'center',marginTop:'1vh'}} >
                      <Col >
                      
                        <Row style={{alignItems:'center',justifyContent:'center',margin:0}}> <UserOutlined style={{margin:0,padding:0,color:tabThree?'#F68C1D':'#8c8c8c',fontSize:tabThree?32:18}}/></Row>
                        <Row style={{margin:"-1vh",display:'flex',alignItems:'center',justifyContent:'center',color:tabTwo?'#F68C1D':'#8c8c8c',color:tabThree?'#F68C1D':'#8c8c8c'}}>Premium </Row>
                      </Col>
                    </div>    
                     
            </Menu.Item>
 
          </Menu>
         
        </Header>

        

       
      
    );
  
}

export default TournamentTabs
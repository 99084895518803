import React, {useState, useEffect} from 'react';

import {Layout, Row, Button, Col} from 'antd'
import {LeftOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';

const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let TopHomeContainer =(props) =>{
    let leftCornerMessage = props.leftCornerMessage;
    let rightCornerMessage =props.rightCornerMessage;
    let centeredMessage = props.centeredMessage

    return(
        <Content style={{width:'100vw',height:'6vh', backgroundColor:'black',position:'fixed', zIndex:1, }}>
           <Row style={{display:'flex', alignContent:'center', justifyContent:'space-between'}}>
           
                <div style={{ marginTop:5, }}>
                    <h3 style={{ color:'#F68C1D',marginLeft:2}}>{leftCornerMessage}</h3>
               </div>
           


            <div style={{display:'flex', marginTop:5,}}>
            <h1 style={{ color:'#F68C1D',}}>{centeredMessage}</h1>
            </div>
               
               <div style={{ marginTop:5, }}>
                    <h3 style={{ color:'#F68C1D',marginRight:2}}>{rightCornerMessage}</h3>
               </div>
            </Row>
            
        </Content>
    );



}

export default TopHomeContainer;
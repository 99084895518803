import React, {useState, useEffect, } from 'react';

import {Layout, Row,Card, Col, Button} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';

//const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let ATournamentCard =(props) =>{

    let aTourn = props.tournament

    let StartingBalance = aTourn.StartingBalance;
    let TournamentLength = '1'+ aTourn.TournamentLengthType.toUpperCase();
    let StartDate = aTourn.StartDate.split('T')[0]
    let EndDate = aTourn.EndDate.split('T')[0];
    let MaxEntries = aTourn.MaxEntries;
    let RewardType = aTourn.PremiumTournament==0?'Coins':'Cash';
    let CryptoTournament = aTourn.CryptoTournament==0?'Stocks':'Crypto Currencies';
    let TradingEnabled = aTourn.TradingEnabled==0?'No':'Yes'
    let PublicPortfolio = aTourn.PublicPortfolio==0?'Private till end of tournament':'Public'
    let Description = aTourn.Description;
    let MinimumAssetValue = aTourn.MinimumAssetValue;
    let MaximumAssetValue = aTourn.MaximumAssetValue;
    let MaxAllocation = aTourn.MaxPurchasePercentage;
    let EntryFee = aTourn.EntryAmount;
    let RewardStructure = '150 to Winner, 75 to Second, 50 to third, 25 to fourth, 15 to third, 5 to fifth';
    let RewardAmount = aTourn.RewardAmount;
    

    console.log('A Tournament: ', aTourn)
    return(

        
            <Button onClick={()=>props.onClick(aTourn)} style={{marginTop:'2vh',marginBottom:'1vh',width:'95vw', height:'10vh',color:'#F68C1D', backgroundColor:'black',  borderColor:'#F68C1D'}}>

            
            <Row style={{justifyContent:'space-between', alignItems:'center'}}>
            <Col>
            {TournamentLength}
            </Col>

            <Col>
            <Row>{StartDate}</Row>
            <Row style={{justifyContent:'center'}}>till</Row>
            <Row> {EndDate}</Row>
            </Col>

            <Col>
            ${StartingBalance}
            </Col>

            <Col>
            {EntryFee}
            </Col>

            <Col>
            {MaxEntries}
            </Col>
            </Row>
            </Button>
        
    );
      
{/* <Button  onClick={()=>console.log('Pressed Button')} link style={{width:'98vw',height:'15vh',backgroundColor:'black', borderColor:'#F68C1D'}}>   
            <Card hoverable bordered={true} style={{ display:'flex',justifyContent:'center',borderColor:'transparent', color:'#F68C1D', width:'100%', backgroundColor:'black',height:'100%',}}>

       
        <Row style={{alignItems:'center', justifyContent:'space-between'}}>
       
        <Col style={{color:'#F68C1D', width:'10%',}}>
        
         {TournamentLength}
        </Col>

        <Col style={{color:'#F68C1D',width:'30%'}}>
        <Row>{StartDate}</Row>
        <Row style={{justifyContent:'center'}}>till</Row>
        <Row> {EndDate}</Row>
        </Col>
        
         <Col style={{color:'#F68C1D',width:'20%',}}>
            
             <Row>${StartingBalance}</Row>
        </Col>

        <Col style={{color:'#F68C1D',width:'20%', }}>
            <Row>{EntryFee}</Row>
            {/* <Button onClick={()=>loadingData(UserID)}  type="primary" ghost style ={{ border: '0px hidden #F68C1D'}} icon={loading?aloading:rightArrow} >
                  
            </Button> 

        </Col>

        <Col style={{color:'#F68C1D',width:'20%'}}>
             <Row>{MaxEntries}</Row>
        </Col>
        </Row>
       
        </Card>
        </Button>  */}

        
            
        // <Button  onClick={()=>console.log('Pressed Button')} link style={{width:'98vw',height:'15vh',backgroundColor:'black', borderColor:'#F68C1D'}}>   
        //     <Card style={{ border:'1px solid #F68C1D', color:'#F68C1D', width:'100%', height:'100%',display:'flex' ,justifyContent:'space-between'}}>

       
        // <Row style={{alignItems:'center', justifyContent:'space-between'}}>
       
        // <Col style={{color:'#F68C1D', width:'20%',display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
        
        //  {TournamentLength}
        // </Col>

        // <Col style={{color:'#F68C1D',width:'20%',  flexDirection:'column',justifyContent:'center', alignItems:'center'}}>
        // <Row>{StartDate}</Row>
        // <Row>till</Row>
        // <Row> {EndDate}</Row>
        // </Col>
        
        //  <Col style={{color:'#F68C1D',width:'20%',justifyContent:'flex-end'}}>
            
        //      <Row>${StartingBalance}</Row>
        // </Col>

        // <Col style={{color:'#F68C1D',width:'20%', flexDirection:'column',alignItems:'flex-end'}}>
        //     <Row>{EntryFee}</Row>
        //     {/* <Button onClick={()=>loadingData(UserID)}  type="primary" ghost style ={{ border: '0px hidden #F68C1D'}} icon={loading?aloading:rightArrow} >
                  
        //     </Button> */}

        // </Col>

        // <Col style={{color:'#F68C1D',width:'20%',display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
        //      <Row>{MaxEntries}</Row>
        // </Col>
        // </Row>
       
        // </Card>
        // </Button> 
        
        
    



}

export default ATournamentCard;
let news =[
    {
      datetime: 1616685331000,
      headline: 'Could Long-Term Capital Gains Selling Be Weighing On The Market?',
      source: 'Benzinga',
      url: 'https://cloud.iexapis.com/v1/news/article/3b64ba7e-3317-40aa-ac34-b5bdda69aa5b',
      summary: 'The SPDR S&P 500 ETF Trust (NYSE: SPY ) has generated a 78.1% total return since the stock market bottomed on March 23, 2020. Investors who bought the dip are now sitting on some big gains, and the one-year anniversary of the shortest bear market in history could generate some selling pressure in the market in the coming weeks. Capital Gains Tax Rules: Profits from stock trading are taxes differently depending on how long the positions were held. Capital gains from a stock that is held for less than a year before it is sold are considered ordinary income and are taxed accordingly. Therefore, an investor’s short-term capital gains tax rate depends on his or her tax bracket. For the 2020 tax year, the short-term capital gains tax rates for single filers are included in the table below. Full story available on Benzinga.com',
      related: 'SPY',
      image: 'https://cloud.iexapis.com/v1/news/image/3b64ba7e-3317-40aa-ac34-b5bdda69aa5b',
      lang: 'en',
      hasPaywall: false
    },
    {
      datetime: 1616595008000,
      headline: "If You Invested $1,000 In Bitcoin One Year Ago, Here's How Much You'd Have Now",
      source: 'Benzinga',
      url: 'https://cloud.iexapis.com/v1/news/article/18199e6d-0a77-4c03-b675-a49bf1df1e0e',
      summary: 'Investors who have owned stocks in the last year have generally experienced some big gains. In fact, the SPDR S&P 500 (NYSE: SPY ) total return over the last 12 months is 74.3%. But there is no question some investments performed better than others along the way. Bitcoin’s Big Run: As strong as the stock market has been since it bottomed on March 23, 2020, Bitcoin (CRYPTO:BTC) has been much stronger. After a wild ride that took Bitcoin prices near $20,000 in late 2017, the previous Bitcoin bubble burst in 2018 and the cryptocurrency finished the year down 72.6%. Bitcoin came back to life in 2019, however, finishing the year up 87.2% and priced at around $7,200. Several factors led to a surge in Bitcoin buying in 2020. First, investors concerned about the potential long-term damage that trillions of dollars in federal stimulus could do to the value of the … Full story available on Benzinga.com',
      related: 'SPY',
      image: 'https://cloud.iexapis.com/v1/news/image/18199e6d-0a77-4c03-b675-a49bf1df1e0e',
      lang: 'en',
      hasPaywall: false
    },
    {
      datetime: 1616530378000,
      headline: "The Dow Jones Plunged Today. Here's Why.",
      source: 'Benzinga',
      url: 'https://cloud.iexapis.com/v1/news/article/895cbe76-9a1f-4357-8e23-bd20d171669e',
      summary: 'U.S. indices at large were trading lower as investors weigh comments from Fed Chair Powell and Treasury Secretary Yellen, as well as recent lockdown measures in Europe. The SPDR Dow Jones Industrial Average ETF Trust (NASDAQ: DIA ) traded as low as $323.45 Tuesday before ending the day down by 0.97% at $324.06. Weakness for the Dow came from industrials, who were the most hammered names for the index. The SPDR S&P 500 ETF Trust (NASDAQ: SPY ) finished lower by 0.79% at $389.50. The Invesco QQQ Trust Series 1 (NASDAQ: QQQ ) fell by 0.44% at $317.22. Watch … Full story available on Benzinga.com',
      related: 'SPY',
      image: 'https://cloud.iexapis.com/v1/news/image/895cbe76-9a1f-4357-8e23-bd20d171669e',
      lang: 'en',
      hasPaywall: false
    },
    {
      datetime: 1616520308000,
      headline: "If You Invested $1,000 In The SPY ETF One Year Ago, Here's How Much You'd Have Now",
      source: 'Benzinga',
      url: 'https://cloud.iexapis.com/v1/news/article/ecce20bf-3aa0-48de-8deb-4923dc2dba55',
      summary: "Investors who have owned high-flying tech companies, stay-at-home plays and Reddit “meme” stocks have made a killing in the market in the past year. But you didn’t have to be a stock-picking genius to generate some historically good returns since March 23, 2020. Even investors who took a conservative, diversified approach to the market and timed the 2020 pandemic bottom last March have made a tremendous return on their investment at this point. Related Link: If You Invested $1,000 In Ford Stock One Year Ago, Here's How Much You'd Have Now In 2019, the SPDR S&P 500 ETF Trust (NYSE: SPY ) generated an extremely strong 31.2% total return. In fact, the index fund had an extremely impressive decade in the … Full story available on Benzinga.com",
      related: 'SPY',
      image: 'https://cloud.iexapis.com/v1/news/image/ecce20bf-3aa0-48de-8deb-4923dc2dba55',
      lang: 'en',
      hasPaywall: false
    },
    {
      datetime: 1616453108000,
      headline: "'Stimmy' Checks And The Stock Market: Will The Retail Trading Frenzy Continue?",
      source: 'Benzinga',
      url: 'https://cloud.iexapis.com/v1/news/article/25288c61-b9e8-4ab3-ae75-0c299e031397',
      summary: 'The recession triggered by the pandemic has been unique compared to recessions of the past. The shutdown of the U.S. economy was voluntary and not triggered by a sharp downturn in economic fundamentals. Buying The Dip: As a result, many investors saw the stock market sell-off last March as a long-term buying opportunity, and investors have been handsomely rewarded for that buying. In addition, the brief 2020 bear market was the first bear market buying opportunity for investors in more than a decade, and the availability of free trading apps lured in an entire new generation of young traders for the first time. March 23 marks the one-year anniversary of the 2020 bottom in the S&P 500. While the SPDR S&P 500 ETF Trust (NYSE: SPY ) is remarkably up 75.9% in a year’s time, some high-octane stocks have skyrocketed much more than that. Younger retail users of trading app Robinhood, Reddit’s WallSteetBets community and other groups of online traders have poured cash into a collection of fundamentally sound long-term investments and high-flying short squeeze trades and so-called “meme” stocks.',
      related: 'SPY',
      image: 'https://cloud.iexapis.com/v1/news/image/25288c61-b9e8-4ab3-ae75-0c299e031397',
      lang: 'en',
      hasPaywall: false
    },
    {
      datetime: 1616445643000,
      headline: "The QQQ Rallied Today. Here's Why.",
      source: 'Benzinga',
      url: 'https://cloud.iexapis.com/v1/news/article/76501d5e-db3a-41c2-8a18-d2bb87f43ced',
      summary: "The Invesco QQQ Trust Series 1 (NASDAQ: QQQ ) hit an intraday high of $320.74 before ending the day up by 1.88% at $318.61. Strength for the index was found in several key tech names as yields fell Monday, names who’d otherwise been faltering amid 10-year yields hitting a 14-month high of 1.75% last Friday. The SPDR S&P 500 ETF Trust (NASDAQ: SPY ) closed higher by 0.80% at $392.59. The SPDR Dow Jones Industrial Average ETF Trust (NASDAQ: DIA ) also finished the day higher by 0.38% at $327.24. Here are the day's winners and losers from the QQQ, according to data from … Full story available on Benzinga.com",
      related: 'SPY',
      image: 'https://cloud.iexapis.com/v1/news/image/76501d5e-db3a-41c2-8a18-d2bb87f43ced',
      lang: 'en',
      hasPaywall: false
    },
    {
      datetime: 1616425273000,
      headline: 'What You Need To Know In Options This Week. SPY, GameStop, Tesla, Adobe, Blink, At Home, Bionano And More',
      source: 'Benzinga',
      url: 'https://cloud.iexapis.com/v1/news/article/05e194f0-1173-4432-8727-7ec1e2db2a0b',
      summary: "The Broader Markets The S&P 500 was lower last week by about 1.2%, slightly less than the 1.5% move that was priced by the options market. The small move lower did not affect overall implied volatility, as the VIX stayed near 20 to close the week. SPY options are pricing in about a 1.4% move in either direction for the upcoming week. That corresponds to about $383 to the downside and $395 on the upside. Here's this week's expected move chart via Options AI : In the News GameStop (NYSE: GME ) closed the week near $200. The company reports earnings on Tuesday after the close. Options are pricing in about a 28% move for this week, corresponding to about $255 on the upside and $140 on the downside: We recently wrote about the uniqueness of option implied volatility and skew in stocks that are experiencing large price movements like GameStop and ( you can read that here ). Tesla (NASDAQ: TSLA ) was about 6% lower on the week versus the 7% move … Full story available on Benzinga.com",
      related: 'SPY',
      image: 'https://cloud.iexapis.com/v1/news/image/05e194f0-1173-4432-8727-7ec1e2db2a0b',
      lang: 'en',
      hasPaywall: false
    },
    {
      datetime: 1616418780000,
      headline: 'First Trust Lists March Series of Target Outcome ETFs® Buffer Strategies Based on QQQ, EFA and SPY',
      source: 'Business Wire',
      url: 'https://cloud.iexapis.com/v1/news/article/cdf00be7-1f7c-4399-ad01-4f2c13b143a2',
      summary: 'WHEATON, Ill.--(BUSINESS WIRE)--First Trust Advisors L.P. (“First Trust”) a leading exchange-traded fund (“ETF”) provider and asset manager, announced today that it has expanded its suite of Target Outcome ETFs® with Buffer Strategies based on Invesco QQQ TrustSM Series 1 ("QQQ") and iShares MSCI EAFE ETF (“EFA”), as well as Buffer and Deep Buffer Strategies based on SPDR® S&P 500® ETF Trust ("SPY"). The funds are actively managed ETFs that are designed to help investors maintain a level of',
      related: 'SPY',
      image: 'https://cloud.iexapis.com/v1/news/image/cdf00be7-1f7c-4399-ad01-4f2c13b143a2',
      lang: 'en',
      hasPaywall: false
    },
    {
      datetime: 1616416463000,
      headline: "Groundhog Day: Instability, Illiquidity, & 'Invincibility'",
      source: 'Zero Hedge',
      url: 'https://cloud.iexapis.com/v1/news/article/66f1b77a-6aea-491a-9fce-506006544701',
      summary: "Groundhog Day: Instability, Illiquidity, & 'Invincibility' Via SpotGamma.com, The current setup feels eerily familiar. In January of ’21 we wrote a note called “When YOLO goes YOL-OH NO!” which outlined the risks embedded in this market. The basis for this risks where: Large speculative call positions which increased leverage and market volatility Small relative put positions which infer the market is not hedged for a downside move Little short selling in markets What ensued was a very sharp ~4% drawdown… which lasted all of 5 days. However during the ~2 months since that was written we’ve seen two of the largest single day VIX spikes in history . And since writing that “ YOLO ” article, the options market has continued this pattern and become more committed to the current call position. With that we note that realized volatility, how much the market has been moving, has been increasing during each peak this year. You can see this measured in the chart below which depicts realized volatility on a 5 day (red) and 1 moth basis (green).",
      related: 'SPY',
      image: 'https://cloud.iexapis.com/v1/news/image/66f1b77a-6aea-491a-9fce-506006544701',
      lang: 'en',
      hasPaywall: false
    },
    {
      datetime: 1616162280000,
      headline: "Fed Still Taking 'All-In' Approach To Economic Recovery: Powell",
      source: 'Benzinga',
      url: 'https://cloud.iexapis.com/v1/news/article/a34b8e82-2337-4898-bcae-85807b4f5cb2',
      summary: 'The SPDR S&P 500 (NYSE: SPY) recovered from early losses on Friday and traded higher by 0.2% after the Wall Street Journal published an op-ed by Federal …',
      related: 'SPY',
      image: 'https://cloud.iexapis.com/v1/news/image/a34b8e82-2337-4898-bcae-85807b4f5cb2',
      lang: 'en',
      hasPaywall: false
    }
  ]

  export default news;
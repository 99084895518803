import React,{ useState, useEffect } from 'react';

import logo from './logo.svg';
import './App.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import {Auth,API} from 'aws-amplify';
import {BrowserRouter as Router, Switch, Route, Redirect, Link} from 'react-router-dom'
import { Layout, Menu, Breadcrumb, Image ,Spin, Row, Col, Space, Button} from 'antd';

import mklogo from './marketKingLogo.png'

import bullbear from './bullbear.png'

import Main from './Routes/Main'
import Tournaments from './Routes/Tournaments'
import Account from './Routes/Account'

import Home from './Routes/Home'
import CustomFooter from './Components/CustomFooter'
import Trade from './Routes/Trade';
import NewTrade from './Routes/NewTrade';
import Portfolio from './Routes/Portfolio';
import Standings from './Routes/Standings';
import Explore from './Routes/Explore';
import Loading from './Components/Loading'
import checkUser from './Utils/checkUser';
import ErrorLoading from './Components/ErrorLoading'
import NewPortfolio from './Routes/NewPortfolio';


import { LoadingOutlined, LeftOutlined} from '@ant-design/icons';
import BrowseAssets from './Routes/BrowseAssets';
import ViewAsset from './Routes/ViewAsset';
const anIcon = <LoadingOutlined style={{fontSize:72, color:'#F68C1D',zIndex:0, position:'absolute',marginLeft:'40vw',marginTop:'23vh'}} spin>Loading</LoadingOutlined>
const leftIcon = <LeftOutlined style={{fontSize:32, color:'white',}}/>
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;



const App = () => {
  const [UserInfo, setUserInfo]=useState();
  const [Loading, setLoading]=useState(true);
  const [aLoading, setaLoading]=useState(false);
  const [zLoading, setZLoading]=useState(true)

   useEffect(()=>{
       const fetchUserID = async () =>{
          Auth.currentAuthenticatedUser()
            .then(user =>{
                let userInfo = {UserID: user.attributes.sub, UserName:user.username}
                setUserInfo(userInfo);

                let apiName = 'MarketKingsAPI'
                let path = '/standings/api/CheckUser'
                let myInit = {body:userInfo}
            
                API.post(apiName, path, myInit)
                  .then(response =>{
                  console.log('A Response:',response)
                  setLoading(false)
                  // setaLoading(true)
                  // setTimeout(()=>{
                  //   setaLoading(false)
                  // },1000)
                  }).catch(err=>console.log(err))
              }).catch(err=>console.log(err))
       };
       fetchUserID();
   },[])

   if(Loading == true){
    return(
      <Router>
     <Content style={{width:'100vw',position:'fixed'}}>
         {/* <div style={ {height:'100vh', textAlign:'center', display:'flex',justifyContent:'center'}}> */}
          
            {/* <div style={{ display:'inline', width:'100%',justifyContent:'center'}}> */}

            {/* <div style={ {height:'100vh'}}> */}
           
            <Spin indicator={anIcon}></Spin>
            
            <Image style={{position:'absolute',left:'-50vw',zIndex:-1}}preview={false} width='200vw' height='100vh'src={bullbear}></Image>
            <Image  style={{position:'absolute',top:'85vh',left:'25vw'}}preview={false} width={'50vw'} src={mklogo}></Image>

            {/* </div> */}
          {/* // </div> */}

           {/* </div> */}
           
           </Content>
      </Router>

    );
  }

  if(Loading == true){
    return(
      <Router>
     
         <div style={ {backgroundColor:'#001529',height:'100vh', width:'100vw', display:'flex' , alignItems:'center',justifyContent:'center', textAlign:'center'}}>
           <div  style={{display:'flex',alignItems:'center'}}>
             <Col >
                <Row style={{marginLeft:'40%',marginRight:'40%'}}>
                    <Spin indicator={anIcon}></Spin>
                </Row>
                
                <Row style={{marginTop:40}}>
                  <Image  preview={false} width={'50vw'} src={mklogo}></Image>
                </Row>
             
             </Col>
           </div>
           
         </div> 
      </Router>

    );
  }
  if(aLoading == true){
    return(
      <Router>
     
         <div style={ {backgroundColor:'#001529',height:'100vh', width:'100vw', display:'flex' , alignItems:'center',justifyContent:'center', textAlign:'center'}}>
           <div className="Logo-Animation" style={{display:'flex',alignItems:'center'}}>
             <Col >
                <Row style={{marginTop:40}}>
                  <Image  preview={false} width={'50vw'} src={mklogo}></Image>
                </Row>
             
             </Col>
           </div>
           
         </div> 
      </Router>

    );
  }
else{
  //style={{ padding: 0, position:"sticky", top:"0" }}
  return (
    <Router >
    <div className="App">
     <Layout style={{ minHeight: '100vh',width:'100vw',position:'sticky',left:0,right:0}}>
     {/* <Header className="site-layout-background"  style={{ padding: 0,position:"fixed", top:"0",minWidth:'100vw',minHeight:'10vh', display:'flex',justifyContent:'center',backgroundColor:'black'}}> 
      <Link to={"/Tournaments"} style={{alignSelf:'center',position:'fixed', left:5}}>
        <Button type="primary" ghost style ={{ borderColor:'transparent'}} icon={leftIcon} />
      </Link>
      <Image style={{alignSelf:'center'}}preview={false} width={'50vw'} height='7vh'src={mklogo}></Image>
     </Header> */}
        

        <Switch>
          
          <Route path='/' exact component={Main}/> 
          <Route path='/Home' exact component={Main}/> 
          <Route path='/BrowseAssets' exact component={BrowseAssets}/>
          <Route path='/BrowseAssets/:List' exact component={BrowseAssets}/>
          <Route path='/ViewAsset/:Symbol' exact component={ViewAsset}/>
         
          {/*<Route path='/' exact component={Tournaments}/>*/}
          <Route path='/Tournaments' exact component={Tournaments}/>
          <Route path='/Account' exact component={Account}/>
          
          <Route path='/Explore/:TournamentID' exact component={Explore}/>
          <Route path='/Trade/:TournamentID/:Symbol' exact component={NewTrade}/>
          <Route path='/Portfolio/:TournamentID' exact component={NewPortfolio}/>
          <Route path='/Standings/:TournamentID' exact component={Standings}/>
          
        </Switch>  
        
        

     
     </Layout>
      </div>
    </Router>
     
  );
}
}

export default withAuthenticator(App);

import axios from 'axios'
const axiosInstance = axios.create({
    baseURL: "https://www.alphavantage.co/query"
});

const getDailyChartForSymbol = (symbol) => {
    return axiosInstance.get('',{
        params:{
            function: 'TIME_SERIES_DAILY',
            symbol,
            apikey: 'A5MMI517A3HS89FV'
        }
    })
};

export default getDailyChartForSymbol
import react,{useEffect, useState} from 'react';

import {Layout, Card, Col, Row, Space} from 'antd';

import {ArrowRightOutlined} from '@ant-design/icons';
import Standings from '../Routes/Standings';

const {Content} = Layout

const Podium = (props) =>{

    let Username = props.Username;
    let PortfolioValue = props.PortfolioValue;
    let Place = props.Place;

    
        return(
            <div>
           <Content style={{width:'100vw',height:'25vh',backgroundColor:'blue',display:'flex', flexDirection:'row',marginBottom:"-20vh", }}>

            <h1>test</h1>  
            </Content>
            <Content style={{width:'100vw',height:'25vh',backgroundColor:'red',display:'flex', alignItems:'flex-end',  zIndex:-1 }}>

                 
                
                <div style={{width:'100%', height:'50%',display:'flex', justifyContent:'center',alignItems:'flex-end',}}>

                <Col style={{backgroundColor:'black', width:'32%',height:'60%',color:'#F68C1D',display:'flex', flexDirection:'column',justifyContent:'center',  border:'1px solid #F68C1D',}}>
                    
                    {/* <Row style={{display:'flex',justifyContent:'center', alignItems:'center'}}> */}
                    
                    {/* </Row> */}
                    
                    <Row style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                    2nd
                    </Row>
                    
                </Col>

                <Col style={{backgroundColor:'black', width:'32%',height:'100%',color:'#F68C1D',display:'flex', alignItems:'center', justifyContent:'center', border:'1px solid #F68C1D'}}>
                    1st
                </Col>

                <Col style={{backgroundColor:'black',width:'32%',height:'30%',color:'#F68C1D',display:'flex', alignItems:'center', justifyContent:'center',border:'1px solid #F68C1D', }}>
                    3rd
                </Col>
               
                </div>
                
            </Content>
            </div>
         )
    
    

    
}

export default Podium;
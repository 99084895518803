import react,{useEffect, useState} from 'react';

import {Layout, Card, Col, Row, Space, Button} from 'antd';

//import {ArrowRightOutlined} from '@ant-design/icons';
//import Standings from '../../Routes/Standings';
import {ArrowRightOutlined} from '@ant-design/icons';


const rightArrow = <ArrowRightOutlined style={{fontSize:18, color:'#F68C1D',}}/>
const {Content}= Layout;

const PlayerTournamentHeader = () =>{
  //width:'40%',display:'flex', justifyContent:'flex-start', alignItems:'flex-start'
        return(
            <Content style={{display:'flex',width:'95vw',height:'5vh',justifyContent:'space-between',alignItems:'center' , backgroundColor:'black', border:'1px solid #8c8c8c', color:'#F68C1D'}}>
         <Col style={{color:'#8c8c8c', marginLeft:'5vw'}}>
          Premium
          </Col>
        <Col style={{color:'#8c8c8c'}}>
            <Row>Place</Row>
           
          </Col>
         

         
          
           <Col style={{color:'#8c8c8c',}}>
           <Row>Value</Row>
            
          </Col>
          <Col style={{color:'#8c8c8c'}}>
          <Row>% </Row>
            <Row>Change</Row>
          </Col>

          <Col style={{color:'#8c8c8c',marginRight:'5vw'}}>
          <Row>End</Row>
            <Row>Date</Row>
          </Col>
        
        </Content>
            )

    
}

export default PlayerTournamentHeader;
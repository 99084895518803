import React,{useState,useEffect} from 'react'
import {Card, Space, Image} from 'antd'

import marketKingLogo from './marketKingLogo.png'
import  './stockTicker.styles.css'

//static data:
import stocks from './stocks'
import news from './news'
import {CaretUpOutlined, CaretDownOutlined, } from '@ant-design/icons';

let caretUp = <CaretUpOutlined style={{color:'green', fontSize:12, marginRight:0, alignItems:'center'}} />
let caretDown= <CaretDownOutlined style={{color:'red', fontSize:12, marginRight:0, alignItems:'center'}} />

const StockTickerScroll = (props) => {
  
  //console.log('TransactionList:',txs)
console.log('stocks:', stocks)
  
    return(
        <div className="scroll-container">
            <div className="scroll-content" >
                <li>MarketKings</li><li>MarketKings</li><li>MarketKings</li>
                {
                    stocks.map((item,key)=>{
                        return(
                            item.quote.changePercent>0?
                            
                               <li key={key} style={{color:'green',}}>
                                <p>{caretUp}{item.symbol} ${item.quote.latestPrice} </p></li>
                           
                                 :
                                 <li key={key} style={{color:'red'}}>
                                  <p>{caretDown}{item.symbol} ${item.quote.latestPrice}</p></li>

                        )
                    })
                }
                <li>MarketKings</li><li>MarketKings</li><li>MarketKings</li>
            </div>
        </div>
        )


}

export default StockTickerScroll;

//// {/* <Image  preview={false} width={40} height={40} src={item.logo.url}></Image> */}
import React, {useState, useEffect, } from 'react';

import {Layout, Row,Card, Col, Button, Alert,Image} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined, CloseCircleFilled,
    CheckCircleFilled} from '@ant-design/icons';
let error = <CloseCircleFilled style={{color:'#F68C1D'}}/>
let success = <CheckCircleFilled style={{color:'#F68C1D'}}/>
//const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let StockCard =(props) =>{

    
    let Price,PercentChange,ImageURL,Symbol,CompanyName;
    
    if(props.stock.quote)
    {
        Symbol=props.stock.symbol;
        CompanyName=props.stock.companyName
        if(CompanyName.length>26)
        {
             CompanyName=CompanyName.slice(0,23)+'...'

        }
      
        Price = props.stock.quote.latestPrice;
        PercentChange = (props.stock.quote.changePercent*100).toFixed(2);
        ImageURL =props.stock.logo.url;

    }
    else{
         Symbol=props.stock.symbol?props.stock.symbol:props.stock.Symbol
         CompanyName=props.stock.CompanyName;
         PercentChange = props.stock.PercentChange;
         Price = props.stock.Price;
         ImageURL = props.stock.Logo
    }

    let percentChangeCarrot;
    if(PercentChange>0)
    {
        percentChangeCarrot =<Col><Row style={{justifyContent:'flex-end'}}><p1 style={{color:'green'}}> ${Price}</p1></Row><Row><p1 style={{color:'green'}}><CaretUpOutlined/> {PercentChange} %</p1></Row></Col>
    }
    else
    {
        percentChangeCarrot =<Col><Row style={{justifyContent:'flex-end'}}><p1 style={{color:'red'}}> ${Price}</p1></Row><Row><p1 style={{color:'red'}}><CaretDownOutlined/> {PercentChange} %</p1></Row></Col>
    }  

    // console.log('A Tournament: ', aTourn)

    return(

        
            <Button onClick={props.onClick} style={{marginBottom:'1vh',width:'100vw', height:'10vh',color:'#F68C1D', backgroundColor:'black',borderRight:0,borderLeft:0,  borderColor:'#F68C1D'}}>

            
            <Row style={{ alignItems:'center'}}>

            <Col style={{display:'flex', alignItems:'center', borderRadius:'50px', borderWidth:1}}>
                    <Image  preview={false} width={50} height={50} src={ImageURL}></Image>
            </Col>
            <Col></Col>
            <Col style={{marginLeft:'5vw'}}>
                <Row>
                    {Symbol}

                 </Row>
                 <Row>
                    {CompanyName}
                </Row>
            </Col>

           
         
             
            <Col style={{ position:'absolute', right:'5vw'}}>
           {percentChangeCarrot}
           </Col>
           
           
         
            </Row>
            
            </Button>
        
    );
      
    
}

export default StockCard;
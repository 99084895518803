import React,{useState, useEffect} from 'react';
import '.././App.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import {Auth, API, ServiceWorker} from 'aws-amplify';
import {
    isPushNotificationSupported,
    askUserPermission,
    registerServiceWorker,
    createNotificationSubscription,
    getUserSubscription,
    sendNotification
  } from "../Utils/push-notifications";
  import Loading from '../Components/Loading'
  import ErrorLoading from '../Components/ErrorLoading';
  import MainFooter from '../Components/MainFooter'
import { Layout, Menu, Breadcrumb, Col, Divider,Card  } from 'antd';
import TopHomeContainer from '../Components/TopHomeContainer'
import {useLocation} from "react-router-dom";

const { Header, Content, Footer, Sider} = Layout;
const { SubMenu } = Menu;
//const pushNotificationSupported = isPushNotificationSupported();

//const serviceWorker = new ServiceWorker();

let Account = () =>{
    // const [userConsent, setSuserConsent] = useState(Notification.permission);
    // const [error, setError] = useState(null);
     const [loading, setLoading] = useState(false);
     const [error, setError] = useState(false);

     
   

  if(loading == true){
    return(
     
      <Loading message={'Bringing your account info up.. :)'}></Loading>
    
    );
  }
  else if(error == true){
      return(
          <ErrorLoading ></ErrorLoading>
      )
  }
else{


    return (
        <Content className="Content">
          <TopHomeContainer centeredMessage="Account"/>
          <MainFooter defaultKey='3'></MainFooter>

      </Content>
    )
}
}
export default Account
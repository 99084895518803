import React,{useState,useEffect} from 'react';
import '.././App.css';

import getDailyChartForSymbol from '../Utils/getDailyChartForSymbol.js';
import getTournamentDetails from '../Utils/getTournamentDetails.js';
import { Layout, Menu, Breadcrumb, Input, Button, Modal, Row, Card, Select, Space} from 'antd';
import Loading from '../Components/Loading';
import Chart from '../Components/Chart'
import OrderModal from '../Components/OrderModal';
import {Auth,API} from 'aws-amplify'
import ErrorLoading from '../Components/ErrorLoading'
import CustomFooter from '../Components/CustomFooter'
import SubmitOrderModal from '../Components/SubmitOrderModal';
import SearchModal from '../Components/SearchModal'

import {Link} from 'react-router-dom'
import { LoadingOutlined, LeftOutlined, DownOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import ChartTypeDropDown from '../Components/ChartTypeDropDown';
import TimeFrameDropDown from '../Components/TimeFrameDropDown'
import ChartOptionsContainer from '../Components/ChartOptionsContainer';
import TopTournamentContainer from '../Components/TopTournamentContainer';
import TradeChangesContainer from '../Components/TradeChangesContainer';
import {useLocation, useHistory} from "react-router-dom";

const {Option} = Select;
const { Header, Content, Footer, Sider, Col } = Layout;
const {Search} = Input;
const { SubMenu } = Menu;



const Trade = (props)=> {
    let TournamentID = props.match.params;
    console.log("TOURNAMENT ID: ", TournamentID)
    let history = useHistory()

    const [symbol, setSymbol] = useState('twtr');
    const [loading, setLoading]= useState(true);
    const [stockData, setStockData] = useState([]);
    const [showModal, setModal] = useState(false);
    const [userInfo, setUserInfo] = useState();
    const [balance, setBalance] = useState(0);
    const [chartTitle,setChartTitle] = useState('twtr')
    const [checkBalance, setCheckBalance] = useState(false)
    const [error, setError] = useState(false)

    const [tournamentDetails, setTournamentDetails] =useState()

    const [confirmOrderModal, setConfirmOrderModal] = useState(false)
    const [TxData,setTxData] = useState()

    const [chartType,setChartType]=useState('Line')
    const [percentChange,setPercentChange]=useState()
    const [chartTimeFrame, setChartTimeFrame]=useState('Day')
    const [searchModal,setSearchModal]= useState(false);
   // const [chartOptions, setChartOptions]=useState(<Option value="Candlestick"></Option>)

    const onSearch = value =>{
      setSymbol(value.toString());
      if(searchModal)
      {
        setSearchModal(false)
      }
      console.log(value)
    } 

    // useEffect(()=>{
    //   let changeOptions = ()=>{
    //       if(chartType== "Line")
    //       {
    //           setChartOptions(<Option value="Candlestick"></Option>)
    //       }
    //       else{
    //         setChartOptions(<Option value="Line"></Option>)

    //       }
    //   }
    //   changeOptions()
    // },chartType)
  
    const toggleModal = () =>{
     // console.log()
    //  let dt = new Date();
    //  console.log('Tournament DEtails: ',tournamentDetails)
    //  console.log('dt',dt.toISOString())
    //  console.log('START TIME:')
    //  console.log('Hours:',dt.getHours())
    //  console.log("Day: ",dt.getDay())
    //  if(dt.getDay()>0 && dt.getDay()<5) 
    //  {
    //    if(dt.getHours()==9)
    //    {
    //     console.log('here')
    //     alert("Trading is now closed!")
    //    }
    if(tournamentDetails.Status != 4 || (tournamentDetails.Status ==5 && tournamentDetails.TradingEnabled==1))
    {
      alert("Trading is now closed")
    }
      //  else{
      //   setModal(prevModal=>{return !prevModal});
      //   console.log(stockData[0].close)
      //   console.log(showModal)
      //   setCheckBalance(!checkBalance);
      // }
      
     //}
     else{
      setModal(prevModal=>{return !prevModal});
      console.log(stockData[0].close)
      console.log(showModal)
      setCheckBalance(!checkBalance);
    }
    } 

    useEffect(()=>
    {
      const fetchTournamentDetails = async () => {
        Auth.currentAuthenticatedUser()
        .then(user =>{
            let userInfo = {UserID: user.attributes.sub, TournamentID:TournamentID.TournamentID}
            console.log('userInfo',userInfo)
            setUserInfo(userInfo);

        getTournamentDetails(userInfo).then(result=>{
          console.log(result)
          setTournamentDetails(result.TournamentDetails)
          console.log('TOURNY DETAILS:',tournamentDetails)
        }).catch(err=>{
          console.log(err)
          setError(true)
          setLoading(false)

    })
    }).catch(err=>{
      console.log(err)
      setError(true)
      setLoading(false)
        
      })
    }

      fetchTournamentDetails();
    },[])    
    
   
   
    useEffect(()=>{
      const getUserBalance = async()=>{
        Auth.currentAuthenticatedUser()
        .then(user =>{
            let userInfo = {UserID: user.attributes.sub, TournamentID:TournamentID.TournamentID}
            let apiName = 'MarketKingsAPI'
            let path = '/standings/api/getUserBalance'
            let myInit = {body:userInfo}
        
            API.post(apiName, path, myInit)
              .then(response =>{
              console.log('A Response:',response[0].Balance)
              console.log("User info",userInfo)
              setBalance(response[0].Balance)
             // setLoading(false)
              }).catch(err=>{
                console.log(err)
                setError(true)
                setLoading(false)
              })

            });
          
   };
      getUserBalance();
    },[checkBalance])
   
  
      useEffect(()=>{
          const fetchStockData = async () => {
           // const result = await getDailyChartForSymbol(symbol);
           // setStockData(formatStockData(result.data['Time Series (Daily)']));
           // setLoading(false)
           getDailyChartForSymbol(symbol).then(result=>{
             if(result.data.note){
               console.log('Note',result.data.note)
               
               setError(true)
               setLoading(false)
             }
           
             if(result.data['Error Message'])
             {console.log(result)
               alert("There was an error with your entry.. Please try again!")
               //setSymbol('twtr')
             }
             else{
               
               if(result.data['Time Series (Daily)']){
                setChartTitle(symbol)
                console.log("result.data['Time Series (Daily)']",result.data['Time Series (Daily)']);
                setStockData(formatStockData(result.data['Time Series (Daily)']));
                setLoading(false)
               }
              }
               
           })
           .catch(err =>{
             console.log("Error:",err)
             setError(true);
             setLoading(false);
           })
           
          };
          fetchStockData();
        }, [symbol]);



       

        // useEffect(()=>{
        //   let stockDataResult =()=>{
        //   if(stockData)
        //   {
        //     setLoading(false);
        //   }
        //   else
        //   {
        //     setError(true);
        //     setLoading(false);
        //   }
        // }
        // stockDataResult();

        // },[stockData])

        // useEffect(()=>{
        //   const load = async ()=>{
        //   //  setLoading(false)
        //   };
        //   load()
        // },[userInfo,chartTitle])

        let submitOrder = (TxData)=>{
          console.log('TEST')
          console.log('TxData:', TxData)
          setTxData(TxData);
          setModal(false);
          setConfirmOrderModal(true);

    }
    let toggleConfirmOrderModal =() =>{
      if(confirmOrderModal)
      {
        setConfirmOrderModal(false)
        setCheckBalance(!checkBalance)
      }
      else{
        setConfirmOrderModal(true)

      }
    }
    const toggleSearch = () => {
      console.log('test')
      setSearchModal(!searchModal)
    }

   


        if(loading == true){
            return(
              <Content className="Content">
        
              <Loading message="Getting ready to trade!" ></Loading>
              <CustomFooter TournamentID={TournamentID} defaultKey='2' ></CustomFooter>
              </Content>
            );
          }
          else if(error == true){
            return(
                <ErrorLoading ></ErrorLoading>
            )
        }
          else if(stockData[0].close){
           return (
               <Content className="Content">
                 
                 <SearchModal visible={searchModal} onCancel={toggleSearch} onSearch={onSearch}></SearchModal>
                <TopTournamentContainer rightCornerMessage={'Balance: $'+balance}/>

                  {/* <div className="site-layout-background" style={{ padding: 10, Height: 10 }}>
                     <Search style={{backgroundColor:'#8c8c8c', borderRadius:10, }} bordered={false} placeholder="Please enter a stock symbol... ex. aapl" onSearch={onSearch} enterButton />
                 </div> */}
{/* 
                 <div style={{color:'#F68C1D',display:"flex",justifyContent:'center',fontSize:32}}>
                    {chartTitle}
                 </div> */}
                 <TradeChangesContainer open={stockData[0].open} close={stockData[0].close} ></TradeChangesContainer>

                  <Space> </Space>
               
                 <ChartOptionsContainer 
                      ChartSymbol={chartTitle} 
                      SymbolPrice={stockData[0].close}
                      ChartTimeFrame={<TimeFrameDropDown setChartTimeFrame={setChartTimeFrame} />} 
                      ChartType={<ChartTypeDropDown setChartType={setChartType} />} 
                      toggleSearch={toggleSearch}
                  />

                 

                 
                 <div className="site-layout-background" style={{marginLeft:'5vw',marginRight:'2.5vw' }}>
                   
                   <Chart chartType={chartType} symbol={chartTitle} stockData={stockData}></Chart>
                 </div>
                 <div className="site-layout-background" style={{ padding: 24}}>

                <Button style={{backgroundColor:'Black', color:'#F68C1D', borderColor:'#F68C1D'}}type="primary" block  onClick={toggleModal}>Trade</Button> 
                </div>
                
                 
          <OrderModal 
                     TournamentID={TournamentID.TournamentID}
                     visible={showModal}
                     submitOrder={submitOrder}
                     userInfo={userInfo}
                     balance={balance}
                     onCancel={toggleModal}
                     marketData={stockData}
                     symbol={symbol}
          >
          </OrderModal>
          
          <SubmitOrderModal
            submitVisible = {confirmOrderModal}
            onConfirmOrderCancel = {toggleConfirmOrderModal}
            TxData={TxData}
          />
               <CustomFooter TournamentID={TournamentID} defaultKey='2' ></CustomFooter>
             </Content>
           )
       }
       else{
         <ErrorLoading></ErrorLoading>
       }
}
function formatStockData(stockData)
{
    return Object.entries(stockData).map(entries =>{
        const [date, priceData] = entries;
        return{
            date,
            open: Number(priceData['1. open']),
            high: Number(priceData['2. high']),
            low: Number(priceData['3. low']),
            close: Number(priceData['4. close']),
            volume: Number(priceData['5. volume']),
        }
    });
}
export default Trade
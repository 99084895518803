import {Auth, API} from 'aws-amplify';

const getChartSegment = (symbol,time) => {
 
    let apiName = 'StockDataAPI'
    let path = '/stockData/getChartSegment/'
    let p = path+symbol+'/'+time
    let myInit = {}
    console.log('In get chartSegment: ', symbol)
    return API.get(apiName, p, myInit)
    .then(response =>{
        console.log('got chart for :',time)
      console.log(response)
      return response.Data
    })
    .catch(err=>console.log(err))
  
};

export default getChartSegment;
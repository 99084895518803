import React,{useState, useEffect} from 'react';
import {Modal, Input, Button, Slider, InputNumber, Row, Col, Space, Alert, Layout, Divider, Icon} from 'antd'
import {
    CaretUpOutlined,
    CaretDownOutlined,
    CloseOutlined,
    PlusOutlined,
    MinusOutlined
  } from '@ant-design/icons';
import submitTransaction from '../Utils/submitTransaction'
import {Auth, API, input} from 'aws-amplify';

let plus = <PlusOutlined style={{color:'#F68C1D', fontSize:32, }} />
let minus = <MinusOutlined style={{color:'#F68C1D', fontSize:32, }} />

let close = <CloseOutlined style={{color:'#F68C1D'}}/>
let {Content} = Layout
const OrderModal = (props) =>{

//const [percentChange, setPercentChange]= useState(0)
const [inputValue, setInputValue] = useState(0);
const [costTotal, setCostTotal ] = useState(0)
const [orderSubmit, setOrderSubmit] = useState(false)
const [TxData, setTxData] = useState([])
const [TxAlert,setTxAlert] = useState( 'Please select the amount of shares and your order type.' )

//const [quote,setQuote] = useState(props.quote?props.quote:'')
let TournamentID=props.TournamentID;
let quote = props.quote;
let marketData=props.marketData;
let Balance = props.balance;
let userInfo = props.userInfo;
let submitOrder = props.submitOrder;
let holdings = 0;

let computeMaxShares = (price) =>{
    let maxShares = ((Balance)-(holdings*price))/price
    return maxShares;
}

// useEffect(() => {
//   let updateTX = ()=>{
//     console.log('TESTing quote change')
//   }
//   updateTX();
// }, [quote])

useEffect(()=>{
  let computeCostTotal = () => {
    let cost = quote.latestPrice * inputValue;
    setCostTotal(cost.toFixed(2));
    console.log(cost.toFixed(2))
  };

computeCostTotal();
}, [inputValue,quote])

let onChange = value =>{
  //value=Number(value)
  console.log(value)

    setInputValue(value)
}

// useEffect(()=>{
//     let computePercentChange =()=>{
//       setCostTotal(0)
//       setInputValue(0)
//       setTxData([])
//         let percentChange = Number((((marketData[0].close - marketData[0].open )/marketData[0].close)*100).toFixed(2))
//         console.log(percentChange)
//         if(percentChange >0){
            
//             setPercentChange(()=><p1 style={{color:'green'}}><CaretUpOutlined/>{percentChange}%</p1>)
            
//         }
//         else{
//             setPercentChange(()=><p1 style={{color:'red'}}><CaretDownOutlined/>{percentChange}%</p1>)
                
//         }
//     };
//     computePercentChange()
// },[marketData])
let percentChange = props.quote.changePercent?(props.quote.changePercent*100).toFixed(2):0;
let percentChangeCarrot;
    if(percentChange>0)
    {
        percentChangeCarrot =<p1 style={{color:'green'}}><CaretUpOutlined/>${quote.change} ({percentChange}%)</p1>
    }
    else
    {
        percentChangeCarrot =<p1 style={{color:'red'}}><CaretDownOutlined/>${quote.change} ({percentChange}%)</p1>
    }   

useEffect(()=>{
  // const handleTransaction = async (TxData) =>{
  //   const txResult = await submitTransaction(TxData);
  //   console.log(txResult)
  // };
  // handleTransaction(TxData);
 console.log('insideUSEEFFECT TXDATA:',TxData)
  let alertTx = () => {
    if(TxData.length==0)
      {
          let m ='Please select the amount of shares and your order type.' 
          console.log(m)
          setTxAlert(()=><Alert message={m} type="info" />)


      }
      else{
        let m = TxData.TxType + " " + TxData.Shares + " shares of " + TxData.Symbol + " @ " + TxData.Price + " each for: $" + (TxData.Price*TxData.Shares).toFixed(2)
        console.log(m)
        setTxAlert(()=><Alert message={m} type="info" />)
      }
 };
 alertTx();
},[TxData])

let handleOrderType= async (orderType)=>{
  // Auth.currentAuthenticatedUser()
  //           .then(user =>{
  //               let userInfo = {UserID: user.attributes.sub, UserName:user.username}
  //              // TxObj.UserID = user.attributes.sub
  //               console.log(userInfo);
  //               //setUserInfo(userInfo);
  console.log('UserInfo',userInfo)
  let TimeStamp = new Date();
  TimeStamp.toString();
 // console.log('PlayerID:',9)
  console.log('Symbol:', props.symbol)
  console.log('Shares:',inputValue)
  console.log('Price:', quote.latestPrice)
  console.log('Cost:', costTotal)
  console.log('ORDER TYPE:',orderType)
  console.log('TimeStamp: ', TimeStamp)
  if(costTotal!=0)
  {
    let TxObj = {UserID: userInfo.UserID,TournamentID:TournamentID, Symbol:props.symbol, Shares:inputValue, Price: quote.latestPrice, TxType: orderType, TimeStamp:TimeStamp }
    setTxData(TxObj)
    submitOrder(TxObj)
  }
  else{
    alert("You can't "+orderType+" 0 shares...")
  }

  
         //   });
}

//let handleOk = async ()=>{
  // const txResult = await submitTransaction(TxData);
  // console.log(txResult);
  // let m= txResult.TransactionResult + ': '+txResult.message;
  // if (txResult.TransactionResult == 'Success')
  // {
  //   setTxAlert(()=><Alert message={m} type="success"/>)
  //   setTimeout(()=>{props.onCancel()},1500)
  // }
  // else{
  //   setTxAlert(()=><Alert message={m} type="error"/>)
  //   setTimeout(()=>{props.onCancel()},1500)

  // }
//console.log('onOK')
//}

//computePercentChange()
return(
  <Modal
  bodyStyle={{backgroundColor:'#141414',color:'#F68C1D'}}
  
  closeIcon={close}
  visible={props.visible}
  onCancel={props.onCancel}
  footer={null}
> 
<Content style={{}}>
  <Row style={{marginTop:20, display:'flex', alignItems:'center',justifyContent:'space-evenly'}}>
    <Col>
    <h1 style={{color:'#F68C1D'}}>{(props.symbol.toUpperCase())}</h1>
    </Col>

    <Col>
      <Row style={{display:'flex', alignItems:'center'}}>
        <h1 style={{color:'#F68C1D'}}> ${quote.latestPrice}</h1>

        <Col style={{display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center',marginLeft:5}}>
          <Row style={{}}>
            {percentChangeCarrot}
          {/* <h5 style={{color:'#F68C1D', }}> {percentChange} </h5> */}
          </Row>
        </Col>

       </Row>
    </Col>
  </Row>

<Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D',  }}> </Divider>

<Row style={{marginTop:20, display:'flex', justifyContent:'center' }}>
 
  <Row style={{marginBottom:20, display:'flex', justifyContent:'center', alignItems:'center' }}>
    <Col>
      <h4 style={{color:'#F68C1D'}}>Please select an amount of shares:</h4>
    </Col>
   </Row>

  
    <Row style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
      <Button onClick={()=>setInputValue(inputValue>0?inputValue-1:0)} ghost style={{backgroundColor:'black', color:'#F68C1D', borderColor:'#F68C1D', display:'flex', alignItems:'center', justifyContent:'center',marginRight:10 }} type="primary" shape="circle" icon={minus} size={'large'} />
      <Input disabled={true} onChange={onChange} style={{width:'30%', height:50, borderColor:'#F68C1D',textAlign:'center', backgroundColor:'#141414', fontSize:32, color:'#F68C1D'}} type='number' defaultValue={0} value={inputValue}/>
      <Button onClick={()=>setInputValue(inputValue+1)} ghost style={{backgroundColor:'black', color:'#F68C1D', borderColor:'#F68C1D', display:'flex', alignItems:'center', justifyContent:'center',marginLeft:10  }} type="primary" shape="circle" icon={plus} size={'large'} />
    </Row>
    
    <Row style={{width:'100vw', marginTop:15}}>
   
     <Slider
      style={{width:'100%', color:'#F68C1D', }}
      
      min={0}
      max={computeMaxShares(marketData[0].close)}
      onChange={onChange}  
      value={typeof inputValue === 'number' ? inputValue : 0} 
      >
     
     </Slider>

  </Row>
  <Row style={{width:'100vw', marginTop:15, display:'flex', justifyContent:'space-evenly'}}>
  <h4 style={{color:'#F68C1D'}}>Current Balance: ${Balance}</h4>
  <h4 style={{color:'#F68C1D'}}>Cost: ${costTotal}</h4>
  



  </Row>
</Row>

<Divider orientation='center' style={{color:'#F68C1D', backgroundColor:'#F68C1D',  }}> </Divider>


<Row style={{marginTop:20, display:'flex', justifyContent:'center', }}>
 
  <Row style={{marginBottom:20, display:'flex', justifyContent:'center', alignItems:'center' }}>
    <Col>
      <h4 style={{color:'#F68C1D'}}>Please select an order type:</h4>
    </Col>
  </Row>

</Row>

<Row style={{display:'flex', justifyContent:'space-evenly'}}>
    <Button type="round" style ={{backgroundColor:'green', width:'40%', borderColor:'transparent', color:'black'}} onClick={()=>handleOrderType('BUY')}>Buy</Button>
    <Button type="round" style ={{backgroundColor:'Red', width:'40%',  borderColor:'transparent', color:'black'}} onClick={()=>handleOrderType('SHORT')}>Short</Button>
</Row>


</Content>


</Modal>
      
//     <Modal
//     title='Place Order'
//     visible={props.visible}
//     bodyStyle={{backgroundColor:'#141414',color:'#F68C1D'}}
//     //onOk={props.onOk(TxData)}
//     onCancel={props.onCancel}
//     closeIcon={close}
//     style={{backgroundColor:'#141414',color:'#F68C1D'}}
//     marketData={props.marketData}
//     footer={[
//       <Content style={{backgroundColor:'red', width:'100vw'}}>
//           <Space>{TxAlert}</Space>
//             <Button key="back" onClick={props.onCancel}>
//               Cancel
//             </Button>
//             <Button key="submit" type="primary" loading={orderSubmit} onClick={()=>{
//               if(TxData.length ==0)
//               {
//                 alert("Please check your order...")

//               }   
//               else
//               {
//                 submitOrder(TxData)
                

//               }          

//             }}>
//               Submit Order
//             </Button>
//             </Content>
//           ]}
//          >
   
   
// <h3>Balance: ${Balance}</h3>
    
//     <div style={{align:'left'}}>
//     <h1>{props.symbol}</h1>
//   <h2 style={{align:'left'}}>Price: {marketData[0].close} {percentChange}</h2>
  
 
//   <h2>DayRange: {marketData[0].low} - {marketData[0].high}</h2>
  
//   </div>
//   <Col >
//   <h1>Cost: ${costTotal.toFixed(2)}</h1>
  
//   </Col> 
//   <Row>
//   <Col span={12}>
//     <Slider
//     style={{width:window.width}}
//     min={0}
//     max={computeMaxShares(marketData[0].close)}
//     onChange={onChange}  
//     value={typeof inputValue === 'number' ? inputValue : 0} 
//     >
     
//     </Slider>
//     </Col>
//     <Col span={4}>
//           <InputNumber
//             min={0}
//             max={Math.round(computeMaxShares(marketData[0].close))}
//             style={{ margin: '0 16px' }}
//             value={inputValue}
//             onChange={onChange}
//           />
//         </Col>
     
//     </Row>
    
//    <div style={{marginTop:24}}/>

//     <Space>
//     <Button type="primary" style ={{backgroundColor:'green'}} onClick={()=>handleOrderType('BUY')}>Buy</Button>
   
//     {/* <Button type="primary" style ={{backgroundColor:'Red'}} onClick={()=>handleOrderType('SELL')}>Sell</Button>

//     <Button type="primary" style ={{backgroundColor:'green'}} onClick={()=>handleOrderType('BTC')}>Buy to Cover</Button> */}
   
//     <Button type="primary" style ={{backgroundColor:'Red'}} onClick={()=>handleOrderType('SHORT')}>Short</Button>
// </Space>
    
//   </Modal>
);


}

export default OrderModal;
import React, {useState, useEffect, } from 'react';

import {Layout, Row,Card, Col, Button, Alert} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined, CloseCircleFilled,
    CheckCircleFilled} from '@ant-design/icons';

const {Content} = Layout;


let PortfolioValueCard =(props) =>{
  
   let value = props.Value
   let percentChange = props.PercentChange
    let color = percentChange>0?'green':'red'
    
    

    return(

        <Card style={{display:'flex', justifyContent:'center', width:'17vw', height:'8vh', alignItems:'center',backgroundColor:'black', borderRadius:'20px', color:'Black',borderColor:'transparent'}}>
       
            <span style={{fontSize:12}}>
                <span style={{color:color}}>${value}</span>
                
            </span>
            
            <span style={{color:color}}> ({percentChange}%)</span>
         
         
        </Card>
      
    )
}

export default PortfolioValueCard;


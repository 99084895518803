import React, {useState, useEffect, } from 'react';

import {Layout, Row,Card, Col, Button, Alert} from 'antd'
import {LeftOutlined, CaretUpOutlined, CaretDownOutlined, CloseCircleFilled,
    CheckCircleFilled} from '@ant-design/icons';
let error = <CloseCircleFilled style={{color:'#F68C1D'}}/>
let success = <CheckCircleFilled style={{color:'#F68C1D'}}/>
//const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;


let PlacementCard =(props) =>{
    //let placement = 99
   let placement = props.Placement
   //console.log('aPlacement:',placement)
    
    let formatPlacementSuffix  =(placement) =>{
        if(placement ==0)
        {
            return 'st'
        }
        else if(placement==1)
        {
            return 'nd'
        }
        else if(placement==2)
        {
            return 'rd'
        }
        else{
            return 'th'
        }
    }
    let suffix = formatPlacementSuffix(placement)
    let formattedPlacement = Number(placement)+1;

    return(

        <Card style={{display:'flex', justifyContent:'center', width:'17vw', height:'8vh', alignItems:'center',borderRadius:'20px',backgroundColor:'Black', color:'#F68C1D',borderColor:'transparent'}}>
       
            <span style={{fontSize:24}}>
                {formattedPlacement}
            </span>
            
            <span >
                <span>{suffix}</span>
                

           </span>
         
         
        </Card>
      
    )
}

export default PlacementCard;

/*
        <Button onClick={props.onClick} style={{marginTop:'2vh',marginBottom:'1vh',width:'95vw', height:'10vh',color:'#F68C1D', backgroundColor:'black',  borderColor:color}}>

            
            <Row style={{justifyContent:'space-between', alignItems:'center'}}>
            <Col>
            {PremiumTournament}
            </Col>

            <Col>
                {Placement}
            </Col>

            <Col>
            ${PortfolioValue}
            </Col>

            <Col>
            {PercentChange}
            </Col>

            <Col>
            {EndDate}
            </Col>
            </Row>
            </Button>

            */
import React, {useState, useEffect} from 'react';


import {Layout, Row,Col, Button,Space, Divider, Card, Image, Descriptions, Typography, Collapse} from 'antd'
import {LeftOutlined, RightOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';

const leftIcon = <LeftOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const rightIcon = <RightOutlined style={{fontSize:32, color:'#F68C1D',}}/>
const {Content} = Layout;
const {Panel} = Collapse;

let About =(props) =>{

    const [selected, setSelection] = useState(1);
    let about = props.about;
    console.log(about[0])
//<rightIcon rotate={isActive ? 90 : 0} />
   
  return(
    <Content style={{ marginTop:'2vh',backgroundColor:'',marginLeft:'5vw',marginRight:'5vw',}}>

        <Row style={{color:'#F68C1D', fontSize:24,display:'flex',}}>About {about.companyName}</Row>
        <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',marginTop:0}}></Divider>

        <Collapse  expandIcon={({ isActive }) => <RightOutlined style={{fontSize:14, color:'#F68C1D',}} rotate={isActive ? 90 : 0}/>}
                    style={{borderColor:'#F68C1D',}} defaultActiveKey={['1']} ghost>

            <Panel  style={{color:'#F68C1D' }} header={<Content style={{color:'#F68C1D',marginLeft:0}}>Company Description</Content>} key="1">
                <p style={{color:'#F68C1D',textAlign:'center'}}>
                {about.description}
                </p>
            </Panel>


            {/* <Panel  style={{color:'#F68C1D' }} header={<p style={{color:'#F68C1D',marginLeft:''}}>Further Information</p>} key="2">
                <Content style={{display:'flex', alignItems:'center',color:'#F68C1D'}}>
                
                </Content>
                
                <p style={{color:'#F68C1D',textAlign:'center'}}>
                
                </p>
            </Panel> */}



        </Collapse>

        

           {/* <Button  style={{borderWidth:0,borderColor:'#F68C1D',backgroundColor:'#141414', color:'#F68C1D'}} >Show More Articles</Button> */}
           <Divider  style={{color:'#F68C1D', borderColor:'#F68C1D',}}></Divider>
           
          </Content>  
           )


        
   



}

export default About;

   
{/* <Button onClick={props.onClick} style={{marginTop:'2vh',marginBottom:'1vh',width:'95vw', height:'10vh',color:'#F68C1D', backgroundColor:'black',  borderColor:color}}>

            
<Row style={{justifyContent:'space-between', alignItems:'center'}}>
<Col>
{PremiumTournament}
</Col>

<Col>
    {Placement}
</Col>

<Col>
${PortfolioValue}
</Col>

<Col>
{PercentChange}
</Col>

<Col>
{EndDate}
</Col>
</Row>
</Button> */}